import _ from 'lodash'

export const limit = (str: string, limit: number, prefix: string): string => {
  if (!str) return prefix || '---'
  if (typeof str === 'number' || !limit) return str

  const stringArray = str.split(' ')
  return stringArray.splice(0, limit).join(' ') + (stringArray.length > limit ? prefix || '...' : '')
}

export const isJson = (str: string) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const convertNumberToWords = (number: number, langs): string => {
  const rows = {
    2: 'row70',
    3: 'row71',
    4: 'row72',
    5: 'row73',
    6: 'row74',
    7: 'row75'
  }

  const dayWordsDefault = {
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven'
  }

  if (rows[number]) {
    const row = rows[number]
    const numberNights = langs ? langs[row] : null

    return numberNights
      ? capitalizeFirstLetter(numberNights)
      : dayWordsDefault[number]
      ? capitalizeFirstLetter(dayWordsDefault[number])
      : number.toString()
  }

  return number.toString()
}

export const linkify = (inputText: string): string => {
  let replacedText: string, replacePattern1: RegExp, replacePattern2: RegExp, replacePattern3: RegExp

  if (!inputText) {
    inputText = ''
  }

  // URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>')

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>')

  // Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>')

  return replacedText
}
