import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "hotel_book" }
const _hoisted_2 = { class: "container my-4" }
const _hoisted_3 = { class: "my-4 select-number-room" }
const _hoisted_4 = { class: "mb-4 group-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_ItemOfVouchers = _resolveComponent("ItemOfVouchers")!
  const _component_MatrixHotel = _resolveComponent("MatrixHotel")!
  const _component_StyledSFSHotelBook = _resolveComponent("StyledSFSHotelBook")!

  return (_openBlock(), _createBlock(_component_StyledSFSHotelBook, null, {
    default: _withCtx(() => [
      _createElementVNode("section", null, [
        _createVNode(_component_Title, {
          "title-name": _ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_44 || 'Home',
          "title-link": "Home"
        }, null, 8, ["title-name"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ItemOfVouchers, {
                icon: "images/icon-voucher/hotel-voucher.png",
                color: "#f0725e",
                label: _ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_1,
                border: ""
              }, null, 8, ["label"])
            ]),
            _createVNode(_component_MatrixHotel, {
              "list-paxs": _ctx.paxsShareRoom,
              "response-fetch-matrix-hotels": _ctx.responseFetchMatrixHotels,
              "room-select": _ctx.roomSelect,
              "is-default-value": _ctx.isDefaultValue,
              onGetListSelected: _ctx.getListSelected,
              onHandleChangeRoomSelect: _ctx.handleChangeRoomSelect,
              onRoomAmountChanged: _ctx.roomAmountChanged
            }, null, 8, ["list-paxs", "response-fetch-matrix-hotels", "room-select", "is-default-value", "onGetListSelected", "onHandleChangeRoomSelect", "onRoomAmountChanged"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                class: "btn-action",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.validationRoomSelected()))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_45 || 'Proceed'), 1)
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}