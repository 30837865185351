
import { defineComponent, onBeforeMount, PropType, reactive, ref, watch } from 'vue'
import SFSMatrixSelect from '@/components/SFSMatrixSelect.vue'
import StyledSFSMatrixSelectAndSelectBox from '@/styled-components/components/SFSMatrixSelectAndSelectBox'
import LangMixinsVue from '@/views/SearchAndSelectPassengers/Mixin/LangMixins.vue'
import _ from 'lodash'
import { isInfant } from '@/utils/passenger'
import { ElNotification } from 'element-plus'

export default defineComponent({
  name: 'SFSMatrixTaxi',
  components: {
    SFSMatrixSelect,
    StyledSFSMatrixSelectAndSelectBox
  },
  mixins: [LangMixinsVue],
  props: {
    listPaxs: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    shareMatrix: {
      type: Array as PropType<number[][]>,
      default: () => []
    },
    paxPerTaxi: {
      type: Number,
      default: undefined
    },
    isSetByAirline: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:shareMatrix'],
  setup(props, { emit }) {
    const numberOfTaxies = ref(props.shareMatrix.length)

    const matrix = reactive<number[]>([])

    watch(
      () => props.paxPerTaxi,
      () => checkPaxOnTaxi()
    )

    watch(matrix, () => {
      let min = 99999
      //find position min room number from room assigned
      _.forEach(matrix, (value, index) => {
        if (value < min && !isInfant(props.listPaxs[index])) {
          min = value
        }
      })
      if (min !== 99999) {
        minIndex = min
      }
      _.forEach(props.listPaxs, (pax, index) => {
        if (isInfant(pax)) {
          matrix[index] = minIndex
        }
      })

      emit('update:shareMatrix', analyzeSelectedPaxsToGetGroupPax(matrix))

      const paxPerCurrentTaxi = _.groupBy(matrix)
      let checkOverPax = _.some(paxPerCurrentTaxi, (group) => {
        if (!props.paxPerTaxi) {
          return false
        }
        return group.length > props.paxPerTaxi
      })
      if (checkOverPax) {
        ElNotification.closeAll()
        ElNotification({
          title: 'Warning',
          message: `Taxi cars can often carry a maximum of ${props.paxPerTaxi} adults with suitcases, please evaluate if you want to split the group in multiple taxis or if one taxi would be sufficient.`,
          type: 'warning',
          duration: 3000
        })
      }
    })

    let minIndex = 1
    const updateMatrix = (matrixShareTaxi: number[][] | string[][] = []) => {
      const shareMatrix: number[] = []
      props.listPaxs?.forEach((pax) => {
        matrixShareTaxi.forEach((matrixItem, idx) => {
          matrixItem = matrixItem.map((mi) => parseInt(mi))
          if (matrixItem.includes(pax?.passenger_id)) shareMatrix.push(idx + 1)
        })
      })
      if (shareMatrix.length) {
        Object.assign(matrix, shareMatrix)
        numberOfTaxies.value = _.uniq(shareMatrix).length
      }
    }

    // check passenger in order to set the number of taxis
    const checkPaxOnTaxi = () => {
      // let airlineParams = { pax_per_taxi: 2 }
      // if (store.state.airline?.params) airlineParams = JSON.parse(store.state.airline.params)
      let maxNeededTaxis = props.listPaxs.length
      let minNeededTaxies = props.paxPerTaxi ? Math.ceil(maxNeededTaxis / props.paxPerTaxi) : 1
      numberOfTaxies.value = minNeededTaxies
      if (numberOfTaxies.value) selectOptions.value = _.range(minNeededTaxies, maxNeededTaxis + 1)
    }

    const analyzeSelectedPaxsToGetGroupPax = (group): number[][] => {
      let shareMatrix: any = {}

      group.forEach((key, index) => {
        const pax = props.listPaxs[index]

        if (!shareMatrix[key]) shareMatrix[key] = []
        shareMatrix[key].push(pax.passenger_id)
      })

      shareMatrix = Object.values(shareMatrix)
      return shareMatrix
    }

    const selectOptions = ref<number[]>([])
    const initData = () => {
      checkPaxOnTaxi()
      updateMatrix(props.shareMatrix)
    }

    watch(
      () => props.shareMatrix,
      () => updateMatrix(props.shareMatrix)
    )

    onBeforeMount(() => {
      initData()
    })

    const sfsMatrixSelectComponent = ref()

    return { numberOfTaxies, selectOptions, matrix, sfsMatrixSelectComponent }
  }
})
