
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import StyledVoucherSharing from '@/styled-components/pages/voucher-sharing'
import ItemOfVoucher from '@/components/ItemOfVoucher/Index.vue'
import SFSTravelersAndServiceInGroup from '@/components/SFSTravelersAndServiceInGroup.vue'
import { StoreType } from '@/types/store'
import LangMixinsVue from '../SearchAndSelectPassengers/Mixin/LangMixins.vue'
import _ from 'lodash'

export default defineComponent({
  name: 'MonetaryCompensationSharing',
  components: {
    StyledVoucherSharing,
    ItemOfVoucher,
    SFSTravelersAndServiceInGroup
  },
  mixins: [LangMixinsVue],
  setup() {
    const store = useStore<StoreType>()
    const router = useRouter()
    const route = useRoute()

    return {
      listMyGroupPaxs: computed(() => store.getters['groupPax/listGroupPax']),
      vouchers: computed(() => store.state.vouchers),
      router,
      route
    }
  },
  computed: {
    totalCredit() {
      const voucher_code = this.route.query.voucher_code
      const monetary = this.vouchers.find((voucher) => voucher.service_id == 21)
      const filteredMonetary = monetary?.detail_monetary?.monetaryVoucher.filter((m) => m.voucher_code == voucher_code)
      let totalPriceOfGroup = filteredMonetary?.reduce((total, m) => total + m.valamount, 0)
      const currencyList = filteredMonetary?.map((m) => m.currency as string) ?? ''
      const currency = !_.isEmpty(currencyList) ? currencyList[0] : ''
      totalPriceOfGroup = totalPriceOfGroup != 0 ? parseFloat(totalPriceOfGroup).toFixed(2) : totalPriceOfGroup
      if (totalPriceOfGroup == 0) return `${currency} 0.00`
      return `${currency} ${totalPriceOfGroup}`
    }
  },
  methods: {
    backToHomePage() {
      this.router.push({ name: 'Home' })
    }
  }
})
