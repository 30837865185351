
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import StyledVoucherSharing from '@/styled-components/pages/voucher-sharing'
import ItemOfVoucher from '@/components/ItemOfVoucher/Index.vue'
import SFSTravelersAndServiceInGroup from '@/components/SFSTravelersAndServiceInGroup.vue'
import { StoreType } from '@/types/store'
import _ from 'lodash'
import { PassengerType } from '@/types/passenger'
import LangMixinsVue from '../SearchAndSelectPassengers/Mixin/LangMixins.vue'

export default defineComponent({
  name: 'RefreshmentSharing',
  components: {
    StyledVoucherSharing,
    ItemOfVoucher,
    SFSTravelersAndServiceInGroup
  },
  mixins: [LangMixinsVue],
  setup() {
    const store = useStore<StoreType>()
    const router = useRouter()
    const route = useRoute()

    return {
      paxsGroup: computed(() => store.getters['groupPax/listGroupPax']),
      vouchers: computed(() => store.state.vouchers),
      router,
      route,
      paxThisIsMe: computed(() => store.state.groupPax?.me)
    }
  },
  data() {
    const groupRefreshmentsPaxIds: number[] = []
    return {
      groupRefreshmentsPaxIds
    }
  },
  computed: {
    totalCredit(): string {
      const voucher_code = this.route.query.voucher_code
      const refreshment = this.vouchers.find((v) => v.service_id == 2)
      const filteredRefreshment = refreshment?.list_refreshment.filter((r) => r.voucher_code == voucher_code)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.groupRefreshmentsPaxIds = filteredRefreshment?.map((r) => r.passenger_id as number) ?? []
      let totalPriceOfGroup = filteredRefreshment?.reduce((total, r) => total + r.valamount, 0)
      const currencyList = filteredRefreshment?.map((r) => r.currency as string) ?? ''
      const currency = !_.isEmpty(currencyList) ? currencyList[0] : ''
      totalPriceOfGroup = totalPriceOfGroup != 0 ? parseFloat(totalPriceOfGroup).toFixed(2) : totalPriceOfGroup
      if (totalPriceOfGroup == 0) return `${currency} 0.00`
      return `${currency} ${totalPriceOfGroup}`
    },
    groupRefreshments(): PassengerType[] {
      return this.paxsGroup.filter((pax) => this.groupRefreshmentsPaxIds.includes(pax.id))
    }
  },
  unmounted() {
    this.router.replace({ query: { ...this.route.query, voucher_code: undefined } })
  },
  methods: {
    backToHomePage() {
      this.router.push({ name: 'Home' })
    }
  }
})
