import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = {
  key: 0,
  class: "mt-4 ws-textbox d-flex justify-content-between"
}
const _hoisted_3 = { class: "d-flex justify-content-between w-100" }
const _hoisted_4 = { class: "w-50" }
const _hoisted_5 = { class: "mt-4" }
const _hoisted_6 = { class: "mt-4" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "m-0" }
const _hoisted_9 = { class: "control-group" }
const _hoisted_10 = { class: "mb-3" }
const _hoisted_11 = { class: "m-0" }
const _hoisted_12 = ["placeholder"]
const _hoisted_13 = { class: "input-group text-center d-block pb-2" }
const _hoisted_14 = { class: "input-group text-center d-block pb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemOfVoucher = _resolveComponent("ItemOfVoucher")!
  const _component_SFSListFlightInfo = _resolveComponent("SFSListFlightInfo")!
  const _component_VueTelInput = _resolveComponent("VueTelInput")!
  const _component_StyledPassengerDetail = _resolveComponent("StyledPassengerDetail")!

  return (_openBlock(), _createBlock(_component_StyledPassengerDetail, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ItemOfVoucher, {
          label: `${_ctx.paxThisIsMe?.first_name} ${_ctx.paxThisIsMe?.last_name}`,
          color: "#a7116f",
          icon: "images/icon-voucher/contact-detail.png",
          pnr: _ctx.paxThisIsMe?.pnr,
          cursor: "auto"
        }, null, 8, ["label", "pnr"])
      ]),
      (_ctx.listGroupPaxExcludeMe.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listGroupPaxExcludeMe, (pax, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(`${pax.first_name} ${pax.last_name}`), 1))
                }), 128))
              ]),
              _createElementVNode("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listGroupPaxExcludeMe, (pax, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, "PNR: " + _toDisplayString(pax.pnr), 1))
                }), 128))
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_SFSListFlightInfo, { class: "flight-info" })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("form", {
          class: "ws-textbox",
          onSubmit: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("h5", _hoisted_8, _toDisplayString(_ctx.groundHandlerMessage?.pax_info_new.SFS_LANG_ROW_2), 1),
            _createElementVNode("div", null, _toDisplayString(_ctx.groundHandlerMessage?.pax_info_new.SFS_LANG_ROW_3), 1),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_VueTelInput, {
                modelValue: _ctx.phonePax,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phonePax) = $event)),
                modelModifiers: { trim: true },
                class: _normalizeClass(["vue-tel-input", { 'validation-failed': !_ctx.validatePhone || !_ctx.checkEmptyValue }]),
                "input-options": { placeholder: _ctx.groundHandlerMessage?.pax_info_new.SFS_LANG_ROW_7 || 'Enter phone number' },
                onValidate: _ctx.checkPhoneValid,
                onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changePhone()))
              }, null, 8, ["modelValue", "class", "input-options", "onValidate"])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("h5", _hoisted_11, _toDisplayString(_ctx.groundHandlerMessage?.pax_info_new.SFS_LANG_ROW_4), 1),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.emailPax) = $event)),
              type: "email",
              class: _normalizeClass(["form-control", { 'validation-failed': !_ctx.checkEmptyValue }]),
              placeholder: _ctx.groundHandlerMessage?.pax_info_new?.SFS_LANG_ROW_8 || 'Enter email address',
              onKeyup: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeEmail()))
            }, null, 42, _hoisted_12), [
              [
                _vModelText,
                _ctx.emailPax,
                void 0,
                { trim: true }
              ]
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("button", {
              type: "submit",
              class: "btn-action",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updatePhoneAndEmail()))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.groundHandlerMessage?.pax_info_new.SFS_LANG_ROW_5), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("button", {
              class: "btn-action",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.router.back()))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.groundHandlerMessage?.pax_info_new.SFS_LANG_ROW_6), 1)
            ])
          ])
        ], 32)
      ])
    ]),
    _: 1
  }))
}