import { getToken, getUser } from '@/utils/auth'
import store from '@/store'

export default async ({ to, next }) => {
  if (!getUser() || !getToken() || to.query.sc) {
    next({ name: 'Login', query: to.query })
    return false
  } else {
    const user: any = getUser()
    const token: any = getToken()
    store.dispatch('user/setUser', user)
    store.dispatch('user/setToken', token)
  }
  next()
}
