import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { searchAndSelectPassengers } from './searchandselectpassengers'
import { user } from './user'
import { home } from './home'
import { hotel } from './hotel'
import { noHotelsAvailable } from './noHotelsAvailable'
import { voucherSharing } from './vouchersharing'
import { combineVoucher } from './combinevoucher'
import { taxi } from './taxi'

export const routes: Array<RouteRecordRaw> = [
  searchAndSelectPassengers,
  home,
  user,
  voucherSharing,
  combineVoucher,
  hotel,
  noHotelsAvailable,
  taxi
]

const prefix = {
  staging: '/',
  development: '/',
  production: '/'
}

const router = createRouter({
  history: createWebHistory(prefix[process.env.NODE_ENV]),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  function hasQueryParams(route) {
    return !!Object.keys(route.query).length
  }

  // global query params
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ name: to.name as string, query: from.query })
  } else {
    next()
  }
})

export default router
