import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_SFSMatrixSelect = _resolveComponent("SFSMatrixSelect")!
  const _component_StyledSFSMatrixSelectAndSelectBox = _resolveComponent("StyledSFSMatrixSelectAndSelectBox")!

  return (_openBlock(), _createBlock(_component_StyledSFSMatrixSelectAndSelectBox, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_card, { class: "select-box-card" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createTextVNode(_toDisplayString(_ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_29) + " ", 1),
              _createVNode(_component_el_select, {
                modelValue: _ctx.currentOption,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentOption) = $event)),
                class: "m-2 w-19",
                placeholder: "Select",
                size: "large"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectOptions, (item, index) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: index,
                      label: item,
                      value: item,
                      clearable: false,
                      filterable: false
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_SFSMatrixSelect, {
            amount: _ctx.numberOfHotels,
            "list-paxs": _ctx.listPaxs,
            matrix: _ctx.matrix,
            title: _ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_30
          }, null, 8, ["amount", "list-paxs", "matrix", "title"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}