import { loadGMapApi } from '@fawmi/vue-google-maps'

export const setGmapApi = (gmapKey: string) => {
  const gmapOptions = {
    load: {
      key: gmapKey,
      libraries: 'places',
      v: '3.52'
    }
  }

  loadGMapApi(gmapOptions.load)
}
