import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_StyledSFSCheckbox = _resolveComponent("StyledSFSCheckbox")!

  return (_openBlock(), _createBlock(_component_StyledSFSCheckbox, null, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.prop ? 'el-form-item' : 'div'), {
        prop: _ctx.prop,
        class: "content-input"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox, {
            modelValue: _ctx.checked,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event)),
            disabled: _ctx.disabled,
            onChange: _ctx.handleChange
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "disabled", "onChange"])
        ]),
        _: 1
      }, 8, ["prop"]))
    ]),
    _: 1
  }))
}