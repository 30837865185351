import styled from 'vue3-styled-components'

const StyledTaxiService = styled.section`

  .item-voucher-taxi {
    .ws-textbox {
      margin-top: 20px !important;
    }
  }

  .way-option {
    display: flex;
    justify-content: center;

    .content-input {
      display: flex;

      .label-radio {
        margin: 10px;
        .text {
          color: #646464 !important;
        }
      }
    }
  }

  .ws-textbox {
    h2 {
      margin-top: 0px;
      margin-bottom: 10px;
      font-weight: 400;
      color: #646464;
    }
    padding: 1rem;
    letter-spacing: 0.004375rem;
    background-color: #ffffff;
    border-radius: 0.75rem;
    border: none;
    -webkit-box-shadow: 0 0.1875rem 0.375rem #85858529;
    box-shadow: 0 0.1875rem 0.375rem #85858529;
    outline: none;
    line-height: 1.777777778;
    margin: 35px 0px 35px;
  }

  .btn {
    text-align: center;
    margin-bottom: 25px;
    button {
      border: 1px;
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
      background-color: #ffffff;
      letter-spacing: 0px;
      color: #f5d32a;
      font-family: 'Roboto';
    }
  }

  .btn-reserve {
    button {
      border-radius: 17px;
      padding: 10px 50px;
      font-weight: 500;
      font-size: 16px;
      width: 162px;
      height: 44px;
    }
    button:hover {
      background-color: #0094d5;
      border: 0px;
      cursor: pointer;
      span {
        color: #f5d32a;
        font-weight: 500;
      }
    }
  }

  input[type='radio'] {
    margin-top: 0.5rem !important;
  }
  }
`

export default StyledTaxiService
