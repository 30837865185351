import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "my-4" }
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = { class: "mb-4 group-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_ItemOfVouchers = _resolveComponent("ItemOfVouchers")!
  const _component_HotelCard = _resolveComponent("HotelCard")!
  const _component_StyledSFSSelectRoom = _resolveComponent("StyledSFSSelectRoom")!

  return (_openBlock(), _createBlock(_component_StyledSFSSelectRoom, null, {
    default: _withCtx(() => [
      _createElementVNode("section", null, [
        _createVNode(_component_Title, {
          "title-name": _ctx.groundHandlerMessage?.hotel?.SFS_LANG_ROW_44 || 'Home',
          "title-link": "Home"
        }, null, 8, ["title-name"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ItemOfVouchers, {
              icon: "images/icon-voucher/hotel-voucher.png",
              color: "#f0725e",
              label: _ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_1,
              border: ""
            }, null, 8, ["label"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_HotelCard, {
              "select-room": true,
              "hotel-info": _ctx.paxsHotel,
              "is-after": true,
              "cancel-time": _ctx.cancelTime,
              "show-cancel-hotel": _ctx.showCancelHotel,
              "has-cancel-hotel-voucher": !_ctx.pax?.params?.has_cancel_hotel_voucher,
              "checkin-date": _ctx.checkinDate,
              "checkout-date": _ctx.checkoutDate
            }, null, 8, ["hotel-info", "cancel-time", "show-cancel-hotel", "has-cancel-hotel-voucher", "checkin-date", "checkout-date"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                class: "btn-action",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleNext && _ctx.handleNext(...args)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_27), 1)
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}