import authentication from '@/middleware/authentication'
import DefaultLayout from '@/layouts/Default.vue'
import HotelList from '@/views/Hotel/HotelList.vue'
import HotelBook from '@/views/Hotel/HotelBook.vue'
import SelectRoom from '@/views/Hotel/SelectRoom.vue'

export const hotel = {
  path: '/',
  component: DefaultLayout,
  meta: {
    middleware: [authentication]
  },
  children: [
    {
      path: '/hotel-list',
      name: 'HotelList',
      component: HotelList
    },
    {
      path: '/hotel-book',
      name: 'HotelBook',
      component: HotelBook
    },
    {
      path: '/select-room',
      name: 'SelectRoom',
      component: SelectRoom,
      props: (route) => ({
        pax_voucher_id: route.query.pax_voucher_id
      })
    }
  ]
}
