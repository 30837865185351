
import { defineComponent } from 'vue'
import _ from 'lodash'
import StyledSFSGoogleMap from '@/styled-components/components/SFSGoogleMap'
import store from '@/store'
import LangMixinsVue from '../SearchAndSelectPassengers/Mixin/LangMixins.vue'

export default defineComponent({
  components: {
    StyledSFSGoogleMap
  },
  mixins: [LangMixinsVue],
  props: {
    origin: {
      type: Object,
      default: () => {}
    },
    destination: {
      type: Object,
      default: () => {}
    },
    initShowMap: {
      type: Boolean,
      default: false
    }
  },
  emits: ['updateLocation', 'getPriceByDistance', 'clearDestinationAddress'],
  data() {
    return {
      direct_from: {},
      direct_to: {},
      center: { lat: 51.282833, lng: 6.771651 },
      place: null,
      distance: 0,
      directionsService: null,
      directionsDisplay: null,
      currentDestination: {},
      isShowMap: false,
      distanceOriginToDestination: 0,
      distanceDestinationToOrigin: 0,
      destination_display_address: '',
      destination_disabled: false,
      origin_display_address: '',
      origin_disabled: false,
      selectedAddress: {},
      gmapKey: 0,
      placeChanged: false
    }
  },
  watch: {
    origin(data) {
      // Refresh data for GMapAutocomplete
      if (!_.isEmpty(data)) {
        this.origin_display_address = data.display_address
      } else {
        this.origin_display_address = ''
      }

      if (!_.isEmpty(data)) {
        this.origin_disabled = data.disabled
      } else {
        this.origin_disabled = false
      }

      this.direct_from = {
        lat: data.lat,
        lng: data.lng
      }
      this.setCenter()
    },
    destination(data) {
      // Refresh data for GMapAutocomplete
      if (!_.isEmpty(data)) {
        this.destination_display_address = data.display_address
      } else {
        this.destination_display_address = ''
        this.distance = 0 //reset distance to zero when destination is empty
      }

      if (!_.isEmpty(data)) {
        this.destination_disabled = data.disabled
      } else {
        this.destination_disabled = false
      }

      this.direct_to = {
        lat: data.lat,
        lng: data.lng
      }
      this.setCenter()
      this.getDirection() // only call one time when destination change
    }
  },
  mounted() {
    // wait for gmap loaded
    const loadGmapInterval = setInterval(() => {
      if (!_.isNil(window['google'])) {
        this.initMap()
        clearInterval(loadGmapInterval)
      }
    }, 1000)
  },
  methods: {
    initMap() {
      this.direct_from = {
        lat: this.origin.lat,
        lng: this.origin.lng
      }
      this.direct_to = {
        lat: this.destination.lat,
        lng: this.destination.lng
      }
      this.setCenter()
      this.directionsService = new (window as any).google.maps.DirectionsService()
      this.getDirection()
      this.isShowMap = this.initShowMap
    },
    showMap() {
      this.isShowMap = !this.isShowMap
      this.getDirection()
    },
    setCenter() {
      const direct_from = this.direct_from as any
      const direct_to = this.direct_to as any
      if (direct_from.lat && direct_from.lng) {
        this.center = {
          lat: parseFloat(direct_from.lat),
          lng: parseFloat(direct_from.lng)
        }
      } else if (direct_to.lat && direct_to.lng) {
        this.center = {
          lat: parseFloat(direct_to.lat),
          lng: parseFloat(direct_to.lng)
        }
      }
    },
    setOriginPlace(place) {
      let location = {
        lat: parseFloat(place.geometry.location.lat()),
        lng: parseFloat(place.geometry.location.lng()),
        address: place.formatted_address
      }
      let dataUpdate = {
        origin: location
      }
      this.$emit('updateLocation', dataUpdate)
    },
    showAddressAgain() {
      // when user remove address and click mouse outside the address, we will return the last address again
      if (!this.placeChanged) {
        this.$emit('updateLocation', this.selectedAddress)
        this.placeChanged = true
      }
      this.gmapKey += 1
    },
    resetDestination() {
      this.selectedAddress = {}
      this.destination_display_address = ''
      this.distance = 0
      this.$emit('clearDestinationAddress')
    },
    setDestinationPlace(place) {
      this.placeChanged = true
      let location = {
        lat: parseFloat(place.geometry.location.lat()),
        lng: parseFloat(place.geometry.location.lng()),
        address: place.formatted_address
      }
      let dataUpdate = {
        destination: location
      }
      this.selectedAddress = dataUpdate
      this.$emit('updateLocation', dataUpdate)
    },
    getDirection: async function () {
      let self = this
      const direct_from = self.direct_from as any
      const direct_to = self.direct_to as any
      const google = (window as any).google
      if (!direct_from.lat || !direct_from.lng || !direct_to.lat || !direct_to.lng || !google) {
        return
      }
      if (this.directionsDisplay != null) {
        ;(this.directionsDisplay as any).setMap(null)
        this.directionsDisplay = null
      }

      this.directionsDisplay = new google.maps.DirectionsRenderer()
      ;(this.directionsDisplay as any).setMap((this.$refs.map as any)?.$mapObject)
      //google maps API's direction service
      function calculateAndDisplayRoute(directionsService, directionsDisplay, start, destination) {
        return new Promise((resolve, reject) => {
          store.dispatch('incrementLoading')
          directionsService?.route(
            {
              origin: start,
              destination: destination,
              travelMode: google.maps.TravelMode.DRIVING
            },
            (response, status) => {
              if (status === 'OK') {
                directionsDisplay.setDirections(response)
                const route = response.routes[0]
                resolve(route)
              } else {
                self.$emit('clearDestinationAddress')
                self.resetDestination()
                window.alert('Directions request failed due to ' + status)
              }
            }
          )
          store.dispatch('decrementLoading')
        })
      }

      let from = {
        lat: parseFloat(direct_from.lat),
        lng: parseFloat(direct_from.lng)
      }
      let to = {
        lat: parseFloat(direct_to.lat),
        lng: parseFloat(direct_to.lng)
      }
      let route: any = null
      if (!_.isEmpty(from) && !_.isEmpty(to)) {
        route = await calculateAndDisplayRoute(this.directionsService, this.directionsDisplay, to, from)
        this.distanceDestinationToOrigin = route.legs[0].distance.value / 1000
        route = await calculateAndDisplayRoute(this.directionsService, this.directionsDisplay, from, to)
        this.distance = route.legs[0].distance.value / 1000
        this.distanceOriginToDestination = this.distance
      } else this.distance = 0

      this.$emit('getPriceByDistance', this.distanceOriginToDestination, this.distanceDestinationToOrigin)
      return route
    }
  }
})
