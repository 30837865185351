
import { defineComponent, ref, watch } from 'vue'
import { Html5Qrcode } from 'html5-qrcode'
import LangMixinsVue from '@/views/SearchAndSelectPassengers/Mixin/LangMixins.vue'

export default defineComponent({
  mixins: [LangMixinsVue],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '600px'
    }
  },
  emits: ['update:visible', 'scanned'],
  setup(props, { emit }) {
    const dialogVisible = ref<Boolean>(props.visible)
    const cameraStatus = ref<Boolean>(false)

    var html5QrCode

    const handleDialogOpened = () => {
      setupHtml5Qrcode()
    }

    watch(
      () => props.visible,
      (value) => (dialogVisible.value = value)
    )

    watch(dialogVisible, (value) => {
      emit('update:visible', value)
    })

    const setupHtml5Qrcode = () => {
      if (dialogVisible.value) {
        html5QrCode = new Html5Qrcode(`reader`)
        const config = { fps: 10, qrbox: { width: 400, height: 400 } }
        const qrCodeSuccessCallback = (decodedText, decodedResult) => {
          if (decodedResult.result) {
            emit('scanned', decodedText, decodedResult)
            closeOrStopScanBoarding()
          }
        }
        html5QrCode.start({ facingMode: 'environment' }, config, qrCodeSuccessCallback).then(() => {
          cameraStatus.value = true
        })
      }
    }

    const closeOrStopScanBoarding = () => {
      if (cameraStatus.value) {
        html5QrCode
          .stop()
          .then((ignore) => {
            // QR Code scanning is stopped.
            html5QrCode.clear()
            cameraStatus.value = false
            dialogVisible.value = false
          })
          .catch((err) => {
            // Stop failed, handle it.
            console.log('err', err)
          })
      } else {
        dialogVisible.value = false
      }
    }

    return { dialogVisible, handleDialogOpened, closeOrStopScanBoarding }
  }
})
