
// import styled component
import StyledSFSDate from '@/styled-components/components/SFSDate'

import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  components: {
    StyledSFSDate
  },
  props: {
    placeholder: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    modelValue: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    disabledDate: {
      type: Function,
      default: () => null
    },
    prop: {
      type: String,
      default: null
    }
  },
  emits: ['handleChange', 'update:modelValue'],
  setup(props, { emit }) {
    const inputValue = ref<string>(props.modelValue)

    const handleChange = (value) => emit('handleChange', value)

    watch(inputValue, (value) => emit('update:modelValue', value))
    watch(
      () => props.modelValue,
      (value) => {
        inputValue.value = value
      }
    )

    return { inputValue, handleChange }
  }
})
