
import { defineComponent } from 'vue'
import StyledItemOfVoucher from '@/styled-components/components/ItemOfVoucher'

export default defineComponent({
  name: 'ItemOfVoucher',
  components: {
    StyledItemOfVoucher
  },
  props: {
    icon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    pnr: {
      type: String,
      default: ''
    },
    number: {
      type: Number,
      default: 0
    },
    text: {
      type: String,
      default: ''
    },
    disable: {
      type: Boolean,
      default: false
    },
    used: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    url: {
      type: Object,
      default: () => {}
    },
    isHandleClick: {
      type: Boolean,
      default: false
    },
    disableClick: {
      type: Boolean,
      default: false
    },
    cursor: {
      type: String,
      default: ''
    }
  },
  watch: {
    isHandleClick() {
      if (this.isHandleClick && Object.keys(this.url).length) {
        this.$router.push({ path: this.url.path, query: this.url.query })
      }
    }
  },
  methods: {
    handleClickLabel(e, disableClick) {
      if (disableClick) e.preventDefault()
    }
  }
})
