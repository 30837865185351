import styled from 'vue3-styled-components'

const StyledSearchPax = styled.section`
  margin: 0 auto;
  max-width: 1200px;

  .text-bold {
    font-weight: 600;
  }

  .text-name {
    color: #a4aabd;
  }

  button {
    cursor: pointer;
  }

  .btn {
    text-align: center;
    margin-bottom: 25px;
    button {
      border: 1px;
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
      background-color: #ffffff;
      letter-spacing: 0px;
      color: #f5d32a;
      font-family: 'Roboto';
    }
  }

  .btn-back,
  .btn-confirm {
    button {
      border-radius: 17px;
      padding: 10px 50px;
      font-weight: 500;
      font-size: 16px;
      width: 162px;
      height: 44px;
    }
    button:hover {
      background-color: #0094d5;
      border: 0px;
      span {
        color: #f5d32a;
        font-weight: 500;
      }
    }
  }

  .btn__container {
    margin-top: 20px;
  }

  .el-dialog__footer {
    text-align: center;
  }

  .ws-textbox {
    padding: 1rem;
    letter-spacing: 0.004375rem;
    background-color: #ffffff;
    border-radius: 0.75rem;
    border: none;
    -webkit-box-shadow: 0 0.1875rem 0.375rem #85858529;
    box-shadow: 0 0.1875rem 0.375rem #85858529;
    outline: none;
    line-height: 1.777777778;
  }

  .add-pax-label {
    font-size: 20px;
    padding: 10px;
  }

  .pax-detail {
    width: 30%;
  }

  .container {
    filter: none;
  }
  .el-form input[type='radio']:before {
    margin: 18% 13% 20% 19%;
  }
`

export default StyledSearchPax
