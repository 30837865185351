
import { defineComponent } from 'vue'
import StyledSFSTravelersAndServiceInGroup from '@/styled-components/components/SFSTravelersAndServiceInGroup'
export default defineComponent({
  components: {
    StyledSFSTravelersAndServiceInGroup
  },
  props: {
    label: {
      type: String,
      default: null
    },
    listPax: {
      type: Array,
      default: () => []
    }
  }
})
