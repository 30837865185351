
import { defineComponent } from 'vue'
import { CONFIRM_THIS_IS_ME_RULES } from '@/constants/searchPax'
import StyledConfirmThisIsMe from '@/styled-components/pages/search-and-select-passengers/confirmThisIsMe'
import store from '@/store'
import SearchPaxMixinsVue from './Mixin/SearchPaxMixins.vue'
import LangMixinsVue from './Mixin/LangMixins.vue'
import { useRouter } from 'vue-router'
import { isInfant } from '@/utils/passenger'
import { ElNotification } from 'element-plus'

export default defineComponent({
  name: 'ConfirmThisIsMe',
  components: {
    StyledConfirmThisIsMe
  },
  mixins: [SearchPaxMixinsVue, LangMixinsVue],
  props: {
    listGroupPaxs: {
      type: Array,
      default: () => []
    },
    myId: {
      type: Number,
      default: undefined
    },
    flightInfo: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['updateStep', 'update:myId', 'update:listGroupPaxs'],
  data() {
    return {
      confirmThisIsMeForm: {
        mySelectedId: this.myId
      },
      confirmThisIsMeRules: CONFIRM_THIS_IS_ME_RULES,
      router: useRouter()
    }
  },
  methods: {
    backStep() {
      // this.$emit('updateStep', 'search')
      this.$emit('updateStep', 'searchOption', true)
    },
    handleConfirm() {
      const form = this.$refs.form as any
      form.validate((valid) => {
        if (!valid) {
          ElNotification.closeAll()
          ElNotification({
            title: 'Error',
            message: 'Please choose passenger',
            type: 'error',
            duration: 3000
          })
          return
        }
        // Find pax this is me
        const paxThisIsMe: any = this.listGroupPaxs.find((pax: any) => pax.id === this.confirmThisIsMeForm.mySelectedId)

        if (isInfant(paxThisIsMe)) {
          ElNotification.closeAll()
          ElNotification({
            title: 'Error',
            message: 'Infant passengers cannot confirm "This is me."',
            type: 'error',
            duration: 3000
          })
          return
        }

        this.$emit('update:myId', this.confirmThisIsMeForm.mySelectedId)
        this.router.replace({ query: { passenger_id: this.confirmThisIsMeForm.mySelectedId } })
        store.dispatch('groupPax/setMe', paxThisIsMe)

        const newGroup = this.filterPassengersHasMyGroupId(paxThisIsMe, this.listGroupPaxs)
        this.$emit('update:listGroupPaxs', newGroup)
        this.$emit('updateStep', 'areYouTravelingAlone')
      })
    }
  }
})
