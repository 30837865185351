
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import StyledVoucherSharing from '@/styled-components/pages/voucher-sharing'
import ItemOfVoucher from '@/components/ItemOfVoucher/Index.vue'
import SFSTravelersAndServiceInGroup from '@/components/SFSTravelersAndServiceInGroup.vue'
import FetchAllMixinsVue from '@/views/Mixins/FetchAllMixins.vue'
import { StoreType } from '@/types/store'
import { ref } from 'vue'
import LangMixinsVue from '../SearchAndSelectPassengers/Mixin/LangMixins.vue'
import { MEAL } from '@/constants/mealPlan'

export default defineComponent({
  name: 'HotelSharing',
  components: {
    StyledVoucherSharing,
    ItemOfVoucher,
    SFSTravelersAndServiceInGroup
  },
  mixins: [FetchAllMixinsVue, LangMixinsVue],
  setup() {
    const store = useStore<StoreType>()
    const router = useRouter()
    const listMealPlan = ref({})
    const isHaveMealPlan = ref(false)
    const isNotShowMealPlanService = ref(false)
    return {
      listMyGroupPaxs: computed(() => store.getters['groupPax/listGroupPax']),
      vouchers: computed(() => store.state.vouchers),
      mealPlanList: computed(() => store.state.mealPlan.listMealPlanInfo),
      listMealPlan,
      isHaveMealPlan,
      isNotShowMealPlanService,
      router
    }
  },
  created() {
    this.getListMealPlan()
  },
  methods: {
    backToHomePage() {
      this.router.push({ name: 'Home' })
    },
    async getListMealPlan() {
      if (Object.keys(this.vouchers).length === 0) {
        await this.fetchVouchers()
        await this.fetchMealPlanVoucher()
      }
      const serviceIdMealPlan = 23
      //Because error Proxy if use this.vouchers.find so need to convert to another
      const vouchers = JSON.parse(JSON.stringify(this.vouchers))
      const mealPlanVoucher = vouchers.find((voucher) => {
        return voucher.service_id == serviceIdMealPlan
      })
      if (!mealPlanVoucher) {
        return ''
      } else {
        const isNotShowMealPlanService = JSON.parse(mealPlanVoucher.params).has_cancelled_meal_plan_voucher
        if (isNotShowMealPlanService) {
          this.isNotShowMealPlanService = true
        }
      }
      this.isHaveMealPlan = true
      let paramMealPlan = JSON.parse(mealPlanVoucher.params)
      let isUseOldMealPlan = paramMealPlan?.is_use_old_meal_plan
      if (isUseOldMealPlan) {
        let countBreakfast = 0
        let countLunch = 0
        let countDinner = 0
        this.mealPlanList.forEach((item) => {
          if (item.number_breakfast > 0) {
            countBreakfast += item.number_breakfast
          }

          if (item.number_lunch > 0) {
            countLunch += item.number_lunch
          }

          if (item.number_dinner > 0) {
            countDinner += item.number_dinner
          }
        })

        this.listMealPlan[this.groundHandlerMessage?.hotel.SFS_LANG_ROW_14] = countBreakfast
        this.listMealPlan[this.groundHandlerMessage?.hotel.SFS_LANG_ROW_15] = countLunch
        this.listMealPlan[this.groundHandlerMessage?.hotel.SFS_LANG_ROW_16] = countDinner
      } else {
        let countBreakfast = 0
        let countLunch = 0
        let countDinner = 0
        if (!this.mealPlanList.length) return ''
        this.mealPlanList.forEach((item) => {
          if (item.type == MEAL.BREAKFAST || item.type == MEAL.INCLUDED_BREAKFAST) {
            countBreakfast++
          } else if (item.type == MEAL.LUNCH) {
            countLunch++
          } else if (item.type == MEAL.DINNER) {
            countDinner++
          }
        })
        this.listMealPlan[this.groundHandlerMessage?.hotel.SFS_LANG_ROW_14] = countBreakfast
        this.listMealPlan[this.groundHandlerMessage?.hotel.SFS_LANG_ROW_15] = countLunch
        this.listMealPlan[this.groundHandlerMessage?.hotel.SFS_LANG_ROW_16] = countDinner
      }
    }
  }
})
