
import { defineComponent } from 'vue'
import StyledNavbar from '@/styled-components/components/SFSNavbar'
import store from '@/store'

type Language = {
  lang_code: string
  name: string
  direction: string
}

export default defineComponent({
  name: 'SFSNavbar',
  components: {
    StyledNavbar
  },
  props: {
    airlineLogo: {
      type: String,
      default: ''
    },
    selectedLang: {
      type: String,
      default: 'en'
    },
    langs: {
      type: Array as () => Language[], // Type assertion for currentLanguage prop
      default: () => [{ lang_code: 'en', name: 'English', direction: 'ltr' }] as Language[] // Default value as an object of Language
    }
  },
  data() {
    return {
      showLangList: false,
      showSwtichLangButton: true
    }
  },
  computed: {
    isPrintServicePage() {
      return this.$route.path === '/print-services'
    }
  },
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      this.showLangList = false
      next()
    })
  },
  methods: {
    reload() {
      location.href = '/'
    },
    changeCurrentLanguage(lang_code: string) {
      let lang = lang_code ?? 'en'
      let direction = this.langs.find((lang) => lang.lang_code === lang_code)?.direction ?? 'ltr'
      store.dispatch('lang/setCurrentLanguage', [lang, direction])
      window.location.reload()
    }
  }
})
