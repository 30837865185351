import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "search-pax__container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "ws-textbox"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }
const _hoisted_7 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchOption = _resolveComponent("SearchOption")!
  const _component_Search = _resolveComponent("Search")!
  const _component_SFSRadio = _resolveComponent("SFSRadio")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_SFSModal = _resolveComponent("SFSModal")!
  const _component_ConfirmThisIsMe = _resolveComponent("ConfirmThisIsMe")!
  const _component_AreYouTravelingAlone = _resolveComponent("AreYouTravelingAlone")!
  const _component_SelectPaxTraveling = _resolveComponent("SelectPaxTraveling")!
  const _component_AddMorePax = _resolveComponent("AddMorePax")!
  const _component_StyledSearchPax = _resolveComponent("StyledSearchPax")!

  return (_openBlock(), _createBlock(_component_StyledSearchPax, null, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        (_ctx.step == 'searchOption')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_SearchOption, {
                "search-type": _ctx.searchType,
                "onUpdate:search-type": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchType) = $event)),
                "handle-scan": _ctx.handleQRScan,
                onUpdateStep: _ctx.updateStep
              }, null, 8, ["search-type", "handle-scan", "onUpdateStep"])
            ]))
          : (_ctx.step == 'search')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_Search, {
                  flightId: _ctx.selectedFlight.flightId,
                  "onUpdate:flightId": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedFlight.flightId) = $event)),
                  "passenger-search-info": _ctx.passengerSearchInfo,
                  "search-type": _ctx.searchType,
                  submit: _ctx.handleSearchPax,
                  onUpdateStep: _ctx.updateStep
                }, null, 8, ["flightId", "passenger-search-info", "search-type", "submit", "onUpdateStep"]),
                _createVNode(_component_SFSModal, {
                  "show-modal": _ctx.isShowModalSelectFlight,
                  title: _ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_23,
                  "cancel-text": _ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_27,
                  "confirm-text": _ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_24,
                  onClose: _ctx.handleCloseModalSelectFlight,
                  onConfirm: _ctx.handleSelectFlight
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ElForm, {
                      ref: "selectFlightForm",
                      model: _ctx.selectedFlight,
                      rules: _ctx.selectFlightRules,
                      onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_SFSRadio, {
                          modelValue: _ctx.selectedFlight.flightId,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedFlight.flightId) = $event)),
                          prop: "flightId",
                          items: 
                _ctx.flights.map((f) => {
                  return {
                    value: f.id,
                    label: `${f.flight_number} ${_ctx.formatDate(f.flight_date, 'DD-MMM-YYYY', true)} ${f.dep}-${f.div}`
                  }
                })
              
                        }, null, 8, ["modelValue", "items"])
                      ]),
                      _: 1
                    }, 8, ["model", "rules"])
                  ]),
                  _: 1
                }, 8, ["show-modal", "title", "cancel-text", "confirm-text", "onClose", "onConfirm"])
              ]))
            : (_ctx.step == 'confirmThisIsMe')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_ConfirmThisIsMe, {
                    "my-id": _ctx.myId,
                    "onUpdate:my-id": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.myId) = $event)),
                    "list-group-paxs": _ctx.listGroupPaxs,
                    "onUpdate:list-group-paxs": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.listGroupPaxs) = $event)),
                    "flight-info": _ctx.flightInfo,
                    onUpdateStep: _ctx.updateStep
                  }, null, 8, ["my-id", "list-group-paxs", "flight-info", "onUpdateStep"])
                ]))
              : (_ctx.step == 'areYouTravelingAlone')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_AreYouTravelingAlone, {
                      "list-selected-pax-ids-on-flight-include-my-group-and-other-groups": 
            _ctx.listSelectedPaxIdsOnFlightIncludeMyGroupAndOtherGroups
          ,
                      "onUpdate:list-selected-pax-ids-on-flight-include-my-group-and-other-groups": _cache[6] || (_cache[6] = ($event: any) => ((
            _ctx.listSelectedPaxIdsOnFlightIncludeMyGroupAndOtherGroups
          ) = $event)),
                      "my-id": _ctx.myId,
                      "list-group-paxs": _ctx.listGroupPaxs,
                      onUpdateStep: _ctx.updateStep
                    }, null, 8, ["list-selected-pax-ids-on-flight-include-my-group-and-other-groups", "my-id", "list-group-paxs", "onUpdateStep"])
                  ]))
                : (_ctx.step == 'selectPaxTraveling')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_SelectPaxTraveling, {
                        "list-selected-group-pax-ids": _ctx.listSelectedPaxIdsOnFlightIncludeMyGroupAndOtherGroups,
                        "list-group-paxs": _ctx.listGroupPaxsAndListSearchedOtherPaxsOnFlight,
                        "my-id": _ctx.myId,
                        onUpdateStep: _ctx.updateStep
                      }, null, 8, ["list-selected-group-pax-ids", "list-group-paxs", "my-id", "onUpdateStep"])
                    ]))
                  : (_ctx.step == 'addMorePax')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_AddMorePax, {
                          "list-searched-other-paxs-on-flight": _ctx.listSearchedOtherPaxsOnFlight,
                          "onUpdate:list-searched-other-paxs-on-flight": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.listSearchedOtherPaxsOnFlight) = $event)),
                          "list-group-paxs": _ctx.listGroupPaxs,
                          "onUpdate:list-group-paxs": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.listGroupPaxs) = $event)),
                          "list-selected-group-pax-ids": _ctx.listSelectedPaxIdsOnFlightIncludeMyGroupAndOtherGroups,
                          "onUpdate:list-selected-group-pax-ids": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.listSelectedPaxIdsOnFlightIncludeMyGroupAndOtherGroups) = $event)),
                          "my-id": _ctx.myId,
                          onUpdateStep: _ctx.updateStep
                        }, null, 8, ["list-searched-other-paxs-on-flight", "list-group-paxs", "list-selected-group-pax-ids", "my-id", "onUpdateStep"])
                      ]))
                    : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}