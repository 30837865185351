
import { defineComponent, PropType, reactive, watch, onBeforeMount, ref } from 'vue'
import StyledSFSMatrixSelect from '@/styled-components/components/SFSMatrixSelect'
import _ from 'lodash'
import { isInfant } from '@/utils/passenger'

export default defineComponent({
  name: 'SFSMatrixSelect',
  components: {
    StyledSFSMatrixSelect
  },
  props: {
    listPaxs: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    amount: {
      type: Number,
      default: 1
    },
    isAirlineSet: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    matrix: {
      type: Array as PropType<number[]>,
      default: () => []
    },
    airlineMatrix: {
      type: Array as PropType<number[]>,
      default: () => []
    }
  },
  emits: ['update:matrix'],
  setup(props, { emit }) {
    const dataSelect = reactive<number[]>(props.matrix)
    const renderKey = ref(0)
    const changeMatrix = ref(false)
    const handleSelectMatrix = (index, e) => {
      changeMatrix.value = true
      renderKey.value++
      dataSelect[index] = +e.target.value
    }

    const setMatrixData = () => {
      if (!props.disabled && !changeMatrix.value) {
        for (let index = 0; index < props.listPaxs.length; index++) {
          // check index = 0
          if (!index) {
            dataSelect[index] = index + 1
            continue
          }
          dataSelect[index] = dataSelect[index - 1] + 1
          // check dataSelect before >= amount -> if true come back assign 1
          if (dataSelect[index - 1] >= props.amount) {
            dataSelect[index] = 1
          }
        }
      }
    }

    watch(
      () => props.amount,
      (newAmountValue) => {
        changeMatrix.value = false

        const maxNumberSelect = Math.max(...dataSelect)
        const isMatrixEmpty = dataSelect.every((item) => item === null || item === undefined)

        // case empty matrix => set default matrix
        if (isMatrixEmpty) {
          renderKey.value++
          setMatrixData()
          return
        }
        // case has matrix
        if (isNaN(maxNumberSelect)) {
          return
        }
        // case has matrix and current matrix satisfy the condition of amount => set default matrix again
        if (maxNumberSelect > newAmountValue) {
          renderKey.value++
          setMatrixData()
        }
      }
    )

    watch(
      () => dataSelect,
      () => emit('update:matrix', dataSelect)
    )

    return { dataSelect, isInfant, handleSelectMatrix, renderKey }
  }
})
