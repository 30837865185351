import jwt_decode from 'jwt-decode'
import store from '@/store'
import Auth from '@/models/Auth'
import { setToken, setUser } from './auth'

export async function handleLogin(shortCode?: string, user?: { username; password }): Promise<Boolean> {
  let data
  if (shortCode) data = await new Auth().loginWithShortCode(shortCode)
  else if (user) data = await new Auth().loginWithUsernameAndPassword(user.username, user.password)

  const { token, success } = data
  if (success && token) {
    const user: any = jwt_decode(token)
    store.dispatch('user/setUser', user)
    store.dispatch('user/setToken', token)

    setToken(token)
    setUser(user)
    return true
  }
  return false
}
