import styled from 'vue3-styled-components'

const SFSInput = styled.div`
  width: 100%;
  .is-error {
    margin-bottom: 20px !important;
  }

  .el-input,
  .el-textarea {
    border-radius: 5px;
    margin-bottom: 0.5rem;
    input {
      font-size: 1rem;
      width: 100%;
      box-shadow: 0 0 10px 3px rgb(0 1 1 / 16%);
      margin-bottom: 0;
    }
    .el-input-group__append,
    .el-input-group__prepend {
      background: #ebebeb;
      width: 50px;
      height: 50px;
      color: #3b4559;
      border: none;
    }
  }
  textarea {
    font-size: 1rem;
    border-radius: 5px;
  }
  p {
    font-size: 1rem;
  }
`

export default SFSInput
