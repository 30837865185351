
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import StyledVoucherSharing from '@/styled-components/pages/voucher-sharing'
import ItemOfVoucher from '@/components/ItemOfVoucher/Index.vue'
import FetchAllMixinsVue from '../Mixins/FetchAllMixins.vue'
import { StoreType } from '@/types/store'
import _ from 'lodash'
import moment from 'moment'
import LangMixinsVue from '@/views/SearchAndSelectPassengers/Mixin/LangMixins.vue'

export default defineComponent({
  name: 'MealPlanSharing',
  components: {
    StyledVoucherSharing,
    ItemOfVoucher
  },
  mixins: [FetchAllMixinsVue, LangMixinsVue],
  setup() {
    const store = useStore<StoreType>()
    const router = useRouter()

    return {
      listGroupPaxExcludeMe: computed(() => store.state.groupPax?.listGroupPaxExcludeMe),
      userMe: computed(() => store.state.groupPax?.me),
      vouchers: computed(() => store.state.vouchers),
      mealPlanList: computed(() => store.state.mealPlan.listMealPlanInfo),
      lang: computed(() => store.state.passenger.lang),
      airline: computed(() => store.state.airline),
      breakfast: 'Breakfast',
      lunch: 'Lunch',
      dinner: 'Dinner',
      mealPlanInDay: ['Breakfast', 'Lunch', 'Dinner'],
      router
    }
  },
  created() {
    console.log(JSON.stringify(this.groundHandlerMessage?.meal_plan_sharing))
    this.fetchMealPlanVoucher()
  },
  methods: {
    backToHomePage() {
      this.router.push({ name: 'Home' })
    },
    getLang(string) {
      switch (string) {
        case 'Breakfast':
          return this.groundHandlerMessage?.meal_plan_sharing.SFS_LANG_ROW_6 || string
        case 'Lunch':
          return this.groundHandlerMessage?.meal_plan_sharing.SFS_LANG_ROW_7 || string
        case 'Dinner':
          return this.groundHandlerMessage?.meal_plan_sharing.SFS_LANG_ROW_8 || string
        default:
          return string
      }
    },
    formatDate(date) {
      if (!date) return ''
      return moment(date).format('DD MMM YYYY')
    },
    getTimeMealPlan(meal_plan) {
      let during_time = ''
      if (meal_plan.valamount == 0) {
        let temp_during_time = JSON.parse(meal_plan.during_time)
        if (!temp_during_time.from && !temp_during_time.to) {
          return
        }
        during_time =
          temp_during_time.from +
          ' ' +
          (this.groundHandlerMessage?.meal_plan_sharing.SFS_LANG_ROW_13 || 'till') +
          ' ' +
          temp_during_time.to
      } else {
        if (!meal_plan.mealplan_expired) return ''

        const mealplan_time = moment(meal_plan.mealplan_expired).format('HH:mm DD MMM YYYY')
        during_time = (this.groundHandlerMessage?.meal_plan_sharing.SFS_LANG_ROW_10 || 'Expire time:') + ' ' + mealplan_time
        if (meal_plan.airport_name) {
          const mealPlanSharingLangRow14 = this.groundHandlerMessage?.meal_plan_sharing.SFS_LANG_ROW_14 || 'by'
          during_time += ' ' + mealPlanSharingLangRow14 + ' ' + meal_plan.airport_name
        }
      }

      return during_time
    },
    isBreakfastHrsInclude(meal_plan) {
      return !!(parseFloat(meal_plan.valamount) == 0 && meal_plan.isHrs)
    },
    formatDateOldMealPlan(date, format = 'DD MMM YYYY') {
      if (!date) return ''
      return moment(date).format(format)
    },
    getTimeOldMealPlan(mealPlanData, mealPlan) {
      // Date in js   with sunday return 0, monday return 1
      // Date in data with sunday return 6, monday return 0
      const date = new Date(mealPlanData.date)
      const day = date.getDay() > 0 ? date.getDay() - 1 : 6
      let infoTime
      if (mealPlan == this.breakfast) {
        infoTime = mealPlanData.breakfast_operating_time
      } else if (mealPlan == this.lunch) {
        infoTime = mealPlanData.lunch_operating_time
      } else if (mealPlan == this.dinner) {
        infoTime = mealPlanData.dinner_operating_time
      }
      if (infoTime === '' || infoTime == null) {
        return ''
      }
      const time = JSON.parse(infoTime)[day].data
      if (time.operating_opentime == null && time.operating_closetime == null) {
        return ''
      }
      return (
        time.operating_opentime +
        ' ' +
        (this.groundHandlerMessage?.meal_plan_sharing.SFS_LANG_ROW_13 || 'till') +
        ' ' +
        time.operating_closetime
      )
    },
    checkHaveMealPlan(data, m) {
      if (m == this.breakfast) {
        return data.breakfast
      } else if (m == this.lunch) {
        return data.lunch
      } else if (m == this.dinner) {
        return data.mealplan
      }
      return false
    },
    checkPrice(data, m) {
      let price = 0
      if (m == this.breakfast) {
        price = data.breakfast_price
      } else if (m == this.lunch) {
        price = data.lunch_price
      } else if (m == this.dinner) {
        price = data.dinner_price
      }
      return Number(this.formatPrice(price))
    },
    getPrice(data, m) {
      let price = 0
      if (m == this.breakfast) {
        price = data.breakfast_price
      } else if (m == this.lunch) {
        price = data.lunch_price
      } else if (m == this.dinner) {
        price = data.dinner_price
      }
      return this.formatPrice(price)
    },
    formatPrice(price) {
      return price.toString().replace('.00', '')
    },
    description() {
      if (Object.keys(this.mealPlanList).length === 0) {
        return
      }

      // Old meal plan
      if (JSON.parse(this.mealPlanList[0].params) && JSON.parse(this.mealPlanList[0].params).is_use_old_meal_plan) {
        let mealPlanDesc =
          (this.groundHandlerMessage?.meal_plan_sharing.SFS_LANG_ROW_5 ||
            'With your hotel booking you receive the following meals.') + ': '

        if (this.mealPlanList.length > 1) {
          const mealPlanDecsItems = this.mealPlanList.map(
            (item) => `<br/>${this.formatDateOldMealPlan(item.date, 'DD-MMM-YYYY')}: ${item.mealplan_desc}`
          )
          mealPlanDesc += mealPlanDecsItems.join('')

          return mealPlanDesc.replace('.:', ':')
        }

        mealPlanDesc += this.mealPlanList[0]?.mealplan_desc
        return mealPlanDesc.replace('.:', ':')
      }

      // New Meal Plan
      return (
        this.groundHandlerMessage?.meal_plan_sharing.SFS_LANG_ROW_1 ||
        'With your hotel booking you receive the following meals. These are included in the hotel booking or you can claim it back with the receipt.'
      )
    },
    checkOldMealPlan(mealPlan) {
      if (mealPlan.params) {
        return JSON.parse(this.mealPlanList[0].params).is_use_old_meal_plan ?? false
      }
      return false
    },
    setListGroup() {
      const result: string[] = []
      this.listGroupPaxExcludeMe.forEach((item) => {
        result.push(item.first_name + ' ' + item.last_name)
      })
      result.push(this.userMe?.first_name + ' ' + this.userMe?.last_name)
      return result
    },
    checkExpired(meal) {
      if (!meal) {
        return true
      }

      const expired = moment(meal.mealplan_expired)
      const airportTime = moment(meal.currentTime)
      if (meal.is_used || parseFloat(meal.valamount) == 0) {
        return false
      }

      return airportTime > expired ? true : false
    }
  }
})
