import styled from 'vue3-styled-components'
const Default = styled('div')`
  margin: 0 auto;
  max-width: 1200px;
  min-height: 100vh;
  background-color: #ffffff;
  overflow: hidden;
  .default__container {
    min-height: calc(100vh - 60px - 54px - 12px);
    padding: 0 22px;
    margin-top: 70px;
    .station-handler-ticket {
      height: 60px;
      border-radius: 12px;
      filter: drop-shadow(0px 2px 6px rgba(51, 51, 51, 0.1));
      background-color: #ffcf0f;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      h1 {
        font-size: 20px;
        color: #474747;
        font-weight: 400;
        font-family: 'Roboto';
      }
    }
  }
  @media print {
    @page {
      size: A3;
    }
    body *,
    .station-handler-ticket,
    .footer__container {
      visibility: hidden;
      margin: 0;
    }
    .station-handler-ticket,
    .navbar__container,
    .print-btn {
      display: none !important;
    }
    .default__container {
      margin-top: unset;
    }
    #section-to-print,
    #section-to-print * {
      visibility: visible;
    }
    #section-to-print {
      scale: 1;
      background: #fff;
      transform-origin: top left;
    }
    .pagebreak {
      page-break-after: always;
    }
  }

  .loader {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    position: fixed;
    top: 50%;
    left: calc((100% - 70px) / 2);
    animation: rotate 1s linear infinite;
    &::before {
      content: '';
      background: transparent;
      box-sizing: border-box;
      opacity: 0.95;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 5px solid #ffcf0f;
      animation: prixClipFix 2s linear infinite;
      z-index: 11000;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes prixClipFix {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11000;

    & ~ * {
      opacity: 50%;
    }
  }
`

export default Default
