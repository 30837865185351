import styled from 'vue3-styled-components'

const StyledNoHotelsAvailable = styled.section`
  #myBar {
    width: 10%;
    height: 15px;
    background-color: #4caf50;
    text-align: center;
    line-height: 30px;
    color: white;
  }
  #myProgress {
    width: 80%;
    margin: auto;
    background-color: #ddd;
  }
  .ws-textbox {
    text-align: center;
  }
  .ws-textbox p {
    margin-top: 20px;
    color: #6a6b6bf0;
    line-height: 33px;
    font-size: 21px;
  }
  .change-room-allocation {
    border-radius: 12px;
  }
  .change-room-allocation:hover {
    cursor: pointer;
  }
`

export default StyledNoHotelsAvailable
