import styled from 'vue3-styled-components'

const SFSRadio = styled.section`
  .el-dialog {
    .el-dialog__body {
      padding-top: 0;
    }
  }
  .label-radio {
    display: flex;
    .text {
      margin-inline: 10px;
      color: #646464;
    }
  }
  .label-radio input[disabled] {
    cursor: not-allowed;
  }

  input[type='radio']:checked {
    box-shadow: 0 0 0 2px #00c365;
  }

  input[type='radio']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }

  input[type='radio']:checked:before {
    background: #00c365;
  }

  input[type='radio'] {
    margin-top: 10px;
    -webkit-appearance: none;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    outline: none;
    box-shadow: 0 0 0 2px gray;
  }
`

export default SFSRadio
