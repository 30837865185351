import moment from 'moment'

export const formatDateTime = (date?: DateTime): string => {
  return date === null || date === '' ? '' : moment().format('MM/DD/YYYY hh:mm A')
}

export const formatDate = (date?: DateTime, format: string = 'MM/DD/YYYY', isIgnoreTimeZone?: boolean): string => {
  if (!isIgnoreTimeZone) {
    return date === null || date === '' ? '' : moment(date).format(format)
  }
  return moment.utc(date).format(format)
}

export const dayOfWeek = (date?: DateTime): number => {
  return moment(date).days() - 1
}

export const getMinMaxDate = (dates: DateTime[]) => {
  const validDates = dates?.filter((dateString) => moment(dateString).isValid())?.map((dateString) => moment(dateString))

  const minDate = moment.min(validDates).format('YYYY-MM-DD')
  const maxDate = moment.max(validDates).format('YYYY-MM-DD')

  return { minDate, maxDate }
}

type DateTime = string | Date
