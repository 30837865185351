
import { computed, defineComponent } from 'vue'
import { ThemeProvider } from 'vue3-styled-components'
import Navbar from '@/components/SFSNavbar.vue'
import Footer from '@/components/SFSFooter.vue'
import Default from '@/styled-components/layouts/Default'
import LangMixinsVue from '@/views/SearchAndSelectPassengers/Mixin/LangMixins.vue'
import CommonLayout from './CommonLayout.vue'
import store from '@/store'

export default defineComponent({
  name: 'LayoutDefault',
  components: {
    Navbar,
    Footer,
    Default,
    CommonLayout,
    ThemeProvider
  },
  mixins: [LangMixinsVue],
  setup() {
    const airlineLogo = computed(
      () => store.state.airline?.templateConfig && store.state.airline.templateConfig.logo_header_mobile
    )
    const langs = computed(() => store.state.airline?.langs)
    const selectedLang = store.getters['lang/selectedLang']
    return {
      airlineLogo,
      langs,
      selectedLang,
      loading: computed(() => store.state.loading > 0)
    }
  }
})
