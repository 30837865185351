import { BaseResponse } from '../types/global'
import { HTTPBaseService } from '@/utils/request'
import { FetchLangType } from '@/types/user'

export default class Lang extends HTTPBaseService {
  constructor(service = 'station-handler') {
    super(service)
  }

  /**
   * sfs-api
   */
  public async fetchLangByType(params: FetchLangType): Promise<BaseResponse> {
    const { data } = await this.instance.get('language', { params })
    return data
  }
}
