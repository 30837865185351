import { PassengerType } from '@/types/passenger'
import { isJson } from '@/utils/string'
import { getterTree, mutationTree, actionTree } from 'typed-vuex'
type RootState = ReturnType<typeof state>

export interface GroupPaxStateType {
  me?: PassengerType
  listGroupPaxExcludeMe: PassengerType[]
}

export const initGroupPaxStore = { me: undefined, listGroupPaxExcludeMe: [] } as GroupPaxStateType

export const state = () => initGroupPaxStore

export const getters = getterTree(state, {
  listGroupPax: (state: RootState) => {
    const list = [...state.listGroupPaxExcludeMe, state.me]
    //sort the list by passenger_id asc
    return list.sort((a, b) => {
      const idA = a?.passenger_id !== undefined ? a.passenger_id : 0
      const idB = b?.passenger_id !== undefined ? b.passenger_id : 0
      return idA - idB
    })
  }
})

export const mutations = mutationTree(state, {
  setMe(state: RootState, me: PassengerType) {
    me.id = me.id ?? me.passenger_id
    me.ssrs = isJson(me.ssrs) ? JSON.parse(me.ssrs) : me.ssrs
    state.me = me
  },
  setListGroupPaxExcludeMe(state: RootState, listGroupPaxExcludeMe: PassengerType[]) {
    listGroupPaxExcludeMe.forEach((item) => {
      item.id = item.id ?? item.passenger_id
      item.ssrs = isJson(item.ssrs) ? JSON.parse(item.ssrs) : item.ssrs
    })
    state.listGroupPaxExcludeMe = listGroupPaxExcludeMe
  }
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    setMe({ commit }, me: PassengerType) {
      commit('setMe', me)
    },
    setListGroupPaxExcludeMe({ commit }, listGroupPaxExcludeMe: PassengerType[]) {
      commit('setListGroupPaxExcludeMe', listGroupPaxExcludeMe)
    }
  }
)
