import { App } from 'vue'
import { limit } from '@/utils/string'
import { formatDateTime, formatDate } from '@/utils/date'
import { replaceStr } from '@/utils/variables'

const register = (app: App<Element>): void => {
  app.config.globalProperties.$filters = {
    limit,
    formatDateTime,
    formatDate,
    replaceStr
  }
}

export default {
  register
}
