import styled from 'vue3-styled-components'

const SFSModalV2 = styled.section`
  .dialog-footer {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    div {
      margin: 0px 10px 0px;
    }
  }

  .btn-modal {
    border-radius: 17px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
    background-color: #ffffff;
    letter-spacing: 0px;
    color: #f5d32a;
    font-family: 'Roboto';
    text-align: center;
    width: 162px;
    height: 44px;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-top: 30px;
    &:hover {
      background-color: #0094d5;
      border: 0px;
      span {
        color: #f5d32a;
        font-weight: 500;
      }
    }
  }
`

export default SFSModalV2
