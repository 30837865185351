
import { defineComponent, computed } from 'vue'
import CommonLayout from '@/styled-components/layouts/CommonLayout'
import { useStore } from 'vuex'
import FetchAllMixinsVue from '@/views/Mixins/FetchAllMixins.vue'
import { StoreType } from '@/types/store'
import store from '@/store'

export default defineComponent({
  components: {
    CommonLayout
  },
  mixins: [FetchAllMixinsVue],
  setup() {
    const store = useStore<StoreType>()
    return {
      loading: computed(() => store.state.loading > 0)
    }
  },
  mounted: async function () {
    await Promise.all([this.fetchAll(), this.fetchAirline()])
    await Promise.all([this.fetchOvernightStation(), this.fetchDetailTaxi(), this.fetchVoucherHotel(), this.fetchFlightInfo()])
    this.addFaviconToHeader()
  },
  methods: {
    addFaviconToHeader() {
      const existingFavicon = document.getElementById('favicon')
      const favicon = store.state?.airline?.templateConfig?.mb_favicon || process.env?.BASE_URL + 'favicon.ico'
      if (!existingFavicon && favicon) {
        const link = document.createElement('link')
        link.rel = 'icon'
        link.id = 'favicon'
        link.type = 'image/x-icon'
        link.href = favicon

        const head = document.head || document.getElementsByTagName('head')[0]
        head.appendChild(link)
      }
    }
  }
})
