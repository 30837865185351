
import { defineComponent, ref } from 'vue'
import StyledLogin from '@/styled-components/pages/login'
import { useRoute, useRouter } from 'vue-router'
import { handleLogin } from '@/utils/login'
import { ElNotification } from 'element-plus'

export default defineComponent({
  name: 'UserLogin',
  components: {
    StyledLogin
  },
  setup() {
    const username = ref<string>('')
    const password = ref<string>('')
    const disabled = ref<boolean>(false)
    const router = useRouter()
    const route = useRoute()

    return {
      username,
      password,
      disabled,
      router,
      route
    }
  },
  async mounted() {
    setTimeout(() => {
      const notification = (this as any).$route.query?.notification
      if (notification) {
        ElNotification({
          title: 'Error',
          message: notification,
          type: 'error',
          duration: 3000
        })
        this.router.replace({ query: { notification: null } })
      }
    }, 1000)

    if (!this.route.query.sc) return
    const result = await handleLogin(this.route.query.sc as string)
    if (!result) return
    this.router.push({ name: 'SearchAndSelectPassengers', query: { passenger_id: null } })
  },
  methods: {
    async login() {
      this.disabled = true
      const success = await handleLogin(undefined, { username: this.username, password: this.password })
      if (success) {
        // go to requested page before
        this.router.push({ name: 'SearchAndSelectPassengers', query: { passenger_id: null } })
      }
      this.disabled = false
    }
  }
})
