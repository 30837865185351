import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "ws-textbox add-pax" }
const _hoisted_2 = { class: "add-pax-label" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "btn btn-back" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemOfVoucher = _resolveComponent("ItemOfVoucher")!
  const _component_SearchOption = _resolveComponent("SearchOption")!
  const _component_Search = _resolveComponent("Search")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_SFSModal = _resolveComponent("SFSModal")!
  const _component_StyledAddMorePax = _resolveComponent("StyledAddMorePax")!

  return (_openBlock(), _createBlock(_component_StyledAddMorePax, null, {
    default: _withCtx(() => [
      _createVNode(_component_ItemOfVoucher, {
        color: "#a7116f",
        icon: "images/icon-voucher/contact-detail.png",
        class: "item-of-voucher",
        pnr: _ctx.myInfo.pnr,
        text: `${_ctx.myInfo.first_name} ${_ctx.myInfo.last_name}`,
        cursor: "default"
      }, null, 8, ["pnr", "text"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_21), 1)
        ]),
        (_ctx.miniStep === 'searchOption')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_SearchOption, {
                "search-type": _ctx.miniSearchType,
                "onUpdate:search-type": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.miniSearchType) = $event)),
                "handle-scan": _ctx.handleMiniQRScan,
                onUpdateStep: _ctx.updateMiniStep
              }, null, 8, ["search-type", "handle-scan", "onUpdateStep"]),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("button", {
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.backStep && _ctx.backStep(...args)))
                  }, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_7), 1)
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_Search, {
                "search-type": _ctx.miniSearchType,
                "passenger-search-info": _ctx.passengerMiniSearchInfo,
                submit: _ctx.handleMiniSearchPax,
                onUpdateStep: _ctx.updateMiniStep
              }, null, 8, ["search-type", "passenger-search-info", "submit", "onUpdateStep"]),
              _createVNode(_component_SFSModal, {
                "show-modal": _ctx.isShowModalSelectPax,
                title: _ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_28,
                "confirm-text": _ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_30,
                "cancel-text": _ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_31,
                onClose: _ctx.handleCloseSelectPax,
                onOpened: _ctx.handleOpenSelectPax,
                onConfirm: _ctx.handleSelectPax
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElForm, {
                    ref: "addMorePaxForm",
                    model: _ctx.selectedPaxForm,
                    rules: _ctx.addMorePaxRule,
                    "show-message": _ctx.isShowMessage,
                    onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ElFormItem, { prop: "checkboxSelectedPaxIds" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_checkbox_group, {
                            modelValue: _ctx.selectedPaxForm.checkboxSelectedPaxIds,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedPaxForm.checkboxSelectedPaxIds) = $event)),
                            class: "group-pax"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchedPaxs, (p, index) => {
                                return (_openBlock(), _createBlock(_component_el_checkbox, {
                                  key: index,
                                  label: p.id,
                                  class: "group-pax__item"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(p.first_name) + " " + _toDisplayString(p.last_name) + " - " + _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_29) + " " + _toDisplayString(p.pnr), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["model", "rules", "show-message"])
                ]),
                _: 1
              }, 8, ["show-modal", "title", "confirm-text", "cancel-text", "onClose", "onOpened", "onConfirm"])
            ]))
      ])
    ]),
    _: 1
  }))
}