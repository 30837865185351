
import { defineComponent, ref, watch } from 'vue'
import StyledMoDal from '@/styled-components/components/SFSModalV2'

export default defineComponent({
  components: {
    StyledMoDal
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean
    },
    center: {
      type: Boolean
    },
    showClose: {
      type: Boolean,
      default: false
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    showConfirm: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    confirmText: {
      type: String,
      default: 'Confirm'
    }
  },
  emits: ['close', 'confirm', 'opened'],
  setup(props) {
    const visible = ref(true)
    const modalWidth = ref('70%')
    watch(
      () => props.showModal,
      (value) => (visible.value = value),
      { immediate: true }
    )

    return { visible, modalWidth }
  },
  mounted() {
    if (window.innerWidth <= 767) {
      this.modalWidth = '90%'
    }
  },
  methods: {
    onOpen() {
      this.$emit('opened')
      // Scroll to the top when re-openning dialog
      this.$el.querySelector('.el-overlay').scrollTo({ top: 0 })
    }
  }
})
