import _ from 'lodash'

export function randomCode(limit: number) {
  return (Date.now().toString(36) + Math.random().toString(36).substr(2)).slice(-limit)
}

export function round(number: number, digits = 2) {
  if (!number) return 0
  return Number(Number(number).toFixed(digits))
}

export function empty(variable) {
  if (!variable || variable === 'null' || variable === '0') return true

  if (typeof variable === 'object' && !Object.keys(variable).length) return true

  if (Array.isArray(variable) && !variable.length) return true

  return false
}

export function replaceStr(text, pattern, replacement) {
  let patternArr = _.isArray(pattern) ? pattern : [pattern]
  let replacementArr = _.isArray(replacement) ? replacement : [replacement]
  _.forEach(patternArr, function (val, key) {
    text = text.replace(new RegExp(val, 'g'), replacementArr[key])
  })
  return text
}
