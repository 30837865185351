
import { defineComponent, ref, PropType, watch } from 'vue'
import StyledSFSRadio from '@/styled-components/components/SFSRadio'

export default defineComponent({
  components: {
    StyledSFSRadio
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: null
    },
    prop: {
      type: String,
      default: null
    },
    items: {
      type: Array as PropType<{ value: string | number; label: string }[]>,
      default: () => {}
    },
    label: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    radioClass: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const inputValue = ref<number | string>(props.modelValue)

    watch(
      () => props.modelValue,
      (value) => (inputValue.value = value)
    )

    watch(inputValue, (value) => emit('update:modelValue', value))

    return { inputValue }
  }
})
