
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { StoreType } from '@/types/store'
import FetchAllMixinsVue from '../Mixins/FetchAllMixins.vue'
import StyledCombineVoucher from '@/styled-components/pages/combine-voucher-2/index'
import AddMorePax from './AddMorePax.vue'
import { PassengerType } from '@/types/passenger'
import SelectPaxTraveling from './SelectPaxTraveling.vue'
import LangMixinsVue from '../SearchAndSelectPassengers/Mixin/LangMixins.vue'

export default defineComponent({
  components: {
    StyledCombineVoucher,
    AddMorePax,
    SelectPaxTraveling
  },
  mixins: [FetchAllMixinsVue, LangMixinsVue],
  setup() {
    const store = useStore<StoreType>()
    return {
      myInfo: computed(() => store.state.groupPax.me),
      listGroupPaxs: computed(() => store.getters['groupPax/listGroupPax']),
      listGroupPaxsExcludeMe: computed(() => store.state.groupPax.listGroupPaxExcludeMe)
    }
  },
  data() {
    const listSelectedGroupPaxIds: number[] = []
    const listSelectedOtherPaxsOnFlight: PassengerType[] = []

    return {
      step: 'addMorePax',
      searchType: undefined,
      passengerSearchInfo: {
        pnr: '',
        firstName: '',
        lastName: ''
      },
      listSelectedGroupPaxIds,
      listSelectedOtherPaxsOnFlight
    }
  },
  computed: {
    listGroupPaxsAndListSelectedOtherPaxsOnFlight() {
      return [...this.listGroupPaxs, ...this.listSelectedOtherPaxsOnFlight]
    },
    listGroupPaxsExcludeMeAndListSelectedOtherPaxsOnFlight() {
      return [...this.listGroupPaxsExcludeMe, ...this.listSelectedOtherPaxsOnFlight]
    }
  },
  created() {
    this.fetchAll()
  },
  methods: {
    updateStep(newStep) {
      this.step = newStep
    }
  }
})
