import { BaseResponse } from '../types/global'
import { HTTPBaseService } from '@/utils/request'
import { GetListVoucherDetailBody } from '@/types/voucher'

export default class Voucher extends HTTPBaseService {
  constructor() {
    super('station-handler')
  }

  public async getListVoucherDetail(body: GetListVoucherDetailBody): Promise<BaseResponse> {
    let { data } = await this.instance.post('passengers/services', body)
    data.data = this.mapDataForDisplayVouchers(data.data)
    return data
  }

  private mapDataForDisplayVouchers(vouchers) {
    if (vouchers?.length <= 0) return vouchers
    const voucherHasMapData: any = []
    vouchers.forEach((voucher) => {
      //Hotel
      if (voucher.service_id == 1)
        voucherHasMapData.push({
          name: 'Hotel voucher',
          color: '#f0725e',
          icon: 'images/icon-voucher/hotel-voucher.png',
          routerName: 'HotelSharing',
          ...voucher
        })

      //Refreshment
      if (voucher.service_id == 2) {
        const uniqueRefreshmentVoucherCode = [...new Set(voucher.list_refreshment.map((refreshment) => refreshment.voucher_code))]
        uniqueRefreshmentVoucherCode.forEach((voucher_code, index) => {
          let name = `Refreshment voucher (${index + 1})`
          if (uniqueRefreshmentVoucherCode.length === 1) {
            name = 'Refreshment voucher'
          }
          voucherHasMapData.push({
            name: name,
            color: '#54ceae',
            icon: 'images/icon-voucher/refreshment-voucher.png',
            routerName: 'RefreshmentSharing',
            voucher_code,
            ...voucher
          })
        })
      }

      //Taxi
      if (voucher.service_id == 4)
        voucherHasMapData.push({
          name: 'Taxi voucher',
          color: '#56c3e0',
          icon: 'images/icon-voucher/taxi-voucher.png',
          routerName: 'TaxiSharing',
          ...voucher
        })

      //Monetary compensation
      if (voucher.service_id == 21) {
        if (voucher?.detail_monetary?.orders?.length == 0) return

        const uniqueMonetaryVoucherCode = [
          ...new Set(voucher.detail_monetary.monetaryVoucher.map((monetary) => monetary.voucher_code))
        ]
        uniqueMonetaryVoucherCode.forEach((voucher_code, index) => {
          let name = `Monetary voucher (${index + 1})`
          if (uniqueMonetaryVoucherCode.length === 1) {
            name = 'Monetary voucher'
          }
          voucherHasMapData.push({
            name: name,
            color: '#bbe35d',
            icon: 'images/icon-voucher/monetary-compensation.png',
            routerName: 'MonetaryCompensationSharing',
            voucher_code,
            ...voucher
          })
        })
      }
      //Meal plan
      if (voucher.service_id == 23)
        voucherHasMapData.push({
          name: 'Meal Plan voucher',
          color: '#f5b945',
          icon: 'images/icon-voucher/meal-plan-voucher.png',
          routerName: 'MealPlanSharing',
          ...voucher
        })
    })
    return voucherHasMapData
  }
}
