import { API_SERVICE } from '@/constants/apiService'
import Passenger from '@/models/Passenger'
import { RequiredParamsType } from '@/types/common'
import { mutationTree, actionTree } from 'typed-vuex'

type RootState = ReturnType<typeof state>

interface TaxiStateType {
  taxi: any
  isInventoryList: number
  detailVouchers: any
}

export const initTaxiStore = {
  taxi: {},
  isInventoryList: 0,
  detailVouchers: undefined
} as TaxiStateType

export const state = () => initTaxiStore

export const mutations = mutationTree(state, {
  setTaxi(state: RootState, taxi: {}) {
    state.taxi = taxi
  },
  setDetailVouchers(state: RootState, detailVouchers: any) {
    state.detailVouchers = detailVouchers
  }
})

export const actions = actionTree(
  { state, mutations },
  {
    async getTaxi({ commit, state }, params: RequiredParamsType) {
      const response = await new Passenger(API_SERVICE.SFS).fetchHotel(params)
      if (response.success) {
        commit('setTaxi', response.data)
        return state.taxi
      }
    },
    setDetailVouchers({ commit }, vouchers: any) {
      commit('setDetailVouchers', vouchers)
    }
  }
)
