import styled from 'vue3-styled-components'

const StyledSFSTravelersAndServiceInGroup = styled.section`
  .ws-textbox {
    letter-spacing: 0.004375rem;
    padding: 16px !important;
    box-shadow: 0 0.1875rem 0.375rem #85858529;
    outline: none;
    line-height: 1.777777778;

    .title {
      margin-bottom: 10px;
      font-size: 17px;
    }
    .traveler-item {
      color: #666464;
    }
  }
`

export default StyledSFSTravelersAndServiceInGroup
