import { BaseResponse } from './../types/global'
import { HTTPBaseService } from '@/utils/request'

export default class Taxi extends HTTPBaseService {
  constructor() {
    super('station-handler')
  }

  // public async issueTaxiTransfer(body: IssueTaxiTranferBody): Promise<BaseResponse> {
  //   const { data } = await this.instance.post('taxi/issue-taxi-transfer', body)
  //   return data
  // }

  public async getDetailTaxiVoucher(passengerId: number): Promise<BaseResponse> {
    const { data } = await this.instance.get(`taxi/get-detail-voucher/${passengerId}`)
    return data
  }
}
