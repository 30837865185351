
import { defineComponent } from 'vue'
import LangMixinsVue from '@/views/SearchAndSelectPassengers/Mixin/LangMixins.vue'
import { waitForElm } from '@/utils/dom'
import { ElNotification } from 'element-plus'

export default defineComponent({
  mixins: [LangMixinsVue],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '600px'
    }
  },
  emits: ['update:visible', 'scanned'],
  data() {
    return {
      dialogVisible: this.visible,
      laserScanningData: '',
      interval: 0
    }
  },
  watch: {
    visible: function () {
      this.dialogVisible = this.visible
    },
    dialogVisible: function () {
      this.$emit('update:visible', this.dialogVisible)
      if (this.dialogVisible) {
        waitForElm('#input-laser-scan').then(() => {
          ;(document.querySelector('#input-laser-scan input') as HTMLElement)?.focus()
        })
      }
    }
  },
  methods: {
    handleOutFocus() {
      this.laserScanningData = (window as any).cuteLaserScanningData ?? this.laserScanningData
    },
    clearCuteLaserScanningData() {
      ;(window as any).cuteLaserScanningData = undefined
    },
    checkCuteLaserScanningData() {
      this.laserScanningData = (window as any).cuteLaserScanningData ?? this.laserScanningData
      this.clearCuteLaserScanningData()
    },
    handleDialogOpened() {
      this.clearCuteLaserScanningData()
      this.interval = setInterval(() => this.checkCuteLaserScanningData(), 500)
    },
    handleConfirm() {
      this.laserScanningData = this.laserScanningData?.trim()
      if (this.laserScanningData && this.laserScanningData.length > 0 && this.laserScanningData.slice(0, 2) === 'M1') {
        this.$emit('scanned', this.laserScanningData)
      } else if (this.laserScanningData && this.laserScanningData.length > 0) {
        ElNotification({
          title: 'Error',
          message: 'Cannot find passenger',
          type: 'error',
          duration: 3000
        })
      }
      this.dialogVisible = false
      this.handleDialogClosed()
    },
    handleDialogClosed() {
      clearInterval(this.interval)
      this.laserScanningData = ''
      this.clearCuteLaserScanningData()
    }
  }
})
