import styled from 'vue3-styled-components'

const StyledAreYouTravelAlone = styled.section`
  .ws-textbox {
    .confirm-label {
      font-size: 1.25rem;
      color: #a4aabd;
      display: flex;
      justify-content: center;
    }
    margin: 30px 0px 30px;
  }

  .item-of-voucher,
  flight-info {
    margin: 30px 0px 30px;
  }

  .btn__container {
    display: flex;
    justify-content: center;
    gap: 10%;
    .btn {
      margin-top: 10px;
    }
  }

  .btn-back-traveling,
  .btn-confirm-traveling {
    button {
      border-radius: 17px;
      padding: 10px 50px;
      font-weight: 500;
      font-size: 16px;
    }
    button:hover {
      background-color: #0094d5;
      border: 0px;
      span {
        color: #f5d32a;
        font-weight: 500;
      }
    }
  }
`

export default StyledAreYouTravelAlone
