import Passenger from '@/models/Passenger'
import { mutationTree, actionTree } from 'typed-vuex'

type RootState = ReturnType<typeof state>

interface StateType {
  paxsHotel: any
}

export const state = () =>
  ({
    paxsHotel: {}
  } as StateType)

export const mutations = mutationTree(state, {
  setPaxsHotel(state: RootState, paxsHotel: any) {
    state.paxsHotel = paxsHotel
  }
})

export const actions = actionTree(
  { state, mutations },
  {
    async fetchVoucherHotel({ commit }, body: { passenger_id: number; reservation_id: number; voucher_id: number }) {
      let response = await new Passenger('sfs-api').fetchVoucherHotel(body)
      if (response.success) {
        commit('setPaxsHotel', response.data)
      }
    }
  }
)
