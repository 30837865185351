
import { defineComponent } from 'vue'
import store from '@/store'
import { getUser } from '@/utils/auth'
import { empty } from '@/utils/variables'

export default defineComponent({
  computed: {
    groundHandlerMessage() {
      return store.getters['lang/lang'].ground_handler_message
    },
    flightStatusLang() {
      return store.getters['lang/lang'].mobile?.status_flight
    }
  },
  mounted() {
    if (empty(store.getters['lang/lang'].ground_handler_message)) {
      this.fetchGroundHandlerMessage()
    }
    store.dispatch('lang/setTemplateDirection')
  },
  methods: {
    fetchGroundHandlerMessage() {
      const user = getUser()
      const selectedLang = store.getters['lang/selectedLang']
      store.dispatch('lang/fetchLang', { airline_id: user.airline.id, lang_code: selectedLang, type: 'ground_handler_message' })
      store.dispatch('lang/fetchLang', { airline_id: user.airline.id, lang_code: selectedLang, type: 'mobile' })
    },
    getLinkImgVoucher(lang, type, isShowPaxLang) {
      if (isShowPaxLang) {
        return lang.paxLang[type]
      }
      return lang.enLang[type]
    }
  }
})
