
import { PassengerType } from '@/types/passenger'
import { ElNotification } from 'element-plus'
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    checkListGroupPaxsHasIssuedService(listGroupPaxs: PassengerType[]) {
      return listGroupPaxs.some((pax) => pax.issued_service)
    },
    listGroupPaxExcludeMe(listGroupPax: Array<any>, myId) {
      return listGroupPax.filter((pax) => pax.id != myId)
    },
    throwMustSelectAllMemberInGroupIssuedWarning() {
      ElNotification.closeAll()
      ElNotification({
        title: 'Error',
        message: 'Modify group is not possible for customers for which services have been issued already',
        type: 'error',
        duration: 3000
      })
    },
    throwMakeSureSelectSameServicePassenger() {
      ElNotification.closeAll()
      ElNotification({
        title: 'Error',
        message:
          'Make sure you have only selected passengers with the same services assigned. Note: maybe your selection still includes passengers that were selected before.',
        type: 'error',
        duration: 3000
      })
    },
    throwNoPaxFoundError() {
      ElNotification.closeAll()
      ElNotification({
        title: 'Error',
        message: 'Cannot find passenger',
        type: 'error',
        duration: 3000
      })
    },
    throwAddPaxSuccess() {
      ElNotification.closeAll()
      ElNotification({
        title: 'Success',
        message: 'Add passenger successfully',
        type: 'success',
        duration: 3000
      })
    }
  }
})
