
import _ from 'lodash'
import store from '@/store'
import Passenger from '@/models/Passenger'
import StyledSFSListFlightInfo from '@/styled-components/components/SFSListFlightInfo'
import LangMixinsVue from '@/views/SearchAndSelectPassengers/Mixin/LangMixins.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    StyledSFSListFlightInfo
  },
  mixins: [LangMixinsVue],
  data() {
    return {
      displayFlight: {
        fltref: '',
        irreg_reason: '',
        flight_status: '',
        status: '',
        std: '',
        etd: ''
      }
    }
  },
  computed: {
    flightLegs(): any {
      return store.state.flightLegs
    },
    paxThisIsMe(): any {
      return store.state['groupPax'].me
    },
    getCurrentPassengerId(): any {
      return parseInt((this as any).$route.query?.passenger_id ?? store.state['groupPax']?.me?.passenger_id)
    },
    showBtnNext(): any {
      const indexCurrentFlightDisplay: any = this.flightLegs?.findIndex((flight) => flight?.fltref === this.displayFlight.fltref)
      return indexCurrentFlightDisplay < this.flightLegs?.length - 1
    },
    showBtnPrev(): any {
      const indexCurrentFlightDisplay: any = this.flightLegs?.findIndex((flight) => flight?.fltref === this.displayFlight.fltref)
      return indexCurrentFlightDisplay > 0
    },
    flightStatusDisplay(): any {
      if (!this.displayFlight.fltref) return ''
      let statusFlight = this.displayFlight?.irreg_reason || this.displayFlight?.flight_status || this.displayFlight?.status
      if (_.isUndefined(this.flightStatusLang)) {
        return statusFlight
      }
      if (this.paxThisIsMe.fltref !== this.paxThisIsMe.first_fltref) {
        statusFlight = this.flightStatusLang['NEW_FLIGHT']
        return statusFlight
      }
      if (!this.displayFlight.irreg_reason) {
        statusFlight = this.flightStatusLang['NO_IRREG']
      } else {
        statusFlight = this.flightStatusLang[this.displayFlight.irreg_reason] || ''
      }
      return statusFlight
    }
  },
  watch: {
    getCurrentPassengerId() {
      this.fetchFlightLegs()
    },
    flightLegs(newValue: any) {
      this.displayFlight = newValue.find((flight) => flight.isCurrentFlight)
    }
  },
  mounted() {
    this.fetchFlightLegs()
  },
  methods: {
    async fetchFlightLegs(): Promise<any> {
      if (!this.getCurrentPassengerId) return
      const passenger = new Passenger()
      const { data: flightLegs } = await passenger.getListFlightInfo(this.getCurrentPassengerId)
      store.dispatch('setFlightLegs', flightLegs)
      this.displayFlight = flightLegs.find((flight) => flight.isCurrentFlight)
    },
    onPressBtnLeg(type, fltref: any): any {
      if (this.flightLegs && this.flightLegs.length === 0) return
      if (type === 'next') {
        const indexCurrentFlightDisplay: any = this.flightLegs.findIndex((flight) => flight?.fltref === fltref)
        if (
          indexCurrentFlightDisplay === this.flightLegs.length - 1 ||
          indexCurrentFlightDisplay === -1 ||
          indexCurrentFlightDisplay === undefined
        ) {
          return
        }
        this.displayFlight = this.flightLegs[indexCurrentFlightDisplay + 1]
      }
      if (type === 'prev') {
        const indexCurrentFlightDisplay: any = this.flightLegs.findIndex((flight) => flight?.fltref === fltref)
        if (indexCurrentFlightDisplay <= 0 || indexCurrentFlightDisplay === undefined) {
          return
        }
        this.displayFlight = this.flightLegs[indexCurrentFlightDisplay - 1]
      }
    }
  }
})
