import styled from 'vue3-styled-components'

const StyledSearch = styled.section`
  .container {
    margin: 0 auto;
    border-radius: 12px;
    filter: drop-shadow(0px 3px 3.5px rgba(133, 133, 133, 0.16));
    padding: 20px;
  }
  .el-input__inner {
    width: 98% !important;
    height: 51px;
    margin-top: 9px;
    border: 1px solid #d1d1d1;
    box-shadow: none !important;
    background: #fafafa;
    text-transform: uppercase;
  }

  .form-group {
    margin: 0px 15px 0px;

    label {
      font-size: 16px;
      letter-spacing: 0px;
      line-height: 32px;
      color: #646464;
      font-weight: 400;
      font-family: 'Roboto';
    }
    .label-explain {
      font-size: 13px;
    }
    .w-full {
      width: 100%;
      display: inline-block;
    }
    .pnr-input {
      width: 100%;
    }
  }
  .button-wrap {
    width: 100%;
    bottom: 0;
    padding: 15px;
    box-sizing: border-box;
    .btn-search-for-pax {
      text-align: center;
      margin-bottom: 75px;
      button {
        cursor: pointer;
        width: 188px;
        height: 110px;
        border: 1px;
        border-radius: 12px;
        filter: drop-shadow(0px 3px 3.5px rgba(133, 133, 133, 0.16));
        background-color: #ffffff;
        font-size: 27px;
        color: #e9da17;
        font-family: 'Segoe UI';
        text-align: center;
        padding-left: 40px;
        padding-right: 40px;
      }
      button:hover {
        background-color: #0094d5;
        border: 0px;
        span {
          color: #f5d32a;
          font-weight: 500;
        }
      }
    }
  }
`

export default StyledSearch
