
import { defineComponent } from 'vue'

// import styled component
import StyledResetPassword from '@/styled-components/pages/reset-password/index'

export default defineComponent({
  name: 'ResetPassword',
  components: {
    StyledResetPassword
  },
  setup() {}
})
