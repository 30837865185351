import styled from 'vue3-styled-components'
const SFSFlightInfo = styled.section`
  .ws-textbox {
    padding: 1rem;
    letter-spacing: 0.004375rem;
    background-color: #ffffff;
    border-radius: 0.75rem;
    border: none;
    -webkit-box-shadow: 0 0.1875rem 0.375rem #85858529;
    box-shadow: 0 0.1875rem 0.375rem #85858529;
    outline: none;
    line-height: 1.777777778;
    text-align: center;
  }
  .block-item-flight {
    .flightinfo {
      color: #a4aabd;
      background-color: white;
      padding: 0;
      max-height: 166px;
    }
    .frame-flight-img {
      margin-top: 1.2rem;

      img {
        transform: var(--transform-flight-icon);
      }
    }
    .airport-name {
      font-size: 32pt;
    }
    .flight-status {
      margin: 12pt 0 !important;
      letter-spacing: -0.3pt;
      .flight-status-text {
        color: #4acef1;
        font-size: 17px;
        margin-top: -19px;
      }
      .text-dark {
        margin-top: -3px;
      }
    }
  }
  .block-item {
    background-color: transparent !important;
  }
  .flight-status span {
    color: #07bcec;
  }
  .text-dark {
    color: #000;
  }
  .mg-0 {
    margin: 0;
  }
  .my-3 {
    margin-bottom: 1rem !important;
  }
`

export default SFSFlightInfo
