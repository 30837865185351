import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SFSInput = _resolveComponent("SFSInput")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialogVisible) = $event)),
    "close-on-click-modal": false,
    onOpened: _ctx.handleDialogOpened,
    onClosed: _ctx.handleDialogClosed
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_el_button, { onClick: _ctx.handleConfirm }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_19), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_SFSInput, {
        id: "input-laser-scan",
        modelValue: _ctx.laserScanningData,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.laserScanningData) = $event)),
        blur: _ctx.handleOutFocus
      }, null, 8, ["modelValue", "blur"])
    ]),
    _: 1
  }, 8, ["modelValue", "onOpened", "onClosed"]))
}