
import { defineComponent } from 'vue'

// import styled component
import StyledSearch from '@/styled-components/pages/combine-voucher-2/search'
import { SEARCH_FORM_RULES } from '@/constants/combineVoucher2'
import LangMixinsVue from '../SearchAndSelectPassengers/Mixin/LangMixins.vue'
import store from '@/store'

export default defineComponent({
  name: 'SearchPax',
  components: {
    StyledSearch
  },
  mixins: [LangMixinsVue],
  props: {
    searchType: {
      type: String,
      default: ''
    },
    passengerSearchInfo: {
      type: Object,
      default: () => {}
    },
    submit: {
      type: Function,
      default: () => {}
    },
    flightId: {
      type: Number,
      default: undefined
    }
  },
  emits: ['updateStep', 'update:flightId'],
  data() {
    return {
      rules: SEARCH_FORM_RULES,
      passengerInfo: this.passengerSearchInfo,
      searchFormRules: SEARCH_FORM_RULES
    }
  },
  computed: {
    maxPnrLength() {
      if (store.state.airline?.params?.enable_limit_character_pnr == 1) {
        return 6
      }

      return null // unlimited
    }
  },
  methods: {
    uppercase() {
      this.passengerInfo.pnr = this.passengerInfo.pnr.toUpperCase()
      this.passengerInfo.firstName = this.passengerInfo.firstName.toUpperCase()
      this.passengerInfo.lastName = this.passengerInfo.lastName.toUpperCase()
    },
    backStep() {
      this.passengerInfo.pnr = ''
      this.passengerInfo.firstName = ''
      this.passengerInfo.lastName = ''
      this.$emit('updateStep', 'searchOption')
      // Reset flightId
      this.$emit('update:flightId', undefined)
    },
    handleSubmit() {
      const form = this.$refs.form as any
      form.validate((valid) => {
        if (!valid) return
        this.submit()
      })
    }
  }
})
