import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ws-textbox" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "btn-reserve btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_ItemOfVoucher = _resolveComponent("ItemOfVoucher")!
  const _component_SFSRadio = _resolveComponent("SFSRadio")!
  const _component_AddressInfo = _resolveComponent("AddressInfo")!
  const _component_SFSMatrixTaxi = _resolveComponent("SFSMatrixTaxi")!
  const _component_StyledTaxiService = _resolveComponent("StyledTaxiService")!

  return (_openBlock(), _createBlock(_component_StyledTaxiService, null, {
    default: _withCtx(() => [
      _createVNode(_component_Title, {
        "title-name": _ctx.groundHandlerMessage?.taxi.SFS_LANG_ROW_4,
        "title-link": "Home"
      }, null, 8, ["title-name"]),
      _createElementVNode("div", null, [
        _createVNode(_component_ItemOfVoucher, {
          class: "item-voucher-taxi",
          icon: "images/icon-voucher/taxi-voucher.png",
          color: "#56c3e0",
          label: _ctx.groundHandlerMessage?.taxi.SFS_LANG_ROW_2
        }, null, 8, ["label"]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.groundHandlerMessage?.taxi.SFS_LANG_ROW_5), 1),
            _createElementVNode("div", null, [
              _createVNode(_component_SFSRadio, {
                modelValue: _ctx.optionTaxi,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.optionTaxi) = $event)),
                disabled: _ctx.isSetOptionTaxi,
                items: [
                { value: 1, label: _ctx.groundHandlerMessage?.taxi.SFS_LANG_ROW_6 },
                { value: 2, label: _ctx.groundHandlerMessage?.taxi.SFS_LANG_ROW_7 },
                { value: 3, label: _ctx.groundHandlerMessage?.taxi.SFS_LANG_ROW_8 },
                { value: 4, label: _ctx.groundHandlerMessage?.taxi.SFS_LANG_ROW_9 }
              ]
              }, null, 8, ["modelValue", "disabled", "items"]),
              (_ctx.isRenderPleaseIssueHotelFirst)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.taxiErrorMessage), 1))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_SFSRadio, {
              modelValue: _ctx.wayOption,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.wayOption) = $event)),
              class: "way-option",
              disabled: _ctx.isDisableChooseWayOption,
              items: [
              { value: 2, label: _ctx.groundHandlerMessage?.taxi.SFS_LANG_ROW_10 },
              { value: 1, label: _ctx.groundHandlerMessage?.taxi.SFS_LANG_ROW_11 }
            ],
              onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getPriceByDistance(_ctx.distanceTaxi, _ctx.distance_hotel_to_airport)))
            }, null, 8, ["modelValue", "disabled", "items"]),
            _createVNode(_component_AddressInfo, {
              origin: _ctx.origin,
              destination: _ctx.destination,
              "init-show-map": false,
              onUpdateLocation: _ctx.updateLocation,
              onGetPriceByDistance: _ctx.getPriceByDistance,
              onClearDestinationAddress: _ctx.clearDestinationAddress
            }, null, 8, ["origin", "destination", "onUpdateLocation", "onGetPriceByDistance", "onClearDestinationAddress"])
          ]),
          _withDirectives(_createVNode(_component_SFSMatrixTaxi, {
            "share-matrix": _ctx.shareMatrixPaxId,
            "onUpdate:share-matrix": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.shareMatrixPaxId) = $event)),
            "list-paxs": _ctx.listGroupPax,
            "pax-per-taxi": _ctx.paxPerTaxi,
            "is-set-by-airline": _ctx.isSetByAirline
          }, null, 8, ["share-matrix", "list-paxs", "pax-per-taxi", "is-set-by-airline"]), [
            [_vShow, _ctx.isNeedToShareTaxi]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleReserveTaxi()))
        }, _toDisplayString(_ctx.groundHandlerMessage?.taxi.SFS_LANG_ROW_19), 1)
      ])
    ]),
    _: 1
  }))
}