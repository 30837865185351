import styled from 'vue3-styled-components'

const StyledSearchOption = styled.section`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .button-scan-parent {
    padding: 0px;
    border-top: 1px solid #f7f3f3 !important;
  }
  .search-by-pnr,
  .search-by-detail {
    flex: 1 0 auto;
    text-align: center;
    margin-bottom: 75px;
    margin: 0% 5% 5% 5%;
    button {
      width: 100%;
      height: 110px;
      border: 1px;
      border-radius: 12px;
      filter: drop-shadow(0px 3px 3.5px rgba(133, 133, 133, 0.16));
      background-color: #ffffff;
      font-size: 27px;
      color: #e9da17;
      font-family: 'Segoe UI';
      text-align: center;
    }
    button:hover {
      background-color: #0094d5;
      border: 0px;
      span {
        color: #f5d32a;
        font-weight: 500;
      }
    }
  }

  .search-by-scan {
    margin-bottom: 50%;
    @media (min-width: 850px) {
      margin-bottom: 16%;
    }
    width: 100%;
    button {
      border: 1px;
      border-radius: 12px;
      background-color: #ffffff;
      filter: drop-shadow(0px 3px 3.5px rgba(133, 133, 133, 0.16));
      font-size: 20px;
      text-align: center;
      width: 100%;
      .button-scan {
        width: 100%;
        .separator {
          width: 2px;
          height: 80px;
          background-color: #666;
          border-radius: 3px;
        }

        .laser-scan,
        .camera-scan {
          width: 100%;
          &:hover {
            background-color: #0094d5;
            border: 0px;
            border-radius: 12px;
            img {
              mix-blend-mode: color-burn;
            }
            span {
              color: #f5d32a;
              font-weight: 500;
            }
          }
        }

        display: flex;
        height: auto;
        align-items: center;
        justify-content: space-around;
        span {
          font-size: 22px;
          color: #707070;
          font-family: 'Segoe UI';
        }
        img {
          width: 87px;
        }
      }
    }
  }
`

export default StyledSearchOption
