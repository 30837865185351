import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddMorePax = _resolveComponent("AddMorePax")!
  const _component_SelectPaxTraveling = _resolveComponent("SelectPaxTraveling")!
  const _component_SFSTravelersAndServiceInGroup = _resolveComponent("SFSTravelersAndServiceInGroup")!
  const _component_StyledCombineVoucher = _resolveComponent("StyledCombineVoucher")!

  return (_openBlock(), _createBlock(_component_StyledCombineVoucher, null, {
    default: _withCtx(() => [
      (_ctx.step == 'addMorePax')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_AddMorePax, {
              "list-selected-other-paxs-on-flight": _ctx.listSelectedOtherPaxsOnFlight,
              "onUpdate:list-selected-other-paxs-on-flight": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.listSelectedOtherPaxsOnFlight) = $event)),
              "list-group-paxs": _ctx.listGroupPaxs,
              "my-id": _ctx.myInfo.id,
              onUpdateStep: _ctx.updateStep
            }, null, 8, ["list-selected-other-paxs-on-flight", "list-group-paxs", "my-id", "onUpdateStep"])
          ]))
        : (_ctx.step == 'selectPaxTraveling')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_SelectPaxTraveling, {
                "list-selected-group-pax-ids": _ctx.listGroupPaxsAndListSelectedOtherPaxsOnFlight.map((pax) => pax.id),
                "list-group-paxs": _ctx.listGroupPaxsAndListSelectedOtherPaxsOnFlight,
                "my-id": _ctx.myInfo.id,
                onUpdateStep: _ctx.updateStep
              }, null, 8, ["list-selected-group-pax-ids", "list-group-paxs", "my-id", "onUpdateStep"])
            ]))
          : _createCommentVNode("", true),
      (_ctx.step != 'selectPaxTraveling')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.listGroupPaxsExcludeMeAndListSelectedOtherPaxsOnFlight.length > 0)
              ? (_openBlock(), _createBlock(_component_SFSTravelersAndServiceInGroup, {
                  key: 0,
                  label: _ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_22,
                  "list-pax": _ctx.listGroupPaxsExcludeMeAndListSelectedOtherPaxsOnFlight
                }, null, 8, ["label", "list-pax"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}