import styled from 'vue3-styled-components'

const SFSHotelBook = styled.section`
  .hotel_book {
    .bg-main {
      background-color: rgb(246, 247, 248);
    }
    .el-icon-arrow-up {
      -webkit-text-stroke: 2px #686868;
    }

    .pdt-15 {
      padding-top: 15pt;
    }

    .pre-header {
      height: 40pt;
      line-height: 40pt;
    }

    .hotel-item .content {
      padding: 10pt 20pt 30pt 10pt;
      margin-bottom: 30pt;
    }

    .block-title {
      padding-left: 10pt;
      padding-top: 5pt;
      padding-bottom: 5pt;
    }

    .block-title .sb_mobile {
      display: inline-block;
    }

    .hotel-name {
      font-family: 'Avenir-Medium', Helvetica, Arial, Verdana, Tahoma, sans-serif;
      font-size: 16pt;
      letter-spacing: -0.3pt;
      margin: 0;
      text-transform: capitalize;
      height: 45pt;
      line-height: 45pt;
    }

    .hotel-distance {
      padding-bottom: 10pt;
      font-size: 14pt;
      line-height: 26pt;
      letter-spacing: -0.3pt;
      color: #000;
      font-family: 'Avenir-Roman', Helvetica, Arial, Verdana, Tahoma, sans-serif;
    }

    .hotel-description {
      padding-bottom: 15pt;
    }

    .btn-detail {
      border: 1px solid rgb(5, 23, 77);
      color: rgb(5, 23, 77);
      background-color: #fff;
      font-weight: 400;
      padding-top: 8pt;
      padding-bottom: 8pt;
    }

    .btn-select {
      background-color: rgb(5, 23, 77);
      color: #fff;
      font-weight: 400;
      padding-top: 8pt;
      padding-bottom: 8pt;
    }

    .group-button {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn-action {
        width: 162px;
        height: 44px;
        border-radius: 17px;
        filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
        background-color: #ffffff;
        border: 0px;
        display: block;
        margin-top: 30px;
        span {
          font-size: 16px;
          letter-spacing: 0px;
          color: #f5d32a;
          font-weight: 500;
        }
      }

      .btn-action:hover {
        background-color: #0094d5;
        border: 0px;
        display: block;
        span {
          color: #f5d32a;
          font-weight: 500;
        }
      }
    }

    .group-action .btn {
      width: 48%;
    }
    .carourel_hotel img {
      max-width: 100%;
      width: 100%;
      max-height: 100%;
      height: 100%;
    }

    .box-card {
      border-radius: 0.75rem;
    }
  }
`

export default SFSHotelBook
