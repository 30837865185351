import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "search-warp" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { for: "prn-label" }
const _hoisted_7 = { class: "label-explain" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "button-wrap" }
const _hoisted_12 = { class: "btn-search-for-pax" }
const _hoisted_13 = { class: "btn btn-back" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SFSInput = _resolveComponent("SFSInput")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_StyledSearch = _resolveComponent("StyledSearch")!

  return (_openBlock(), _createBlock(_component_StyledSearch, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ElForm, {
            ref: "form",
            model: _ctx.passengerInfo,
            "show-message": false,
            rules: _ctx.rules,
            onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
          }, {
            default: _withCtx(() => [
              (_ctx.searchType == 'PNR')
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("label", _hoisted_6, [
                          _createTextVNode(_toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_4) + " ", 1),
                          _createElementVNode("span", _hoisted_7, "(" + _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_5) + ")", 1)
                        ])
                      ]),
                      _createVNode(_component_SFSInput, {
                        modelValue: _ctx.passengerInfo.pnr,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.passengerInfo.pnr) = $event)),
                        prop: "pnr",
                        type: "text",
                        class: "pnr-input",
                        rules: _ctx.searchFormRules.pnr,
                        maxlength: _ctx.maxPnrLength,
                        onInput: _ctx.uppercase
                      }, null, 8, ["modelValue", "rules", "maxlength", "onInput"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.searchType == 'NAME')
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_SFSInput, {
                        modelValue: _ctx.passengerInfo.firstName,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.passengerInfo.firstName) = $event)),
                        label: _ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_8,
                        prop: "firstName",
                        type: "text",
                        class: "pnr-input",
                        rules: _ctx.searchFormRules.firstName,
                        onInput: _ctx.uppercase
                      }, null, 8, ["modelValue", "label", "rules", "onInput"])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_SFSInput, {
                        modelValue: _ctx.passengerInfo.lastName,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.passengerInfo.lastName) = $event)),
                        label: _ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_9,
                        prop: "lastName",
                        type: "text",
                        class: "pnr-input",
                        rules: _ctx.searchFormRules.lastName,
                        onInput: _ctx.uppercase
                      }, null, 8, ["modelValue", "label", "rules", "onInput"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("button", {
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)))
                  }, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_6), 1)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("button", {
                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.backStep && _ctx.backStep(...args)))
                  }, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_7), 1)
                ])
              ])
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ])
      ])
    ]),
    _: 1
  }))
}