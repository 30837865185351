
import { defineComponent, PropType } from 'vue'
import { useRouter } from 'vue-router'
import StyledAreYouTravelAlone from '@/styled-components/pages/search-and-select-passengers/areYouTravelAlone'
import ItemOfVoucher from '@/components/ItemOfVoucher/Index.vue'
import Passenger from '@/models/Passenger'
import SearchPaxMixinsVue from './Mixin/SearchPaxMixins.vue'
import LangMixinsVue from './Mixin/LangMixins.vue'
import { PassengerType } from '@/types/passenger'

export default defineComponent({
  name: 'ConfirmTravelingAlone',
  components: {
    StyledAreYouTravelAlone,
    ItemOfVoucher
  },
  mixins: [SearchPaxMixinsVue, LangMixinsVue],
  props: {
    listGroupPaxs: {
      type: Array as PropType<PassengerType[]>,
      default: () => []
    },
    myId: {
      type: Number,
      default: undefined
    },
    listSelectedPaxIdsOnFlightIncludeMyGroupAndOtherGroups: {
      type: Array,
      default: () => []
    }
  },
  emits: ['updateStep', 'update:listSelectedPaxIdsOnFlightIncludeMyGroupAndOtherGroups'],
  data() {
    return {
      router: useRouter(),
      myInfo: this.listGroupPaxs.find((pax: any) => pax.id == this.myId)
    }
  },
  methods: {
    async confirmedTravelingAlone(isTravelingAlone: boolean) {
      // handle case group > 1 member has issued service but choose travel alone
      if (this.checkListGroupPaxsHasIssuedService(this.listGroupPaxs)) {
        if (isTravelingAlone && this.listGroupPaxs.length !== 1) {
          this.throwMustSelectAllMemberInGroupIssuedWarning()
          return
        }
      }

      // handle case group has not issued service and pax choose travel alone
      if (isTravelingAlone) {
        // if only 1 pax in group redirect to home
        if (this.listGroupPaxs.length === 1) {
          this.router.push({ name: 'Home' })
          return
        }
        if (!this.checkListGroupPaxsHasIssuedService(this.listGroupPaxs)) {
          // if has a lot of pax in group call api separate
          if (this.myId) {
            const passenger = new Passenger()
            const result = await passenger.addNewPaxsToGroup({
              passenger_id: this.myId,
              passengers: []
            })
            if (result.success) {
              this.router.push({ name: 'Home' })
            }
          }

          return
        }
      }

      if (this.listGroupPaxs.length <= 9) {
        // Check if list group <= 9
        if (!isTravelingAlone) {
          // Select All pax case my list group pax <= 9
          this.$emit(
            'update:listSelectedPaxIdsOnFlightIncludeMyGroupAndOtherGroups',
            this.listGroupPaxs.map((p) => p.id)
          )
          this.$emit('updateStep', 'selectPaxTraveling')
        }
      } else {
        // pax length > 9
        this.$emit('updateStep', 'addMorePax')
        return
      }
    }
  }
})
