import NoHotelsAvailable from '@/views/NoHotelsAvailable/Index.vue'
import authentication from '@/middleware/authentication'
import DefaultLayout from '@/layouts/Default.vue'

export const noHotelsAvailable = {
  path: '/',
  component: DefaultLayout,
  meta: {
    middleware: [authentication]
  },
  children: [
    {
      path: '/no-hotels-available',
      name: 'NoHotelsAvailable',
      component: NoHotelsAvailable
    }
  ]
}
