
import { defineComponent } from 'vue'
import moment from 'moment'
import LangMixinsVue from '../../SearchAndSelectPassengers/Mixin/LangMixins.vue'
import { empty } from '@/utils/variables'
import { convertNumberToWords, capitalizeFirstLetter } from '@/utils/string'
import { formatDate } from '@/utils/date'
import { linkify } from '@/utils/string'

export default defineComponent({
  name: 'SFSNavbar',
  mixins: [LangMixinsVue],
  props: {
    hotelData: {
      type: Object,
      default: () => {}
    },
    hotelLanguage: {
      type: Object,
      default: () => {}
    },
    paxInfo: {
      type: Object,
      default: () => {}
    },
    showSecondLanguage: {
      type: Boolean,
      default: false
    },
    langPaxDirection: {
      type: String,
      default: 'ltr'
    }
  },
  data() {
    return {
      dayMap: {
        0: 'Mo',
        1: 'Tu',
        2: 'We',
        3: 'Th',
        4: 'Fr',
        5: 'Sa',
        6: 'Su'
      },
      processedMealPlan: {},
      isOldMealPlan: false
    }
  },
  computed: {
    flightNumber() {
      const fltref = this.paxInfo?.fltref
      return fltref.split('-')[0]
    },
    flightDate() {
      const fltref = this.paxInfo?.fltref
      const year = fltref.split('-')[1]
      const month = fltref.split('-')[2]
      const day = fltref.split('-')[3]
      let date = day + '-' + month + '-' + year
      return moment(date, 'DD-MM-Y').format('DD-MMM-Y')
    }
  },
  watch: {
    hotelData() {
      this.initMealPlan()
    }
  },
  methods: {
    languageReplace(fullLang, textReplaced) {
      let re = new RegExp(Object.keys(textReplaced).join('|'), 'gi')
      return fullLang.replace(re, function (matched) {
        return textReplaced[matched]
      })
    },
    initMealPlan() {
      for (const [passenger_id, meal] of Object.entries(this.hotelData)) {
        const mealPlanTexts = this.getMealPlanTexts(meal.hotelMealPlans, passenger_id)
        this.processedMealPlan[passenger_id] = mealPlanTexts
      }
    },
    getTextOldMealPlan(mealPlans) {
      const mealPlanDesc: string[] = []
      if (mealPlans.length <= 1 && mealPlans[0]?.mealplan_desc) {
        mealPlanDesc.push(mealPlans[0]?.mealplan_desc)
        return mealPlanDesc
      }

      mealPlans?.forEach((mealPlan) => {
        if (mealPlan.date && mealPlan.mealplan_desc) {
          mealPlanDesc.push(`${formatDate(mealPlan?.date, 'DD-MMM-YYYY')}: ${mealPlan?.mealplan_desc}`)
        }
      })
      return mealPlanDesc
    },
    getMealPlanTexts(hotelMealPlans, paxId) {
      this.isOldMealPlan = this.checkOldMealPlan(hotelMealPlans[0])

      if (this.isOldMealPlan) {
        return this.getTextOldMealPlan(hotelMealPlans)
      }
      return this.getTextNewMealPlan(hotelMealPlans)
    },
    getTextNewMealPlan(hotelMealPlans) {
      const mealPlanTexts: string[] = []
      hotelMealPlans?.forEach((m) => {
        const operatingTime = JSON.parse(m.during_time)
        const openTime = operatingTime?.from?.replace(':', 'h')
        const closeTime = operatingTime?.to?.replace(':', 'h')

        const isHotelHrs = m?.hotel_type == 'Global partner'
        const isInclusiveHrs = m?.valamount && parseFloat(m.valamount) === 0 && isHotelHrs

        let mealPlanText = ''
        switch (m.type) {
          case 1:
          case 6:
            mealPlanText = `Pre arranged ${m.mealplan_time} ${
              !isInclusiveHrs ? `available between ${openTime} and ${closeTime}` : ''
            }`
            break
          case 2:
            mealPlanText = `Pre arranged ${m.mealplan_time} available between ${openTime} and ${closeTime}`
            break
          case 3:
            mealPlanText = `Pre arranged ${m.mealplan_time} available between ${openTime} and ${closeTime}`
            break
        }
        mealPlanTexts.push(mealPlanText)
      })
      return mealPlanTexts
    },
    convertNumberToWords(number, langs) {
      return convertNumberToWords(number, langs)
    },
    checkOldMealPlan(mealPlan) {
      if (mealPlan?.params) {
        return JSON.parse(mealPlan.params).is_use_old_meal_plan ?? false
      }
      return false
    },
    getVHtmlContentHRSSupport(dataLanguage, dataDetail) {
      const enLang = dataLanguage.enLang.row78
      const phoneSupport = dataDetail.phone_number_hrs_support || '+492212077600'
      let htmlContent = this.languageReplace(enLang, { '<phone_number_hrs_support>': phoneSupport })

      if (this.showSecondLanguage) {
        const paxLang = dataLanguage.paxLang.row78
        htmlContent += `<i>/${this.languageReplace(paxLang, { '<phone_number_hrs_support>': phoneSupport })}</i>`
      }

      return htmlContent
    },
    generateTextVoucherTimeEntitled(dataDetail, hotelLanguage, isMultipleDay = false) {
      let textNumberNight = isMultipleDay
        ? this.replaceTextNumberNights(dataDetail.number_days, hotelLanguage.enLang)
        : hotelLanguage.enLang.row6
      let textVoucherTime = this.languageReplace(hotelLanguage.enLang.row22, dataDetail.voucher_time_entitled)
      let result = textNumberNight + ' ' + textVoucherTime

      if (this.showSecondLanguage) {
        let lang = this.showSecondLanguage ? hotelLanguage.paxLang : hotelLanguage.enLang
        let textNumberNight2 = isMultipleDay ? this.replaceTextNumberNights(dataDetail.number_days, lang) : lang.row6
        let textVoucherTime2 = this.languageReplace(lang.row22, dataDetail.voucher_time_entitled)
        result += ' <i>&nbsp;&#x200F;/&#x200F;&nbsp;' + textNumberNight2 + ' ' + textVoucherTime2 + '</i>'
      }

      return result
    },
    replaceTextNumberNights(numberDays, langs) {
      if (parseInt(numberDays) == 1) {
        return langs?.row6 || 'One night accommodation in'
      }

      let textNumberNights = langs?.row69
      if (!textNumberNights) {
        textNumberNights = '<number_nights> nights accommodation in'
      }

      return this.languageReplace(textNumberNights, { ['<number_nights>']: convertNumberToWords(numberDays, langs) })
    },
    linkify
  }
})
