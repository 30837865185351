import { BaseResponse } from './../types/global'
import { HTTPBaseService } from '@/utils/request'

export default class MealPlan extends HTTPBaseService {
  constructor(service = 'station-handler') {
    super(service)
  }

  public async fetchMealPlanVoucher(passengerId: number): Promise<BaseResponse> {
    const { data } = await this.instance.get(`mobile/mealPlanReceipt/${passengerId}`)
    return data
  }
}
