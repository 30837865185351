import styled from 'vue3-styled-components'

const StyledFooter = styled.section`
  .footer__container {
    height: 54px;
    background-color: #ffffff;
    font-family: 'Roboto';
    padding: 0 22px;
    display: flex;
    .footer__wrap {
      flex: 1;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
    }
  }
`

export default StyledFooter
