import styled from 'vue3-styled-components'
const NotLayout = styled('div')`
  .loader {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    position: fixed;
    top: 50%;
    left: calc((100% - 70px) / 2);
    animation: rotate 1s linear infinite;
    &::before {
      content: '';
      background: transparent;
      box-sizing: border-box;
      opacity: 0.95;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 5px solid #ffcf0f;
      animation: prixClipFix 2s linear infinite;
      z-index: 11000;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes prixClipFix {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11000;

    & ~ * {
      opacity: 50%;
    }
  }
`

export default NotLayout
