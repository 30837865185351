import styled from 'vue3-styled-components'

const SFSHotelCard = styled.div`
  @mixin important-text {
    line-height: 26pt;
    letter-spacing: -0.3pt;
  }

  .el-card {
    --el-card-border-radius: 0.75rem;
    .el-card__body {
      h3 {
        margin-top: 4px;
      }
    }
  }

  .carousel__item {
    width: 100%;
    background-color: var(--vc-clr-primary);
    color: var(--vc-clr-white);
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hotel-button-select {
    text-align: center;
    margin-top: -7px;
    .btn-action {
      width: 162px;
      height: 44px;
      margin: 0px auto 12px auto;
      border-radius: 17px;
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
      background-color: #ffffff;
      border: 0px;
      display: block;
      margin-top: 30px;
      span {
        font-size: 16px;
        letter-spacing: 0px;
        color: #f5d32a;
        font-weight: 500;
      }
    }
    .btn-action:hover {
      background-color: #0094d5;
      border: 0px;
      display: block;
      span {
        color: #f5d32a;
        font-weight: 500;
      }
    }
  }
  .hotel-card {
    --text-color: #474747;
    .hotel-distance,
    .content__hotel-info {
      margin-bottom: 11px;
      font-size: 14pt;
      margin-top: 6pt;
      @include important-text;
      color: var(--text-color);
    }

    .hotel-description {
      padding-bottom: 14pt;
      text-align: justify;
    }

    .btn-select {
      width: 40%;
      height: 44px;
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
      background-color: #ffffff;
      border: 0px;
      span {
        font-size: 16px;
        letter-spacing: 0px;
        color: #f5d32a;
        font-weight: 400;
      }
    }

    .btn-select:hover {
      background-color: #0094d5;
    }

    .hotel-horizontal {
      width: 100%;
      height: 1.5px;
      background-color: #ddd;
      border-radius: 10px;
    }

    .hotel-button-see-more {
      text-decoration: underline;
      color: #999;
      text-align: center;
      margin-bottom: 0pt;
      margin-top: 8pt;
      a {
        color: #999;
        font-weight: 300;
        display: inline-block;
        border-bottom: 1px solid #b9b5b5;
        padding-bottom: 1px;
        text-decoration: none;
      }
    }

    .pick-up-time {
      display: flex;
      align-items: center;

      &__value {
        cursor: pointer;
        --font-size: 24pt;
        border: 0 !important;
        height: var(--font-size);
        font-size: var(--font-size);
        color: var(--text-color);
        text-decoration: underline;
        font-weight: 700;
      }
    }

    .content__hotel-info {
      .hotel-information {
        padding-bottom: 2rem;

        .button-call-hrs {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12pt;

          a {
            border: 1px solid var(--text-color);
            border-radius: 8px;
            padding: 10px;
            text-decoration: none;
            color: var(--text-color);
          }

          i {
            color: #5ced73;
          }
        }

        .text-underline {
          margin: 0;
          width: fit-content;
          ::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: var(--text-color);
          }
        }
      }

      .prefix {
        font-weight: 500;
      }

      .text-cancel-hotel {
        opacity: 0.8;
        text-decoration: underline;
        font-size: 13pt;
      }

      a {
        @include important-text;
        text-decoration: underline;
        color: var(--text-color);
      }
    }
    .line-original {
      white-space: pre-line;
    }
  }
`

export default SFSHotelCard
