import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElInput = _resolveComponent("ElInput")!
  const _component_StyledSFSInput = _resolveComponent("StyledSFSInput")!

  return (_openBlock(), _createBlock(_component_StyledSFSInput, null, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.prop ? 'el-form-item' : 'div'), {
        prop: _ctx.prop,
        rules: _ctx.rules,
        class: "content-input"
      }, {
        default: _withCtx(() => [
          (_ctx.label)
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.label), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_ElInput, {
            modelValue: _ctx.inputValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
            label: _ctx.label,
            type: _ctx.type,
            rows: _ctx.rows,
            min: _ctx.min,
            max: _ctx.max,
            maxlength: _ctx.maxlength,
            step: _ctx.step,
            placeholder: _ctx.placeholder,
            disabled: _ctx.disabled,
            onChange: _ctx.handleChange,
            onBlur: _ctx.handleBlur
          }, _createSlots({ _: 2 }, [
            (_ctx.prepend)
              ? {
                  name: "prepend",
                  fn: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.prepend), 1)
                  ])
                }
              : undefined,
            (_ctx.append)
              ? {
                  name: "append",
                  fn: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.append), 1)
                  ])
                }
              : undefined
          ]), 1032, ["modelValue", "label", "type", "rows", "min", "max", "maxlength", "step", "placeholder", "disabled", "onChange", "onBlur"])
        ]),
        _: 1
      }, 8, ["prop", "rules"]))
    ]),
    _: 1
  }))
}