
import { defineComponent, computed, onMounted, onUnmounted, watch, onBeforeMount } from 'vue'

// import styled component
import StyledVoucher from '@/styled-components/pages/print-voucher'
import HotelVoucher from './HotelVoucher/Index.vue'
import MealplanVoucher from './MealPlanVoucher/Index.vue'
import TaxiVoucher from './TaxiVoucher/Index.vue'
import RefreshmentVoucher from './RefreshmentVoucher/Index.vue'
import MCVoucher from './MCVoucher/Index.vue'
import { useStore } from 'vuex'
import { ref } from 'vue'
import SfsApiPrintVouchersOnA4 from '@/models/SFSAPI_PrintVouchersOnA4'
import { dayOfWeek, formatDate, getMinMaxDate } from '@/utils/date'
import { empty } from '@/utils/variables'
import moment from 'moment'
import { StoreType } from '@/types/store'
import { useRouter } from 'vue-router'
import { isJsonString } from '@/utils/data-type'
import store from '@/store'
import _ from 'lodash'

export default defineComponent({
  name: 'PrintServices',
  components: {
    HotelVoucher,
    MealplanVoucher,
    TaxiVoucher,
    RefreshmentVoucher,
    MCVoucher,
    StyledVoucher
  },
  setup() {
    const store = useStore<StoreType>()

    const sectionPrint = ref<any>(null)

    // make print section scale to fit page width
    const scaleSectionPrint = () => {
      if (sectionPrint.value) {
        let w = window.innerWidth
        if (w < 995) {
          let scalePx = (w - 44) / 995
          sectionPrint.value.style['scale'] = scalePx
          sectionPrint.value.style['transform-origin'] = 'top left'

          let currentHeight = sectionPrint.value.getBoundingClientRect().height
          let ogHeight = currentHeight / scalePx
          let marginPx = ogHeight - currentHeight

          sectionPrint.value.style['margin-bottom'] = `-${marginPx}px`
        } else {
          removeScaleSectionPrint()
        }
      }
    }

    const removeScaleSectionPrint = () => {
      if (sectionPrint.value) {
        sectionPrint.value.style.removeProperty('scale')
        sectionPrint.value.style.removeProperty('margin-bottom')
        sectionPrint.value.style.removeProperty('transform-origin')
      }
    }

    onMounted(() => {
      const mountedInterval = setInterval(() => {
        if (sectionPrint.value && sectionPrint.value.getBoundingClientRect().height > 0) {
          scaleSectionPrint()
          window.addEventListener('resize', scaleSectionPrint)
          window.addEventListener('beforeprint', removeScaleSectionPrint)
          window.addEventListener('afterprint', scaleSectionPrint)
          clearInterval(mountedInterval)
        }
      }, 500)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', scaleSectionPrint)
      window.removeEventListener('beforeprint', removeScaleSectionPrint)
      window.removeEventListener('afterprint', scaleSectionPrint)
    })

    return {
      airline: computed(() => store.state.airline),
      paxThisIsMe: computed(() => store.state['groupPax'].me),
      listGroupPax: computed(() => store.getters['groupPax/listGroupPax']),
      sectionPrint
    }
  },
  data() {
    return {
      VoucherData: {},
      LanguageVoucher: {},
      ServiceIds: [],
      showSecondLanguage: false,
      router: useRouter(),
      langPaxDirection: 'ltr'
    }
  },
  watch: {
    listGroupPax() {
      this.fetchDataPrint()
    },
    airline() {
      this.fetchDataPrint()
    }
  },
  mounted() {
    this.fetchDataPrint()
  },
  methods: {
    fetchDataPrint() {
      const hasAriline = this.airline?.id
      if (!_.isUndefined(this.listGroupPax[0]) && !!hasAriline) {
        this.updateStatusVoucher()
        this.handleDataPrintA4()
      }
    },
    handleClose() {
      this.router.push({ path: '/home' })
      return
    },
    handlePrint() {
      window.print()
    },
    async printVouchersOnA4() {
      const listPaxIds = this.listGroupPax.map((item) => item.id)
      const dataSend = {
        data: {
          airline_id: this.airline?.id ?? 0,
          airport_code: this.paxThisIsMe?.overnight_station ?? this.paxThisIsMe?.dep ?? '',
          paxIds: listPaxIds,
          sttPrintAll: 1,
          isStaff: 0
        }
      }

      const sfsApi = new SfsApiPrintVouchersOnA4()
      return await sfsApi.printVouchersOnA4(dataSend)
    },
    async handleDataPrintA4() {
      const passengerInfo = this.listGroupPax
      const airlineParams = this.airline?.params
      const paxLanguage = this.paxThisIsMe?.language || 'en'
      this.langPaxDirection = this.paxThisIsMe?.direction || 'ltr'
      store.commit('lang/setTemplateDirection', { paxDirection: this.langPaxDirection })
      const { data } = await this.printVouchersOnA4()
      let output = { langs_data: {}, service_data: {} }

      let serviceIdArr: any = []
      const serviceNameArr: any = []
      let result = {}
      for (const key in data.langsData) {
        const lang = data.langsData[key]
        // always show en + pax language
        const langEng = _.find(lang, { lang_code: 'en' })
        const langPax = paxLanguage.toLowerCase() == 'en' ? [] : _.find(lang, { lang_code: paxLanguage.toLowerCase() }) ?? []
        this.showSecondLanguage = !empty(langPax)

        const dataService = data.serviceDetail[key]
        if (dataService && Object.keys(dataService).length === 0) continue

        const defaultFont = "Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;"

        let font = { fontName: defaultFont }
        if (isJsonString(langEng['parmas'])) {
          font = JSON.parse(langEng['parmas'])
        }

        font.fontName = !font.fontName ? defaultFont : font.fontName

        const serviceId = langEng['service_id']
        output.service_data[serviceId] = dataService

        // Check if it is service hotel
        if (Number(key) == 1) {
          let resultService = {}
          let listVouchersPax = {}
          for (const pax of passengerInfo) {
            if (pax.voucher_id) {
              listVouchersPax[pax.voucher_id] = pax
            }
          }

          if (listVouchersPax) {
            for (let keyVoucher in listVouchersPax) {
              const voucherPax = listVouchersPax[keyVoucher]
              const paxId = voucherPax.id
              const hotel = dataService['hotel'][keyVoucher]
              const receptionTime = !empty(hotel.reception_time) ? JSON.parse(hotel.reception_time) : null

              const vouchers = dataService['vouchers_detail'][keyVoucher]
              const mealPlans = dataService['meal_plan_by_day'][keyVoucher]

              const isHotelHasMealPlans = this.checkMealPlanStatus(mealPlans)

              let checkinRegular = {}
              vouchers.forEach((voucher) => {
                if (voucher.payment_type !== 'passenger') {
                  dataService['hotel'][keyVoucher]['voucher_vat_comment_line'] = airlineParams?.voucher_vat_comment_line
                }
                const dayofWeek = dayOfWeek(voucher.date)
                if (!empty(receptionTime) && !empty(receptionTime.regular)) {
                  checkinRegular = receptionTime.regular[dayofWeek - 1]
                }
                let singleDoubleRoomName = 'single/double room'
                if (voucher.room_type === 2 && voucher.seats === 2) {
                  singleDoubleRoomName = 'Double room'
                }
                const roomMap = { 1: 'single room', 2: singleDoubleRoomName, 3: 'triple room', 4: 'quad room' }
                const dayVoucher = moment(voucher.date, 'YYYY/MM/DD')
                voucher.voucher_time_entitled = {
                  '<room_type>': roomMap[voucher.room_type],
                  '<startdate>': dayVoucher.format('DD-MMM-YYYY'),
                  '<enddate>': dayVoucher.add(1, 'days').format('DD-MMM-YYYY')
                }
              })

              const replaceData = {
                '<services>': '',
                '<pax_first_name>': voucherPax ? voucherPax.first_name : '',
                '<pax_last_name>': voucherPax ? voucherPax.last_name : '',
                '<checkin_opentime>': checkinRegular ? checkinRegular['open_time'] : '',
                '<checkin_closetime>': checkinRegular ? checkinRegular['close_time'] : ''
              }

              const enLang = await this.replaceLanguages(langEng['content'], replaceData)
              const paxLang = await this.replaceLanguages(langPax['content'], replaceData)
              result[voucherPax.id] = {
                enLang: enLang,
                paxLang: paxLang
              }
              output.langs_data[key] = result
              const dataHotel = dataService['hotel'][keyVoucher]
              dataHotel.voucher_code = dataService['vouchers_detail'][keyVoucher][0]['code']
              dataHotel.general_comments = dataService['vouchers_detail'][keyVoucher][0]['comment_airline']
              dataHotel.transport_id = dataService['vouchers_detail'][keyVoucher][0]['transport_id']

              const isCombine = this.checkCombineHotelVoucher(vouchers, isHotelHasMealPlans)
              if (isCombine) {
                dataHotel.detail = this.combineHotelVouchers(vouchers)
              } else {
                dataHotel.detail = vouchers
              }
              dataHotel.hotelMealPlans = mealPlans
              const paxInSameVoucher = passengerInfo.filter((p) => p.voucher_id == keyVoucher)
              const name = paxInSameVoucher.map((p) => p.first_name + ' ' + p.last_name)
              dataHotel.countPax = paxInSameVoucher.length
              dataHotel.pax_name = name.join(', ')
              resultService[paxId] = dataHotel
            }
            output.service_data[key] = resultService
          }
        } else {
          const namePaxArray = passengerInfo.map((pax) => `${pax.first_name} ${pax.last_name}`)
          const strNamePaxs = namePaxArray.toString()

          let result = {}
          for (let paxKey in passengerInfo) {
            const paxVal = passengerInfo[paxKey]

            const arrCommonData = {
              '<pax_first_name>': paxVal.first_name ?? '',
              '<pax_last_name>': paxVal.last_name ?? ''
            }

            switch (Number(key)) {
              case 2: {
                if (!dataService[paxVal.id]) break

                const refreshmentCurrency = dataService[paxVal.id].currency_creadit
                  ? dataService[paxVal.id].currency_creadit
                  : dataService[paxVal.id].currency

                const refreshmentAmount = dataService[paxVal.id].amount_credit
                  ? dataService[paxVal.id].amount_credit
                  : dataService[paxVal.id].price_per_person

                const paxName = `${paxVal.title || 'MX'} ${paxVal.first_name} ${paxVal.last_name}`
                const blockCode = dataService[paxVal.id].block_code

                serviceNameArr.push(langPax['name_service'])

                const arrData = {
                  '<pax_name>': paxName,
                  '<currency>': refreshmentCurrency,
                  '<flight_number>': paxVal.carrier + paxVal.flight_no || this.paxThisIsMe?.fltref?.split('-')[0],
                  '<block_code>': blockCode,
                  '<flight_date>': formatDate(paxVal.flight_date, 'DD-MMM-YYYY'),
                  '<vcn_expiry_date_time>': moment(paxVal.flight_date)
                    .add(airlineParams.expire_day_p2c, 'days')
                    .format('DD-MMM-YYYY')
                }

                const replaceData = { ...arrData, ...arrCommonData }

                const enLang = await this.replaceLanguages(langEng['content'], replaceData)
                const paxLang = await this.replaceLanguages(langPax['content'], replaceData)

                result[paxVal.id] = {
                  enLang: enLang,
                  paxLang: paxLang
                }
                break
              }
              case 4: {
                if (!dataService[paxVal.id]) break
                const optionTaxi = dataService[paxVal.id].option_taxi

                let taxi: any = {}
                let dataTaxi: any = {}
                if (optionTaxi === 4) {
                  // option to address
                  taxi.name = 'Custom address'
                  taxi.address = dataService[paxVal.id].to_address
                  taxi.telephone = 'N/A'
                  taxi.zipcode = ''
                  dataTaxi = {
                    ['to_address']: {
                      ['address']: dataService[paxVal.id].to_address || ''
                    },
                    ['address']: dataService[paxVal.id].from_andress,
                    ['telephone']: ''
                  }
                } else if (optionTaxi === 3) {
                  // option to airport
                  taxi.name = 'Custom address'
                  taxi.address = dataService[paxVal.id].from_andress
                  taxi.telephone = 'N/A'
                  taxi.zipcode = ''
                  dataTaxi = {
                    ['to_address']: {
                      ['address']: dataService[paxVal.id].to_address || ''
                    },
                    ['address']: dataService[paxVal.id].from_andress,
                    ['telephone']: ''
                  }
                } else {
                  const hotel = data.serviceDetail[1]['hotel'][paxVal.voucher_id]
                  taxi.name = hotel.name
                  taxi.address = `${hotel.address}, ${hotel.zipcode} ${hotel.city}`
                  taxi.telephone = hotel.telephone
                  taxi.zipcode = hotel.zipcode
                  dataTaxi['to_address'] = { name: hotel.name }
                  dataTaxi['to_address']['address'] = `${hotel.address}, ${hotel.zipcode} ${hotel.city}`
                  dataTaxi['address'] = dataService[paxVal.id].from_andress
                  dataTaxi['telephone'] = hotel.telephone
                }
                dataTaxi['airport'] = !empty(paxVal.overnight_station_airport) ? paxVal.overnight_station_airport : ''
                // dataTaxi['airport'] = !empty(paxVal.overnight_station_airport) ? paxVal.overnight_station_airport : airport.name

                dataTaxi['currency'] = dataService[paxVal.id].currency
                dataTaxi['way_option'] = dataService[paxVal.id].way_option
                dataTaxi['flight_number'] = paxVal.carrier + paxVal.flight_no
                dataTaxi['block_code'] = dataService[paxVal.id].block_code
                dataTaxi['voucher_code'] = dataService[paxVal.id].voucher_code
                dataTaxi['details'] = dataService[paxVal.id].details
                dataTaxi['details'][0].destination = dataTaxi['to_address']

                // case taxi 2 way
                if (!empty(dataTaxi['details'][1])) {
                  dataTaxi['details'][1].destination = {
                    ['name']: dataTaxi['address']
                  }
                }
                dataTaxi['taxi_company'] = dataService[paxVal.id].taxi_name
                dataTaxi['qrCode'] = dataService[paxVal.id].qrCodeDetail
                dataTaxi['flight_date'] = formatDate(paxVal.flight_date, 'DD-MMM-YYYY')
                dataTaxi['params'] = JSON.parse(dataService[paxVal.id].params)
                const paxParams = dataTaxi['params']

                // Matrix taxi processs
                let groupId = paxParams ? paxParams.group_id : ''
                const matrixTaxiGroup = this.getGroupTaxiMatrix(dataTaxi['params'], paxVal.id)
                if (!empty(matrixTaxiGroup)) groupId = paxVal.id

                const paxNameTaxiMatrix = this.makePaxNameTaxiVoucher(matrixTaxiGroup, passengerInfo)
                const paxNameTaxi = !empty(paxNameTaxiMatrix) ? paxNameTaxiMatrix : strNamePaxs
                dataTaxi['full_name'] =
                  paxNameTaxi != '' ? paxNameTaxi : `${paxVal.title} ${paxVal.first_name} ${paxVal.last_name}`
                dataTaxi['first_name'] = paxVal.first_name
                dataTaxi['last_name'] = paxVal.last_name

                const replaceData = {
                  '<airportName>': dataTaxi['airport'],
                  '<pax_name>': dataTaxi['full_name'],
                  '<hotel_name>': dataTaxi['to_address'],
                  '<hotel_address>': dataTaxi['address'],
                  '<hotel_phone>': dataTaxi['telephone'],
                  '<currency>': dataTaxi['currency'],
                  '<flight_number>': dataTaxi['flight_number'],
                  '<block_code>': dataTaxi['block_code'],
                  '<flight_date>': dataTaxi['flight_date'],
                  '<taxi_company>': dataTaxi['taxi_company'],
                  '<pax_first_name>': dataTaxi['first_name'],
                  '<pax_last_name>': dataTaxi['last_name'],
                  '<vcn_expiry_date_time>': moment(paxVal.flight_date)
                    .add(airlineParams.expire_day_p2c, 'days')
                    .format('DD-MMM-YYYY')
                }
                const enLang = await this.replaceLanguages(langEng['content'], replaceData)
                const paxLang = await this.replaceLanguages(langPax['content'], replaceData)
                result[paxVal.id] = {
                  enLang: enLang,
                  paxLang: paxLang
                }
                break
              }
              case 21: {
                if (!dataService[paxVal.id]) break

                const paxName = `${paxVal.title || 'MX'} ${paxVal.first_name} ${paxVal.last_name}`
                const blockCode = dataService[paxVal.id][0].block_code

                serviceNameArr.push(langPax['name_service'])

                const arrData = {
                  '<pax_name>': paxName,
                  '<flight_number>': paxVal.carrier + paxVal.flight_no || this.paxThisIsMe?.fltref?.split('-')[0],
                  '<flight_irreg_reason>': paxVal.flight_irreg_reason,
                  '<block_code>': blockCode,
                  '<flight_date>': formatDate(paxVal.flight_date, 'DD-MMM-YYYY'),
                  '<vcn_expiry_date_time>': moment(paxVal.flight_date)
                    .add(airlineParams.expire_day_p2c, 'days')
                    .format('DD-MMM-YYYY')
                }

                const replaceData = { ...arrData, ...arrCommonData }

                const enLang = await this.replaceLanguages(langEng['content'], replaceData)
                const paxLang = await this.replaceLanguages(langPax['content'], replaceData)
                result[paxVal.id] = {
                  enLang: enLang,
                  paxLang: paxLang
                }
                break
              }
              case 23: {
                if (!dataService[paxVal.id]) break
                let mealPlanCurrency = dataService[paxVal.id].currency_creadit
                  ? dataService[paxVal.id].currency_creadit
                  : dataService[paxVal.id].currency
                const mealPlanAmount = dataService[paxVal.id].amount_credit
                  ? dataService[paxVal.id].amount_credit
                  : dataService[paxVal.id].price_per_person

                const mealPlanDetail = dataService[paxVal.id]?.detail
                mealPlanCurrency = mealPlanDetail?.[0]
                  ? mealPlanDetail?.[0]?.currency || mealPlanCurrency
                  : dataService[paxVal.id]?.currency || mealPlanCurrency

                const paxName = `${paxVal.title || 'MX'} ${paxVal.first_name} ${paxVal.last_name}`
                const blockcode = dataService[paxVal.id].block_code

                const arrData = {
                  '<pax_name>': paxName,
                  '<currency>': mealPlanCurrency,
                  '<flight_number>': paxVal.carrier + paxVal.flight_no || this.paxThisIsMe?.fltref?.split('-')[0],
                  '<block_code>': dataService[paxVal.id].block_code,
                  '<flight_date>': formatDate(paxVal.flight_date, 'DD-MMM-YYYY'),
                  '<vcn_expiry_date_time>': moment(paxVal.flight_date)
                    .add(airlineParams.expire_day_p2c, 'days')
                    .format('DD-MMM-YYYY')
                }
                const replaceData = { ...arrData, ...arrCommonData }
                const enLang = await this.replaceLanguages(langEng['content'], replaceData)
                const paxLang = await this.replaceLanguages(langPax['content'], replaceData)
                result[paxVal.id] = {
                  enLang: enLang,
                  paxLang: paxLang
                }
                break
              }
              default:
                break
            }
            serviceIdArr.push(Number(key))
          }
          output.langs_data[key] = result
        }
      }
      this.LanguageVoucher = output.langs_data
      this.ServiceIds = serviceIdArr
      this.VoucherData = output.service_data
      this.updatePrintVoucher()
    },
    async updateStatusVoucher() {
      const listPaxIds = this.listGroupPax.map((item) => item.id)
      const dataSend = {
        data: {
          printtype: 'print',
          voucher_id: 0,
          paxIds: listPaxIds
        }
      }
      const sfsApi = new SfsApiPrintVouchersOnA4()
      await sfsApi.updateStatusVoucher(dataSend)
    },
    async updatePrintVoucher() {
      const paxIds = this.listGroupPax.map((item) => item.id)
      const serviceIds = [...new Set(this.ServiceIds)]

      const dataSend = {
        pax_id: paxIds.toString(),
        service_id: serviceIds.toString(),
        airline_token: this.airline?.unique_token ?? ''
      }
      const sfsApi = new SfsApiPrintVouchersOnA4()
      await sfsApi.updatePrintVoucher(dataSend)
    },
    async replaceLanguages(langsData: any, replaceData: any) {
      let result = {}
      if (empty(langsData)) return result

      for (const index in replaceData) {
        const element = replaceData[index]

        if (Object.keys(result).length < 1) {
          const stringReplace = JSON.stringify(langsData).replaceAll(index, element)
          result = JSON.parse(stringReplace)
        } else {
          const stringReplace = JSON.stringify(result).replaceAll(index, element)
          result = JSON.parse(stringReplace)
        }
      }
      return result
    },
    getGroupTaxiMatrix(data, paxId) {
      if (empty(data) || empty(data.matrix_share_taxi)) {
        return []
      }

      let result = []
      data.matrix_share_taxi.forEach((keys) => {
        if (keys.includes(paxId)) result = keys
      })
      return result
    },
    makePaxNameTaxiVoucher(groups, passengers) {
      if (empty(groups)) return null

      const namePaxArray: any = []
      passengers.forEach((pax) => {
        const name = pax.first_name + ' ' + pax.last_name
        if (Object.values(groups).includes(pax.id)) {
          namePaxArray.push(name)
        }
      })

      return namePaxArray.toString()
    },
    checkCombineHotelVoucher(vouchers, isHotelHasMealPlans): Boolean {
      if (!isHotelHasMealPlans || empty(vouchers[0])) return false

      const firstBookingId = vouchers[0].booking_id
      const firstVoucherId = vouchers[0].voucher_id

      const isCombine = vouchers.every((voucher) => {
        return voucher.booking_id === firstBookingId && voucher.voucher_id === firstVoucherId
      })

      return isCombine
    },
    combineHotelVouchers(vouchers) {
      if (empty(vouchers) || empty(vouchers[0])) return []

      let dates: any = []
      let blockCodes: Object = {}
      let totalNight = 0

      vouchers.forEach((voucher) => {
        dates.push(voucher.date)
        blockCodes[voucher.date] = voucher.blockcode
        totalNight += Number(voucher.number_days)
      })

      blockCodes = Object.fromEntries(Object.entries(blockCodes).sort())

      const result = vouchers[0]

      const minMaxDate = getMinMaxDate(dates)

      let singleDoubleRoomName = 'Single/Double room'
      if (result.room_type === 2 && result.seats === 2) {
        singleDoubleRoomName = 'Double room'
      }
      const roomMap = { 1: 'Single room', 2: singleDoubleRoomName, 3: 'Triple room', 4: 'Quad room' }
      const MinDateVoucher = moment(minMaxDate.minDate, 'YYYY/MM/DD')
      const MaxDateVoucher = moment(minMaxDate.maxDate, 'YYYY/MM/DD')
      result.voucher_time_entitled = {
        '<room_type>': roomMap[result.room_type],
        '<startdate>': MinDateVoucher.format('DD-MMM-YYYY'),
        '<enddate>': MaxDateVoucher.add(1, 'days').format('DD-MMM-YYYY')
      }

      result.blockcode = Object.values(blockCodes).join(', ')
      result.isCombineHotelVoucher = true
      result.number_days = totalNight
      return [result]
    },
    checkMealPlanStatus(mealPlans): Boolean {
      if (!mealPlans || empty(mealPlans)) return false

      const isOldMealPlan = this.checkOldMealPlan(mealPlans)
      if (!isOldMealPlan) return true

      return mealPlans.some((mealPlan) => mealPlan.breakfast || mealPlan.lunch || mealPlan.mealplan)
    },
    checkOldMealPlan(mealPlan): Boolean {
      if (mealPlan?.params) {
        return JSON.parse(mealPlan.params).is_use_old_meal_plan ?? false
      }
      return false
    }
  }
})
