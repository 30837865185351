import styled from 'vue3-styled-components'

const SFSMatrixSelect = styled.section`
  .matrix-table {
    display: block;
    padding-bottom: 10px;
    overflow-x: auto;
  }
  .matrix {
    &__wrapper {
      input[type='radio']:checked {
        box-shadow: 0 0 0 2px #78ee47;
      }

      input[type='radio']:before {
        content: '';
        display: block;
        width: 76%;
        height: 69%;
        margin: 15% auto;
        border-radius: 50%;
      }

      input[type='radio']:checked:before {
        background: #78ee47;
      }
    }
    .el-icon-arrow-u {
      color: #696969;
    }

    &-table {
      font-size: 16px;
      border-collapse: inherit;

      .padding-td {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }

      .pax-fullname {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        width: 170px;
      }
    }

    &-name ::v-deep {
      width: 150px;
    }
  }

  input[type='radio'] {
    -webkit-appearance: none;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    outline: none;
    box-shadow: 0 0 0 2px gray;
  }
`

export default SFSMatrixSelect
