
import { defineComponent } from 'vue'
import StyledSearchOption from '@/styled-components/pages/combine-voucher-2/searchOption'
import LangMixinsVue from '../SearchAndSelectPassengers/Mixin/LangMixins.vue'
export default defineComponent({
  name: 'SearchOption',
  components: {
    StyledSearchOption
  },
  mixins: [LangMixinsVue],
  props: {
    searchType: {
      type: String,
      default: ''
    },
    handleScan: {
      type: Function,
      default: () => {}
    }
  },
  emits: ['updateStep', 'update:searchType'],
  data() {
    return {
      scanVisible: false,
      laserVisible: false
    }
  },
  methods: {
    updateStep(type) {
      this.$emit('update:searchType', type)
      this.$emit('updateStep', 'search')
    }
  }
})
