import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "my-4" }
const _hoisted_3 = { class: "ws-textbox" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_ItemOfVouchers = _resolveComponent("ItemOfVouchers")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_StyledNoHotelsAvailable = _resolveComponent("StyledNoHotelsAvailable")!

  return (_openBlock(), _createBlock(_component_StyledNoHotelsAvailable, null, {
    default: _withCtx(() => [
      _createElementVNode("section", null, [
        _createVNode(_component_Title, {
          "title-name": _ctx.groundHandlerMessage?.hotel?.SFS_LANG_ROW_44 || 'Home',
          "title-link": "Home"
        }, null, 8, ["title-name"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ItemOfVouchers, {
              icon: "images/icon-voucher/hotel-voucher.png",
              color: "#f0725e",
              label: _ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_1,
              border: ""
            }, null, 8, ["label"])
          ]),
          (_ctx.availableChangeRoomAllocation)
            ? (_openBlock(), _createBlock(_component_el_card, {
                key: 0,
                class: "box-card change-room-allocation mt-4 mb-4",
                onClick: _ctx.handleChangeRoomAllocation
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_41 || 'Change Room allocation'), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: `${_ctx.publicPath}images/icon-voucher/warning.png`,
              alt: "",
              srcset: "",
              class: "img-voucher"
            }, null, 8, _hoisted_4),
            _createElementVNode("p", null, _toDisplayString(_ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_31), 1)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}