export const SEARCH_FORM_RULES = {
  pnr: [{ required: true, message: 'This field is required' }],
  firstName: [{ required: true, message: 'This field is required' }],
  lastName: [{ required: true, message: 'This field is required' }]
}

export const SELECT_FLIGHT_RULES = {
  flightId: [{ required: true, message: 'Please select flight' }]
}

export const CONFIRM_THIS_IS_ME_RULES = {
  mySelectedId: [{ required: true, message: 'This field is required' }]
}

export const ADD_MORE_PAX_RULES = {
  checkboxSelectedPaxIds: [
    {
      type: 'array',
      required: true,
      message: 'Please select at least one passenger',
      trigger: 'change'
    }
  ],
  listNewGroupPaxsExcludeMeIds: [
    {
      required: true,
      message: 'Please select at least one passenger'
    }
  ]
}
