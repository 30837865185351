import styled from 'vue3-styled-components'

const SFSTitle = styled.section`
  .container-header {
    padding-top: 0.35rem;
    margin: -13px -20px -11px -21px;
  }
  .page-title-link {
    background-color: white;
    direction: ltr;
    a {
      text-decoration: none;
      align-items: center;
      color: #474747;
      i {
        max-width: 1rem;
      }
      display: flex;
      width: 100%;
      h1 {
        color: #474747;
        white-space: nowrap;
        font-size: 14pt;
        margin-left: 20px;
      }
    }
  }
  .back-to {
    display: flex;
  }
`

export default SFSTitle
