import { BaseResponse } from './../types/global'
import { HTTPBaseService } from '@/utils/request'
import { GetAirlineDetailBody } from '@/types/airline'

export default class Airline extends HTTPBaseService {
  constructor() {
    super('station-handler')
  }

  public async getAirlineDetail(body: GetAirlineDetailBody): Promise<BaseResponse> {
    const { data } = await this.instance.post('airlines/airlineDetail', body)
    return data
  }
}
