
import { computed, defineComponent, PropType } from 'vue'
import SearchOption from './SearchOption.vue'
import Search from './Search.vue'
import StyledAddMorePax from '@/styled-components/pages/combine-voucher-2/addMorePax'
import ItemOfVoucher from '@/components/ItemOfVoucher/Index.vue'
import Passenger from '@/models/Passenger'
import { COMBINE_VOUCHER_RULES } from '@/constants/combineVoucher2'
import CombineVoucherMixinVue from './Mixin/CombineVoucherMixin.vue'
import { getUser } from '@/utils/auth'
import moment from 'moment'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { StoreType } from '@/types/store'
import { PassengerType } from '@/types/passenger'
import _ from 'lodash'
import LangMixinsVue from '../SearchAndSelectPassengers/Mixin/LangMixins.vue'
export default defineComponent({
  name: 'AddMorePax',
  components: {
    SearchOption,
    Search,
    StyledAddMorePax,
    ItemOfVoucher
  },
  mixins: [CombineVoucherMixinVue, LangMixinsVue],
  props: {
    listGroupPaxs: {
      type: Array as PropType<PassengerType[]>,
      default: () => []
    },
    myId: {
      type: Number,
      default: undefined
    },
    listSelectedOtherPaxsOnFlight: {
      type: Array as PropType<PassengerType[]>,
      default: () => []
    }
  },
  emits: ['updateStep', 'update:listSelectedOtherPaxsOnFlight'],
  setup() {
    const store = useStore<StoreType>()
    const router = useRouter()
    return {
      flightInfo: computed(() => store.state.flightInfo),
      router
    }
  },
  data() {
    // Auto select pax in group pax
    const selectedPaxForm = { checkboxSelectedPaxIds: this.listGroupPaxs.map((pax) => pax.id ?? 0) }
    const searchedPaxs: PassengerType[] = []
    const addMorePaxRule = COMBINE_VOUCHER_RULES
    const mySelectedListOtherPaxsIds: number[] = this.listSelectedOtherPaxsOnFlight.map((pax) => pax.id ?? 0)
    return {
      miniStep: 'searchOption',
      miniSearchType: '',
      passengerMiniSearchInfo: {
        pnr: '',
        firstName: '',
        lastName: ''
      },
      mySelectedListOtherPaxsIds,
      selectedPaxForm,
      addMorePaxRule,
      searchedPaxs,
      isShowModalSelectPax: false,
      myInfo: this.listGroupPaxs.find((pax: any) => pax.id == this.myId),
      isShowMessage: true
    }
  },
  computed: {
    myNewListGroupPaxs() {
      return this.listGroupPaxs.filter((pax: any) => this.mySelectedListOtherPaxsIds.includes(pax.id))
    }
  },
  methods: {
    updateMiniStep(newMiniStep) {
      this.miniStep = newMiniStep
    },
    async handleMiniSearchPax(isScanning = false) {
      // Handle search pax
      const passenger = new Passenger()
      this.passengerMiniSearchInfo.pnr = _.trim(this.passengerMiniSearchInfo.pnr)
      this.searchedPaxs = (
        await passenger.searchPax({
          airline_id: getUser().airline?.id,
          pnr: this.passengerMiniSearchInfo.pnr,
          flight_id: this.flightInfo?.flight_id,
          first_name: this.passengerMiniSearchInfo.firstName.trim(),
          last_name: this.passengerMiniSearchInfo.lastName.trim(),
          search_type: this.miniSearchType ?? ''
        })
      ).data as PassengerType[]

      // Case scan pax
      if (isScanning) {
        const searchedPaxs = this.searchedPaxs.find(
          (p) =>
            p.pnr === this.passengerMiniSearchInfo.pnr &&
            p.first_name === this.passengerMiniSearchInfo.firstName &&
            p.last_name === this.passengerMiniSearchInfo.lastName
        )
        if (!searchedPaxs) {
          this.searchedPaxs = []
        } else {
          this.searchedPaxs = [searchedPaxs]
        }
      }

      const listGroupPaxIds = this.listGroupPaxs.map((pax) => pax.id)

      if (this.searchedPaxs.length === 0) this.throwNoPaxFoundError()
      else if (this.searchedPaxs.length === 1) {
        // Case group has only me and scan myself
        if (this.searchedPaxs[0].id == this.myId) {
          this.throwNoPaxFoundError()
          return
        }

        this.selectedPaxForm.checkboxSelectedPaxIds = this.searchedPaxs.map((pax) => pax.id ?? 0)
        this.handleSelectPax()
        this.throwAddPaxSuccess()
      } else {
        // Show pax in group
        this.searchedPaxs = _.uniqBy([...this.searchedPaxs, ...this.listGroupPaxs], (p) => p.id).filter(
          (pax) => pax.id != this.myId
        )

        if (this.miniSearchType == 'PNR')
          this.searchedPaxs = this.searchedPaxs.filter((p) => p.pnr == this.passengerMiniSearchInfo.pnr)
        if (this.miniSearchType == 'NAME')
          this.searchedPaxs = this.searchedPaxs.filter(
            (p) =>
              p.first_name === this.passengerMiniSearchInfo.firstName && p.last_name === this.passengerMiniSearchInfo.lastName
          )

        this.searchedPaxs = this.searchedPaxs.map((pax) => {
          if (listGroupPaxIds.includes(pax.id)) {
            pax.isDisableSelect = true
          }
          return pax
        })

        if (this.searchedPaxs.length === 0) {
          this.throwNoPaxFoundError()
          return
        }

        if (this.searchedPaxs.length === 1) {
          // case filter has only me
          if (this.searchedPaxs[0].id == this.myId) {
            this.throwNoPaxFoundError()
            return
          }

          this.selectedPaxForm.checkboxSelectedPaxIds = this.searchedPaxs.map((pax) => pax.id ?? 0)
          this.handleSelectPax()
          this.throwAddPaxSuccess()
        } else this.isShowModalSelectPax = true

        this.selectedPaxForm.checkboxSelectedPaxIds = this.filterNewListGroupPaxInSearchedPax()
      }
    },
    filterNewListGroupPaxInSearchedPax() {
      // Auto select checkbox having selected paxs
      const listGroupPaxIds = this.listGroupPaxs.map((pax) => pax.id)

      return this.searchedPaxs
        .filter((pax: any) => this.mySelectedListOtherPaxsIds.includes(pax.id) || listGroupPaxIds.includes(pax.id))
        .map((pax: any) => pax.id)
    },
    handleCloseSelectPax() {
      this.isShowMessage = false
      const form = this.$refs.addMorePaxForm as any
      form.resetFields()
      this.isShowModalSelectPax = false
    },
    handleOpenSelectPax() {
      this.isShowMessage = true
      const form = this.$refs.addMorePaxForm as any
      form.resetFields()
    },
    handleSelectPax() {
      const form = this.$refs.addMorePaxForm as any
      if (form)
        form.validate((valid) => {
          if (!valid) return
          this.isShowModalSelectPax = false
          this.mySelectedListOtherPaxsIds = [
            ...new Set([...this.mySelectedListOtherPaxsIds, ...this.selectedPaxForm.checkboxSelectedPaxIds])
          ]
          this.handleConfirm()
        })
      // Case scan QR
      else {
        this.isShowModalSelectPax = false
        this.mySelectedListOtherPaxsIds = [
          ...new Set([...this.mySelectedListOtherPaxsIds, ...this.selectedPaxForm.checkboxSelectedPaxIds])
        ]
        this.handleConfirm()
      }
    },
    handleConfirm() {
      const listSearchedPaxsAndPreviousSelectedPax = _.uniqBy(
        [...this.listSelectedOtherPaxsOnFlight, ...this.searchedPaxs],
        (p) => p.id
      )
      const listGroupPaxIds = this.listGroupPaxs.map((pax) => pax.id)

      this.$emit(
        'update:listSelectedOtherPaxsOnFlight',
        listSearchedPaxsAndPreviousSelectedPax
          .filter((pax) => this.mySelectedListOtherPaxsIds.includes(pax.passenger_id ?? pax.id ?? 0))
          .filter((pax) => !listGroupPaxIds.includes(pax.id))
      )
      this.$emit('updateStep', 'selectPaxTraveling')
    },
    async handleMiniQRScan(decodedText) {
      const passenger = new Passenger()
      const { data: parsedPax } = await passenger.parseStringToPax({ boardingPass: decodedText })
      this.passengerMiniSearchInfo = {
        pnr: parsedPax.pnr,
        firstName: parsedPax.firstName,
        lastName: parsedPax.lastName
      }
      this.miniSearchType = 'NAME'
      await this.handleMiniSearchPax(true)

      this.passengerMiniSearchInfo = {
        pnr: '',
        firstName: '',
        lastName: ''
      }
    },
    backStep() {
      this.router.push({ name: 'Home' })
    }
  }
})
