import DefaultLayout from '@/layouts/Default.vue'
import authentication from '@/middleware/authentication'
import CombineVoucher from '@/views/CombineVoucher2/Index.vue'

export const combineVoucher = {
  path: '/',
  component: DefaultLayout,
  meta: {
    middleware: [authentication]
  },
  children: [
    {
      path: '/combine-voucher',
      name: 'CombineVoucher',
      component: CombineVoucher
    }
  ]
}
