import { createApp } from 'vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import middleware from '@grafikri/vue-middleware'
import ElementPlus from 'element-plus'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import 'element-plus/lib/theme-chalk/index.css'
import '@/assets/sass/style.scss' // global css
import '@/assets/sass/checkbox.scss'
import '@/assets/css/fontawesome/fontawesome.css'
import App from './App.vue'
import router from './router'
import store from './store'
import BaseComponents from './components/index'
import Filters from './filters/index'

require('dotenv').config()

router.beforeEach(middleware({ store }))

const app = createApp(App)
  .use(router)
  .use(store)
  .use(ElementPlus, { size: 'small', zIndex: 3000 })
  .use(VueGoogleMaps)
  .use(VueTelInput)

// import all icons from @element-plus/icons-vue and register them globally
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// Init sentry.io
if (process.env.VUE_APP_SENTRY_DNS) {
  Sentry.init({
    app,
    dsn: '',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    tracesSampleRate: 1.0
  })
}

// declare global variables
app.config.globalProperties.rentalClientUrl = process.env.VUE_APP_RENTAL_PAGE_URL
app.config.globalProperties.cloudFrontUrl = process.env.VUE_APP_AMAZONS3_CLOUDFRONTURL
app.config.globalProperties.isMobile = document.body.offsetWidth < 587

BaseComponents.register(app)
Filters.register(app)

app.mount('#app')
