import { ConfirmTaxiBody } from '@/types/sfsApiMobile'
import { HTTPBaseService } from '@/utils/request'
import { BaseResponse } from './../types/global'

export default class SfsApiMobile extends HTTPBaseService {
  constructor() {
    super('sfs-api')
  }

  public async issueTaxi(body: ConfirmTaxiBody): Promise<BaseResponse> {
    const { data } = await this.instance.post('mobile/taxi/issue-taxi-transfer', { data: body })
    return data
  }

  public async updateTaxiTransferOption(body: ConfirmTaxiBody): Promise<BaseResponse> {
    const { data } = await this.instance.put('mobile/update-taxi-transfer-option', { data: body })
    return data
  }

  public async sendSMSToOthers(body: ConfirmTaxiBody): Promise<BaseResponse> {
    const { data } = await this.instance.put('mobile/send-sms-to-others', { data: body })
    return data
  }
}
