
import StyledSFSFlightInfo from '@/styled-components/components/SFSFlightInfo'
import LangMixinsVue from '@/views/SearchAndSelectPassengers/Mixin/LangMixins.vue'

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    StyledSFSFlightInfo
  },
  mixins: [LangMixinsVue],
  props: {
    flight: {
      type: Object,
      default: () => {}
    },
    statusFlight: {
      type: String,
      default: ''
    }
  }
})
