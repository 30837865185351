
import { ElNotification } from 'element-plus'
import { defineComponent } from 'vue'
import { formatDate } from '@/utils/date'
import { PassengerType } from '@/types/passenger'

export default defineComponent({
  methods: {
    checkListGroupPaxsHasIssuedService(listGroupPaxs: PassengerType[]) {
      return listGroupPaxs.some((pax) => pax.issued_service)
    },

    listGroupPaxExcludeMe(listGroupPax: Array<any>, myId) {
      return listGroupPax.filter((pax) => pax.id != myId)
    },
    throwMustSelectAllMemberInGroupIssuedWarning() {
      ElNotification.closeAll()
      ElNotification({
        title: 'Error',
        message: 'Modify group is not possible for customers for which services have been issued already',
        type: 'error',
        duration: 3000
      })
    },
    throwPleaseAddMorePassengersError() {
      ElNotification.closeAll()
      ElNotification({
        title: 'Error',
        message: 'Please add more passengers',
        type: 'error',
        duration: 3000
      })
    },
    throwNoPaxFoundError() {
      ElNotification.closeAll()
      ElNotification({
        title: 'Error',
        message: 'Cannot find passenger',
        type: 'error',
        duration: 1500
      })
    },
    throwAddPaxSuccess() {
      ElNotification.closeAll()
      ElNotification({
        title: 'Success',
        message: 'Add passenger successfully',
        type: 'success',
        duration: 3000
      })
    },
    throwMakeSureSelectSameServicePassenger() {
      ElNotification.closeAll()
      ElNotification({
        title: 'Error',
        message:
          'Make sure you have only selected passengers with the same services assigned. Note: maybe your selection still includes passengers that were selected before.',
        type: 'error',
        duration: 3000
      })
    },
    throwTravelAloneError() {
      ElNotification.closeAll()
      ElNotification({
        title: 'Error',
        message: 'You travel alone',
        type: 'error',
        duration: 3000
      })
    },
    throwModifyGroupError() {
      ElNotification.closeAll()
      ElNotification({
        title: 'Error',
        message: 'Cannot separate passenger in group. Because group passenger has issued service',
        type: 'error',
        duration: 3000
      })
    },
    throwFlightNotFound() {
      ElNotification.closeAll()
      ElNotification({
        title: 'Error',
        message: `No passenger flights`,
        type: 'error',
        duration: 3000
      })
    },
    filterPassengersHasMyGroupId(me: any, listGroupPaxs: any[]) {
      // filter passengers in the same group with this is me (myId)
      const newGroup = listGroupPaxs.filter((pax: any) => pax.group_id === me.group_id)
      return newGroup
    },
    formatDate(date: string, format?: string, isIgnoreTimeZone?: boolean) {
      return formatDate(date, format, isIgnoreTimeZone)
    }
  }
})
