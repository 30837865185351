import HotelSharing from '@/views/VoucherSharing/HotelSharing.vue'
import MonetaryCompensationSharing from '@/views/VoucherSharing/MonetaryCompensationSharing.vue'
import RefreshmentSharing from '@/views/VoucherSharing/RefreshmentSharing.vue'
import TaxiSharing from '@/views/VoucherSharing/TaxiSharing.vue'
import authentication from '@/middleware/authentication'
import DefaultLayout from '@/layouts/Default.vue'
import MealPlanSharing from '@/views/VoucherSharing/MealPlanSharing.vue'

export const voucherSharing = {
  path: '/',
  component: DefaultLayout,
  meta: {
    middleware: [authentication]
  },
  children: [
    {
      path: '/hotel-sharing',
      name: 'HotelSharing',
      component: HotelSharing
    },
    {
      path: '/monetary-compensation-sharing',
      name: 'MonetaryCompensationSharing',
      component: MonetaryCompensationSharing
    },
    {
      path: '/refreshment-sharing',
      name: 'RefreshmentSharing',
      component: RefreshmentSharing,
      props: (route) => ({
        refreshmentId: route.query.refreshmentId
      })
    },
    {
      path: '/taxi-sharing',
      name: 'TaxiSharing',
      component: TaxiSharing
    },
    {
      path: '/meal-plan-sharing',
      name: 'MealPlanSharing',
      component: MealPlanSharing
    }
  ]
}
