
import { defineComponent, onBeforeMount, PropType, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import SFSMatrixSelect from '@/components/SFSMatrixSelect.vue'
import StyledSFSMatrixSelectAndSelectBox from '@/styled-components/components/SFSMatrixSelectAndSelectBox'
import LangMixinsVue from '@/views/SearchAndSelectPassengers/Mixin/LangMixins.vue'
import _ from 'lodash'
// import { useRouter } from 'vue-router'
import { isInfant } from '@/utils/passenger'

export default defineComponent({
  name: 'SFSMatrixHotel',
  components: {
    SFSMatrixSelect,
    StyledSFSMatrixSelectAndSelectBox
  },
  mixins: [LangMixinsVue],
  props: {
    responseFetchMatrixHotels: {
      type: Object,
      default: () => ({})
    },
    listPaxs: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    roomSelect: {
      type: Number,
      default: 0
    }
  },
  emits: ['handleChangeRoomSelect', 'getListSelected', 'roomAmountChanged'],
  setup(props, { emit }) {
    const router = useRouter()

    const numberOfHotels = ref(0)

    const selectOptions = ref<number[]>([])

    const currentOption = ref<number>(1)
    watch(currentOption, () => {
      let dataMatrix = _.get(props.responseFetchMatrixHotels, 'data.matrix', {})
      numberOfHotels.value = currentOption.value
      emit('roomAmountChanged', currentOption.value)
      if (props.responseFetchMatrixHotels.success && Object.keys(dataMatrix).length !== 0) {
        return
      }
    })

    watch(
      () => props.roomSelect,
      () => {
        currentOption.value = props.roomSelect
        numberOfHotels.value = props.roomSelect
      }
    )

    watch(
      () => props.responseFetchMatrixHotels,
      () => {
        let dataMatrix = _.get(props.responseFetchMatrixHotels, 'data.matrix', {})
        if (props.responseFetchMatrixHotels.success && Object.keys(dataMatrix).length !== 0) {
          let numberRoom = _.reduce(dataMatrix, (result, value, key) => (key != 0 ? result + value : 0), 0)
          let index = 0
          let indexPax = 0
          for (let key in dataMatrix) {
            // first pax check then start with index = 0
            if (!index) {
              setDataSelected(index, Number(key) * Number(dataMatrix[key]))
            } else {
              // see the location of the next passenger
              // by taking the last position of the passenger in the first room
              indexPax = Number(Object.keys(dataMatrix)[index - 1]) * Number(Object.values(dataMatrix)[index - 1])
              for (let i = 0; i < Number(dataMatrix[key]); i++) {
                // if i > 0 -> update indexPax
                if (i) {
                  indexPax = indexPax + Number(key)
                }
                setDataSelected(indexPax, Number(key))
              }
            }
            index++
          }
          emit('handleChangeRoomSelect', { number: numberRoom })
          emit('getListSelected', matrix)
          return
        }
        console.log('SFSMatrixHotel/watch')
        router.push({ path: '/no-hotels-available' })
      }
    )
    const setDataSelected = (index, length) => {
      for (let i = 0; i < length; i++) {
        if (!index) {
          matrix[index + i] = 1
          continue
        }
        matrix[index + i] = matrix[index - 1] + 1
      }
    }

    const matrix = reactive<number[]>([])
    let minIndex = 1
    const updateMatrix = () => {
      _.forEach(props.listPaxs, (value, index) => {
        matrix[index] = value.room
      })
    }
    watch(matrix, () => {
      let min = 99999
      //find position min room number from room assigned
      _.forEach(matrix, (value, index) => {
        if (value < min && !isInfant(props.listPaxs[index])) {
          min = value
        }
      })
      if (min !== 99999) {
        minIndex = min
      }
      _.forEach(props.listPaxs, (pax, index) => {
        if (isInfant(pax)) {
          matrix[index] = minIndex
        }
      })

      emit('getListSelected', matrix)
    })

    watch(
      () => props.listPaxs,
      () => {
        updateMatrix()
      }
    )

    const initData = () => {
      currentOption.value = props.listPaxs.length
      numberOfHotels.value = currentOption.value
      selectOptions.value = _.range(1, props.listPaxs.length + 1)

      updateMatrix()
    }

    onBeforeMount(() => {
      const mountedInterval = setInterval(() => {
        if (!_.isNil(props.listPaxs)) {
          initData()
          clearInterval(mountedInterval)
        }
      }, 500)
    })

    return { numberOfHotels, selectOptions, currentOption, matrix }
  }
})
