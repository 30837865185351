export const TAXI_WAY_OPTION = {
  ONE_WAY: 1,
  TWO_WAY: 2
}

export const OPTION_TAXI = {
  ONE_AIRPORT_HOTEL: 1,
  TWO_AIRPORT_HOTEL: 2,
  ONE_HOTEL_AIRPORT: 3,
  AIRPORT_ADDRESS: 4
}

export const TAXI_CONFIG = {
  TAXI2RIDE: 'taxi2ride',
  PHOTO2CLAIM: 'photo2claim'
}
