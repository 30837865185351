export const isEmtpy = (value: any): boolean => {
  if (value) {
    if (value.constructor === Object) {
      return Object.keys(value).length === 0
    }
    if (value.constructor === Array) {
      return value.length === 0
    }
    return false
  } else return false
}

export const isJsonString = (value: any): boolean => {
  try {
    JSON.parse(value)
  } catch (e) {
    return false
  }
  return true
}
