import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "confirm-label" }
const _hoisted_2 = { class: "passenger-name" }
const _hoisted_3 = { class: "passenger-name-child" }
const _hoisted_4 = { class: "text-bold" }
const _hoisted_5 = { class: "text-bold" }
const _hoisted_6 = { class: "text-bold" }
const _hoisted_7 = { class: "this-is-me" }
const _hoisted_8 = ["value", "checked"]
const _hoisted_9 = { class: "btn__container" }
const _hoisted_10 = { class: "btn btn-confirm" }
const _hoisted_11 = { class: "btn btn-back" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElFormItem = _resolveComponent("ElFormItem")!
  const _component_ElForm = _resolveComponent("ElForm")!
  const _component_StyledConfirmThisIsMe = _resolveComponent("StyledConfirmThisIsMe")!

  return (_openBlock(), _createBlock(_component_StyledConfirmThisIsMe, null, {
    default: _withCtx(() => [
      _createVNode(_component_ElForm, {
        ref: "form",
        model: _ctx.confirmThisIsMeForm,
        rules: _ctx.confirmThisIsMeRules,
        "show-message": false,
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", null, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_25), 1)
          ]),
          _createVNode(_component_ElFormItem, {
            prop: "mySelectedId",
            class: "ws-textbox"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listGroupPaxs, (pax, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "passenger-detail"
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", null, [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(pax.first_name + ' ' + pax.last_name), 1)
                      ])
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("div", null, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_29) + " ", 1),
                        _createElementVNode("span", null, _toDisplayString(pax.pnr), 1)
                      ])
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("div", null, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_32) + " ", 1),
                        _createElementVNode("span", null, _toDisplayString(_ctx.flightInfo.flight_number), 1)
                      ])
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_33) + " ", 1),
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.flightInfo.dep), 1),
                      _createElementVNode("span", null, " " + _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_34) + " ", 1),
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.flightInfo.div), 1)
                    ])
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", null, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_26), 1)
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.confirmThisIsMeForm.mySelectedId) = $event)),
                        type: "radio",
                        value: pax.id,
                        checked: _ctx.confirmThisIsMeForm.mySelectedId == pax.id
                      }, null, 8, _hoisted_8), [
                        [_vModelRadio, _ctx.confirmThisIsMeForm.mySelectedId]
                      ])
                    ])
                  ])
                ]))
              }), 128)),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("button", {
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleConfirm && _ctx.handleConfirm(...args)))
                  }, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_19), 1)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("button", {
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.backStep && _ctx.backStep(...args)))
                  }, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_20), 1)
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }))
}