import styled from 'vue3-styled-components'

const StyledNavbar = styled.section`
  .navbar__container {
    min-height: 60px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    max-width: 1200px;

    .header_wrapper {
      margin: 0 auto;
      background-color: #ffffff;
      padding: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-inline: 26px;
      .airline-logo {
        max-height: 60px;
        max-width: 50%;
        img {
          max-height: 60px;
          cursor: pointer;
          max-width: 100%;
        }
      }
    }
  }
`

export default StyledNavbar
