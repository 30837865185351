import { LoginResponse } from '@/types/user'
import { HTTPBaseService } from '@/utils/request'

export default class Auth extends HTTPBaseService {
  constructor() {
    super('station-handler')
  }

  public async loginWithUsernameAndPassword(username: string, password: string): Promise<LoginResponse> {
    const { data } = await this.instance.post('account/auth', { username, password })
    return data
  }

  public async loginWithShortCode(shortCode: string): Promise<LoginResponse> {
    const { data } = await this.instance.post('account/login-with-shortcode', { shortCode })
    return data
  }
}
