
import _ from 'lodash'
import StyledSFSTitle from '@/styled-components/components/SFSTitle'

export default {
  name: 'SFSTitle',
  components: { StyledSFSTitle },
  props: {
    titleName: {
      type: String,
      default: ''
    },
    titleLink: {
      type: String,
      default: ''
    },
    namePage: {
      type: String,
      default: ''
    },
    paxVoucherId: {
      type: [Number, String],
      default: ''
    },
    step: {
      type: String,
      default: ''
    }
  }
}
