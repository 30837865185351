import { VoucherType } from '@/types/voucher'
import Vuex, { Store } from 'vuex'
import { useAccessor, mutationTree, actionTree } from 'typed-vuex'

import * as user from './modules/user'
import * as groupPax from './modules/groupPax'
import * as selectRoom from './modules/selectRoom'
import * as taxi from './modules/taxi'
import * as hotel from './modules/hotel'
import * as mealPlan from './modules/mealPlan'
import * as passenger from './modules/passenger'
import { setGmapApi } from '@/utils/gmap'
import { StoreType } from '@/types/store'
import * as lang from './modules/lang'

export const state = () =>
  ({
    loading: 0,
    airline: undefined,
    flightInfo: undefined,
    flightLegs: undefined,
    overnightStation: undefined,
    vouchers: [] as VoucherType[]
  } as StoreType)

type RootState = ReturnType<typeof state>

export const mutations = mutationTree(state, {
  incrementLoading(state) {
    state.loading = state.loading + 1
  },
  decrementLoading(state) {
    state.loading = state.loading - 1
  },
  setAirline(state, airline) {
    state.airline = airline

    // load gmap api
    setGmapApi(airline.google_map_key)
  },
  setFlightInfo(state, flightInfo) {
    state.flightInfo = flightInfo
  },
  setFlightLegs(state, flightLegs) {
    state.flightLegs = flightLegs
  },
  setOvernightStation(state, overnightStation) {
    state.overnightStation = overnightStation
  },
  setVouchers(state: RootState, vouchers: VoucherType[]) {
    state.vouchers = vouchers
  }
})

export const actions = actionTree(
  { state, mutations },
  {
    async incrementLoading({ commit }) {
      commit('incrementLoading')
    },
    async decrementLoading({ commit }) {
      commit('decrementLoading')
    },
    async setAirline({ commit }, airline: any) {
      airline.params = JSON.parse(airline.params)
      commit('setAirline', airline)
    },
    async setFlightInfo({ commit }, flightInfo: any) {
      commit('setFlightInfo', flightInfo)
    },
    async setFlightLegs({ commit }, flightLegs: any) {
      commit('setFlightLegs', flightLegs)
    },
    async setOvernightStation({ commit }, overnightStation: any) {
      commit('setOvernightStation', overnightStation)
    },
    setVouchers({ commit }, vouchers: any[]) {
      commit('setVouchers', vouchers)
    }
  }
)

export const storePattern = {
  state,
  mutations,
  actions,
  modules: {
    user: { namespaced: true, ...user },
    groupPax: { namespaced: true, ...groupPax },
    selectRoom: { namespaced: true, ...selectRoom },
    taxi: { namespaced: true, ...taxi },
    hotel: { namespaced: true, ...hotel },
    mealPlan: { namespaced: true, ...mealPlan },
    passenger: { namespaced: true, ...passenger },
    lang: { namespaced: true, ...lang }
  },
  namespaced: true,
  devtools: true
}

const store: Store<StoreType> = new Vuex.Store(storePattern)
export const accessor = useAccessor(store, storePattern)

export default store
