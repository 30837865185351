import SearchAndSelectPassengers from '@/views/SearchAndSelectPassengers/Index.vue'
import authentication from '@/middleware/authentication'
import DefaultLayout from '@/layouts/Default.vue'

export const searchAndSelectPassengers = {
  path: '/',
  icon: 'ico_home.svg',
  menuName: 'SearchAndSelectPassengers',
  component: DefaultLayout,
  meta: {
    middleware: [authentication],
    scope: 'main'
  },
  children: [
    {
      path: '/',
      name: 'SearchAndSelectPassengers',
      component: SearchAndSelectPassengers
    }
  ]
}
