
import ItemOfVouchers from '@/components/ItemOfVoucher/Index.vue'
import Title from '@/components/SFSTitle.vue'
import StyledNoHotelsAvailable from '@/styled-components/pages/no-hotels-available'
import LangMixinsVue from '@/views/SearchAndSelectPassengers/Mixin/LangMixins.vue'
import { StoreType } from '@/types/store'
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { publicPath } from '../../../vue.config'

export default defineComponent({
  name: 'NoHotelsAvailable',
  components: { Title, ItemOfVouchers, StyledNoHotelsAvailable },
  mixins: [LangMixinsVue],
  props: {
    secret: {
      type: String,
      default: ''
    },
    hasBookedFailed: {
      type: Boolean
    }
  },
  setup() {
    const store = useStore<StoreType>()
    const paxsGroup = computed(() => store.getters['groupPax/listGroupPax'])

    return {
      pax: computed(() => store.state.groupPax.me),
      availableChangeRoomAllocation: computed(() => paxsGroup.value.length > 1)
    }
  },
  data() {
    return {
      progressBarWidth: 10,
      idIntervalProgressBar: 0, //TODO: check can 0 or null
      idIntervalLoadHotels: 0,
      timeRefeshPage: 300000,
      publicPath
    }
  },
  watch: {
    pax: {
      handler: function () {
        this.onRunLoadHotels()
        this.onRunProgressBar()
      },
      deep: true
    }
  },
  mounted() {
    this.onRunProgressBar()
    this.onRunLoadHotels()
  },

  beforeUnmount() {
    clearInterval(this.idIntervalProgressBar)
    clearInterval(this.idIntervalLoadHotels)
  },

  methods: {
    onRunProgressBar() {
      if (this.pax?.diff_day === 1) {
        clearInterval(this.idIntervalProgressBar)
        const self = this
        this.idIntervalProgressBar = setInterval(function () {
          if (self.progressBarWidth >= 100) {
            self.progressBarWidth = 0
          }
          self.progressBarWidth = self.progressBarWidth + 1
        }, 50)
      }
    },

    onRunLoadHotels() {
      if (this.pax?.diff_day === 1) {
        clearInterval(this.idIntervalLoadHotels)
        const self = this
        this.idIntervalLoadHotels = setInterval(function () {
          self.$router.push({
            path: '/hotel-list'
          })
        }, self.timeRefeshPage)
      }
    },

    handleChangeRoomAllocation() {
      this.$router.push({ path: '/hotel-book' })
    }
  }
})
