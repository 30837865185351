
import Passenger from '@/models/Passenger'
import { computed, defineComponent, PropType } from 'vue'
import { useRouter } from 'vue-router'
import StyledSelectPaxTraveling from '@/styled-components/pages/combine-voucher-2/selectPaxTraveling'
import ItemOfVoucher from '@/components/ItemOfVoucher/Index.vue'
import { PassengerType } from '@/types/passenger'
import CombineVoucherMixinVue from './Mixin/CombineVoucherMixin.vue'
import store from '@/store'
import LangMixinsVue from '../SearchAndSelectPassengers/Mixin/LangMixins.vue'
import { ElNotification } from 'element-plus'
import { StoreType } from '@/types/store'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'SelectPaxTraveling',
  components: {
    StyledSelectPaxTraveling,
    ItemOfVoucher
  },
  mixins: [CombineVoucherMixinVue, LangMixinsVue],
  props: {
    listGroupPaxs: {
      type: Array as PropType<PassengerType[]>,
      default: () => []
    },
    myId: {
      type: Number,
      require: true,
      default: undefined
    },
    listSelectedGroupPaxIds: {
      type: Array,
      default: () => []
    }
  },
  emits: ['updateStep'],
  setup() {
    const store = useStore<StoreType>()
    return {
      listMyGroupPaxs: computed(() => store.getters['groupPax/listGroupPax'])
    }
  },
  data() {
    let listGroupPaxsExcludeMe: any[] = []
    if (this.listSelectedGroupPaxIds.length > 0) {
      listGroupPaxsExcludeMe = this.listGroupPaxs.filter(
        (pax: any) => this.listSelectedGroupPaxIds.includes(pax.id) && pax.id != this.myId
      )
    } else {
      listGroupPaxsExcludeMe = this.listGroupPaxExcludeMe(this.listGroupPaxs, this.myId)
    }
    const listNewGroupPaxsExcludeMeIds = listGroupPaxsExcludeMe.map((pax) => pax.id)
    return {
      router: useRouter(),
      listGroupPaxsExcludeMe,
      listNewGroupPaxsExcludeMeIds
    }
  },

  methods: {
    backStep() {
      this.$emit('updateStep', 'addMorePax')
    },
    async handleConfirm() {
      const passenger = new Passenger()

      const listNewGroupPaxsIncludeMeIds = [...this.listNewGroupPaxsExcludeMeIds, this.myId]

      ElNotification.closeAll()
      if (listNewGroupPaxsIncludeMeIds.length <= 1) {
        ElNotification({
          title: 'Error',
          message: 'Please select at least one passenger',
          type: 'error',
          duration: 3000
        })
        return
      }

      const listMyGroupPaxIds = this.listMyGroupPaxs.map((p) => p.id)

      const isMyGroupIssued = this.checkListGroupPaxsHasIssuedService(this.listMyGroupPaxs)

      const isNewSelectedPaxIssued = this.checkListGroupPaxsHasIssuedService(
        this.listGroupPaxs.filter((p) => this.listNewGroupPaxsExcludeMeIds.includes(p.id))
      )

      const isPaxsExcludeMyGroupIssued = this.checkListGroupPaxsHasIssuedService(
        this.listGroupPaxs
          .filter((p) => this.listNewGroupPaxsExcludeMeIds.includes(p.id))
          .filter((p) => listMyGroupPaxIds.includes(p.id))
      )

      // All below cases are produced by tester Nguyen Yen Nga Tran

      // Case block when user combine pax issued and not issued yet (my group has issued and new group pax has more ids or less than my group)
      if (
        isNewSelectedPaxIssued &&
        isMyGroupIssued &&
        !listNewGroupPaxsIncludeMeIds.every((id) => listMyGroupPaxIds.includes(id))
      ) {
        this.throwMustSelectAllMemberInGroupIssuedWarning()
        return
      }

      // Case block when user combine pax issued and not issued yet (my group hasnot issued and new group pax has more ids or less than my group)
      if (
        isNewSelectedPaxIssued &&
        !isMyGroupIssued &&
        !listNewGroupPaxsIncludeMeIds.every((id) => listMyGroupPaxIds.includes(id))
      ) {
        this.throwMakeSureSelectSameServicePassenger()
        return
      }

      // Case block when user combine pax issued and not issued yet (my group hasnot issued and paxs exclude my group has issued)
      if (!isPaxsExcludeMyGroupIssued && isMyGroupIssued) {
        this.throwMustSelectAllMemberInGroupIssuedWarning()
        return
      }

      // Case uncheck all other group's paxs
      if (
        this.checkListGroupPaxsHasIssuedService(this.listGroupPaxs) &&
        this.listMyGroupPaxs.every((pax) => listNewGroupPaxsIncludeMeIds.includes(pax.id)) &&
        listNewGroupPaxsIncludeMeIds.every((id) => listMyGroupPaxIds.includes(id))
      ) {
        this.router.push({ name: 'Home' })
        return
      }
      const { success } = await passenger.modifyGroupPax({
        passenger_id: store.state.groupPax.me?.passenger_id ?? 0,
        flight_id: store.state.flightInfo?.flight_id,
        passengers: this.listNewGroupPaxsExcludeMeIds.map((id) => {
          const pax = this.listGroupPaxsExcludeMe.find((pax) => pax.id == id)
          return { pnr: pax.pnr ?? '', first_name: pax.first_name ?? '', last_name: pax.last_name ?? '' }
        })
      })
      if (success) {
        this.router.push({ name: 'Home' })
      }
    }
  }
})
