import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mt-50" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "ws-textbox mt-4" }
const _hoisted_4 = { class: "title mb-3" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "group-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemOfVoucher = _resolveComponent("ItemOfVoucher")!
  const _component_SFSTravelersAndServiceInGroup = _resolveComponent("SFSTravelersAndServiceInGroup")!
  const _component_StyledVoucherSharing = _resolveComponent("StyledVoucherSharing")!

  return (_openBlock(), _createBlock(_component_StyledVoucherSharing, null, {
    default: _withCtx(() => [
      _createVNode(_component_ItemOfVoucher, {
        label: _ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_1,
        color: "#f0725e",
        icon: "images/icon-voucher/hotel-voucher.png"
      }, null, 8, ["label"]),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.listMyGroupPaxs.length > 0)
          ? (_openBlock(), _createBlock(_component_SFSTravelersAndServiceInGroup, {
              key: 0,
              label: _ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_10,
              "list-pax": _ctx.listMyGroupPaxs
            }, null, 8, ["label", "list-pax"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isHaveMealPlan && !_ctx.isNotShowMealPlanService)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ItemOfVoucher, {
              label: _ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_32,
              color: "#f5b945",
              class: "mt-4",
              icon: "images/icon-voucher/meal-plan-voucher.png"
            }, null, 8, ["label"]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createTextVNode(_toDisplayString(_ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_11) + " ", 1),
                (_ctx.listMyGroupPaxs.length > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_12), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_13), 1))
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listMealPlan, (quantity, nameMeal) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: nameMeal,
                  class: "list-meal-item"
                }, [
                  _createElementVNode("span", null, _toDisplayString(quantity) + " " + _toDisplayString(nameMeal), 1)
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("button", {
          class: "btn-action",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backToHomePage()))
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_17), 1)
        ])
      ])
    ]),
    _: 1
  }))
}