import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { class: "my-4" }
const _hoisted_3 = { class: "my-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_ItemOfVouchers = _resolveComponent("ItemOfVouchers")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_HotelCard = _resolveComponent("HotelCard")!
  const _component_StyledSFSHotelList = _resolveComponent("StyledSFSHotelList")!

  return (_openBlock(), _createBlock(_component_StyledSFSHotelList, null, {
    default: _withCtx(() => [
      _createVNode(_component_Title, {
        "title-name": _ctx.groundHandlerMessage?.hotel?.SFS_LANG_ROW_44 || 'Home',
        "title-link": "Home"
      }, null, 8, ["title-name"]),
      _createElementVNode("section", null, [
        (!_ctx.hasCancelHotelVoucher)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ItemOfVouchers, {
                  icon: "images/icon-voucher/hotel-voucher.png",
                  color: "#f0725e",
                  label: _ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_1,
                  border: ""
                }, null, 8, ["label"])
              ]),
              _createVNode(_component_el_card, { class: "box-card select-hotel" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_28), 1)
                ]),
                _: 1
              }),
              (_ctx.availableChangeRoomAllocation)
                ? (_openBlock(), _createBlock(_component_el_card, {
                    key: 0,
                    class: "box-card change-room-allocation mt-4",
                    onClick: _ctx.handleChangeRoomAllocation
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.groundHandlerMessage?.hotel.SFS_LANG_ROW_41 || 'Change Room allocation'), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataListHotel, (hotel, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "mb-2 wrap-hotel-card"
                  }, [
                    _createVNode(_component_HotelCard, {
                      "hotel-info": hotel,
                      onHandleBookHotel: _ctx.handleBookHotel
                    }, null, 8, ["hotel-info", "onHandleBookHotel"])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}