import styled from 'vue3-styled-components'

const SFSSelectRoom = styled.section`
  .group-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 32px;

    .btn-action {
      width: 162px;
      height: 44px;
      border-radius: 17px;
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
      background-color: #ffffff;
      border: 0px;
      display: block;
      margin-top: 30px;
      span {
        font-size: 16px;
        letter-spacing: 0px;
        color: #f5d32a;
        font-weight: 500;
      }
    }

    .btn-action:hover {
      background-color: #0094d5;
      border: 0px;
      display: block;
      span {
        color: #f5d32a;
        font-weight: 500;
      }
    }
  }
  .truncate-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .content span {
    line-height: 26pt;
  }
  .shuttle-bus-infor .prefix {
    margin-bottom: 10px;
  }
  .prefix {
    white-space: nowrap;
  }
  .at-time {
    margin: 0 0.25rem;
  }
  body:dir(rtl) {
    .prefix {
      margin-left: 0.25rem;
    }
    .date {
      direction: ltr;
    }
  }
`

export default SFSSelectRoom
