import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-header" }
const _hoisted_2 = { class: "header-wrapper p-0" }
const _hoisted_3 = {
  key: 0,
  class: "page-title-link"
}
const _hoisted_4 = { class: "back-to" }
const _hoisted_5 = {
  class: "col-10",
  style: {"text-transform":"capitalize"}
}
const _hoisted_6 = { class: "back-to" }
const _hoisted_7 = { style: {"text-transform":"capitalize","font-weight":"400"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowLeftBold = _resolveComponent("ArrowLeftBold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_StyledSFSTitle = _resolveComponent("StyledSFSTitle")!

  return (_openBlock(), _createBlock(_component_StyledSFSTitle, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          ($props.titleName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                ($props.namePage == 'hotelTransport')
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: { name: $props.titleLink, query: { pax_voucher_id: $props.paxVoucherId } },
                      class: "logo-img"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("h1", _hoisted_5, [
                            _createVNode(_component_el_icon, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ArrowLeftBold)
                              ]),
                              _: 1
                            }),
                            _createTextVNode(" " + _toDisplayString($props.titleName), 1)
                          ])
                        ])
                      ]),
                      _: 1
                    }, 8, ["to"]))
                  : (_openBlock(), _createBlock(_component_router_link, {
                      key: 1,
                      to: { name: $props.titleLink },
                      class: "logo-img"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("h1", _hoisted_7, [
                            _createVNode(_component_el_icon, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ArrowLeftBold)
                              ]),
                              _: 1
                            }),
                            _createTextVNode(" " + _toDisplayString($props.titleName), 1)
                          ])
                        ])
                      ]),
                      _: 1
                    }, 8, ["to"]))
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}