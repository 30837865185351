import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "label-radio" }
const _hoisted_3 = ["value", "checked", "disabled"]
const _hoisted_4 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StyledSFSRadio = _resolveComponent("StyledSFSRadio")!

  return (_openBlock(), _createBlock(_component_StyledSFSRadio, null, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.prop ? 'el-form-item' : 'div'), {
        prop: _ctx.prop,
        class: "content-input"
      }, {
        default: _withCtx(() => [
          (_ctx.label)
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.label), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.value,
              class: _normalizeClass(["my-radio-button-style2", _ctx.radioClass])
            }, [
              _createElementVNode("label", _hoisted_2, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
                  type: "radio",
                  value: item.value,
                  checked: _ctx.inputValue == item.value,
                  disabled: _ctx.disabled
                }, null, 8, _hoisted_3), [
                  [_vModelRadio, _ctx.inputValue]
                ]),
                _createElementVNode("span", _hoisted_4, _toDisplayString(item.label), 1)
              ])
            ], 2))
          }), 128))
        ]),
        _: 1
      }, 8, ["prop"]))
    ]),
    _: 1
  }))
}