import { PrintVouchersOnA4Body, UpdateStatusVoucherBody, UpdatePrintVoucherBody } from '@/types/sfsApiPrintVouchersOnA4'
import { HTTPBaseService } from '@/utils/request'
import { BaseResponse } from './../types/global'

export default class SfsApiPrintVouchersOnA4 extends HTTPBaseService {
  constructor() {
    super('sfs-api')
  }

  public async printVouchersOnA4(body: PrintVouchersOnA4Body): Promise<BaseResponse> {
    const { data } = await this.instance.post(`vouchers/printVouchersOnA4`, body)
    return data
  }

  public async updatePrintVoucher(body: UpdatePrintVoucherBody): Promise<BaseResponse> {
    const { data } = await this.instance.post(`vouchers/update-print-voucher`, body)
    return data
  }

  public async updateStatusVoucher(body: UpdateStatusVoucherBody): Promise<BaseResponse> {
    const { data } = await this.instance.post(`passengers/updateStatusVoucher`, body)
    return data
  }
}
