import styled from 'vue3-styled-components'

const StyledVoucherSharing = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Roboto';

  .ws-textbox {
    padding: 1rem;
    letter-spacing: 0.004375rem;
    background-color: #ffffff;
    border-radius: 0.75rem;
    border: none;
    -webkit-box-shadow: 0 0.1875rem 0.375rem #85858529;
    box-shadow: 0 0.1875rem 0.375rem #85858529;
    outline: none;
    line-height: 1.777777778;
  }

  .total-price {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .space {
    margin-bottom: 20px;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .group-button {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-action {
      width: 162px;
      height: 44px;
      border-radius: 17px;
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
      background-color: #ffffff;
      border: 0px;
      display: block;
      margin-top: 30px;
      span {
        font-size: 16px;
        letter-spacing: 0px;
        color: #f5d32a;
        font-weight: 500;
      }
    }

    .btn-action:hover {
      background-color: #0094d5;
      border: 0px;
      display: block;
      span {
        color: #f5d32a;
        font-weight: 500;
      }
    }
  }
  $colorBlue: #37c5e7;
  .meal-plan {
    .ws-textbox__meal-plan {
      position: relative;
      .meal-plan-box--used::after {
        position: absolute;
        content: 'Used';
        top: 0;
        right: 0;
        width: 60px;
        height: 32px;
        background-color: #37c5e7;
        color: white;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--meal-plan-box-border-radius);
      }
      .meal-plan-box--expired::after {
        position: absolute;
        content: 'Expired';
        top: 0;
        right: 0;
        width: 60px;
        height: 32px;
        background-color: orange;
        color: white;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--meal-plan-box-border-radius);
      }
    }

    .icon-clock-wrapper i {
      font-size: 20px;
      color: #37c5e7;
    }

    .icon-money-wrapper i {
      font-size: 16px;
      color: #ffb200;
    }

    .click-here {
      font-size: 14px;
      color: #414141;
      text-decoration: underline;
    }
  }
  .pl-20 {
    padding-left: 22px;
  }
`

export default StyledVoucherSharing
