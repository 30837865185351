
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import StyledVoucherSharing from '@/styled-components/pages/voucher-sharing'
import ItemOfVoucher from '@/components/ItemOfVoucher/Index.vue'
import SFSTravelersAndServiceInGroup from '@/components/SFSTravelersAndServiceInGroup.vue'
import { StoreType } from '@/types/store'
import LangMixinsVue from '../SearchAndSelectPassengers/Mixin/LangMixins.vue'

export default defineComponent({
  name: 'TaxiSharing',
  components: {
    StyledVoucherSharing,
    ItemOfVoucher,
    SFSTravelersAndServiceInGroup
  },
  mixins: [LangMixinsVue],
  setup() {
    const store = useStore<StoreType>()
    const router = useRouter()

    return {
      listMyGroupPaxs: computed(() => store.getters['groupPax/listGroupPax']),
      router
    }
  },
  methods: {
    backToHomePage() {
      this.router.push({ name: 'Home' })
    }
  }
})
