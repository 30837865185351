import styled from 'vue3-styled-components'

const StyledConfirmThisIsMe = styled.section`
  .el-form {
    input[type='radio']:checked {
      box-shadow: 0 0 0 2px #00c365;
    }

    input[type='radio']:before {
      content: '';
      display: block;
      width: 60%;
      height: 60%;
      margin: 20% auto;
      border-radius: 50%;
    }

    input[type='radio']:checked:before {
      background: #00c365;
    }

    input[type='radio'] {
      -webkit-appearance: none;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      outline: none;
      box-shadow: 0 0 0 2px gray;
      margin-top: 0.8rem;
    }
    .confirm-label {
      margin: 30px 0px 30px;
    }

    .passenger-detail {
      margin: 30px 0px 0px;
      display: flex;
      justify-content: space-evenly;

      div {
        flex: 1 0 auto;

        .this-is-me {
          padding: 25px 0 25px 25px;
          text-align: center;
        }
      }
      .passenger-name {
        max-width: 170px;
        .passenger-child-name {
          width: 100%;
        }
      }
    }
  }
`

export default StyledConfirmThisIsMe
