import styled from 'vue3-styled-components'

const SFSDate = styled.section`
  .el-input {
    width: 100% !important;
  }
  .el-input__icon {
    line-height: 50px;
  }
`

export default SFSDate
