
import StyledDateRange from '@/styled-components/components/SFSDateRange'

import { defineComponent, ref, watch, PropType } from 'vue'

export default defineComponent({
  components: {
    StyledDateRange
  },
  props: {
    placeholder: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    modelValue: {
      type: Array as PropType<string[]>,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  emits: ['handleChange', 'update:modelValue'],
  setup(props, { emit }) {
    const dateRange = ref<string[]>(props.modelValue)

    const handleChange = (value) => emit('handleChange', value)

    watch(dateRange, (value) => emit('update:modelValue', value))
    watch(
      () => props.modelValue,
      (value) => (dateRange.value = value)
    )

    return { dateRange, handleChange }
  }
})
