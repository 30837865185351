import { mutationTree, actionTree } from 'typed-vuex'
import MealPlan from '@/models/MealPlan'

type RootState = ReturnType<typeof state>

interface StateType {
  listMealPlanInfo: any
}

export const state = () =>
  ({
    listMealPlanInfo: {}
  } as StateType)

export const mutations = mutationTree(state, {
  setListMealPlanVoucher(state: RootState, listMealPlanInfo: any) {
    state.listMealPlanInfo = listMealPlanInfo
  }
})

export const actions = actionTree(
  { state, mutations },
  {
    async fetchMealPlanVoucher({ commit }, context) {
      let response = await new MealPlan('sfs-api').fetchMealPlanVoucher(context.passenger_id)
      if (response.success) {
        commit('setListMealPlanVoucher', response.data)
      }
    }
  }
)
