import TaxiService from '@/views/TaxiService/Index.vue'
import authentication from '@/middleware/authentication'
import DefaultLayout from '@/layouts/Default.vue'

export const taxi = {
  path: '/',
  component: DefaultLayout,
  meta: {
    middleware: [authentication]
  },
  children: [
    {
      path: '/taxi-service',
      name: 'Taxi Service',
      component: TaxiService
    }
  ]
}
