
import Passenger from '@/models/Passenger'
import store from '@/store'
import { getUser } from '@/utils/auth'
import Voucher from '@/models/Voucher'
import Taxi from '@/models/Taxi'
import Airline from '@/models/Airline'
import _ from 'lodash'
import { isJson } from '@/utils/string'

export default {
  methods: {
    getCurrentPassengerId() {
      return parseInt((this as any).$route.query?.passenger_id ?? store.state['groupPax']?.me?.passenger_id)
    },
    async fetchAirline() {
      if (_.isEmpty(store.state.airline)) {
        const user = getUser()
        if (user) {
          const { data: airline } = await new Airline().getAirlineDetail({
            airline_id: user.airline.id
          })
          airline.templateConfig = isJson(airline.templateConfig) ? JSON.parse(airline.templateConfig) : airline.templateConfig
          store.dispatch('setAirline', airline)
        }
      }
    },
    async fetchAll() {
      const currentPaxId = this.getCurrentPassengerId()
      const user = getUser()
      if (!user || !currentPaxId) return

      const passenger = new Passenger()
      const { data: listGroupPaxs } = await passenger.getPaxsGroup({ paxId: currentPaxId })

      store.dispatch(
        'groupPax/setListGroupPaxExcludeMe',
        listGroupPaxs.filter((pax) => pax.passenger_id != currentPaxId)
      )
      const me = listGroupPaxs.find((pax) => pax.passenger_id == currentPaxId)
      store.dispatch('groupPax/setMe', me)

      let userMe = store.state.groupPax.me
      let paramString = userMe?.airline_id + '_' + userMe?.overnight_station + '_' + userMe?.passenger_id
      let response = await new Passenger('sfs-api').fetchPax(paramString)
      if (response.success) {
        store.dispatch('passenger/setPassengerDetail', response.data)
      }
    },
    async fetchOvernightStation() {
      const passenger = new Passenger()
      const user = getUser()
      const paxId = this.getCurrentPassengerId()
      if (!user || !paxId) return
      const airlineId = user.airline.id
      const { data: overnightStation } = await passenger.getOvernightStation(paxId, airlineId)
      store.dispatch('setOvernightStation', overnightStation)
    },
    async fetchVouchers() {
      const user = getUser()
      const paxId = this.getCurrentPassengerId()
      if (!user || !paxId) return
      const { data: vouchers } = await new Voucher().getListVoucherDetail({
        airline_id: user.airline.id,
        paxId
      })
      store.dispatch('setVouchers', vouchers)
    },
    async fetchDetailTaxi() {
      const taxi = new Taxi()
      const paxId = this.getCurrentPassengerId()
      if (!paxId) return
      const { data: getDetailTaxi } = await taxi.getDetailTaxiVoucher(paxId)
      store.dispatch('taxi/setDetailVouchers', getDetailTaxi)
    },
    fetchVoucherHotel() {
      const pax_voucher_id = (this as any).$route.query.pax_voucher_id
      const passenger_id = (this as any).$route.query.passenger_id
      const reservation_id = (this as any).$route.query.reservation_id
      const voucher_id = (this as any).$route.query.voucher_id
      if (!pax_voucher_id && !passenger_id) return
      store.dispatch('selectRoom/fetchVoucherHotel', {
        passenger_id: [pax_voucher_id ?? passenger_id],
        reservation_id,
        voucher_id
      })
    },
    async fetchMealPlanVoucher() {
      const passenger_id = (this as any).$route.query.passenger_id
      if (!passenger_id) return
      await store.dispatch('mealPlan/fetchMealPlanVoucher', {
        passenger_id
      })
    },
    async fetchFlightInfo() {
      if (!store.state.groupPax.me) return
      const passenger = new Passenger()
      const { data: flightInfo } = await passenger.getFlightInfo(store.state.groupPax.me.fltref ?? '')
      store.dispatch('setFlightInfo', flightInfo)
    }
  }
}
