export const COMBINE_VOUCHER_RULES = {
  checkboxSelectedPaxIds: [
    {
      type: 'array',
      required: true,
      message: '',
      trigger: 'change'
    }
  ]
}

export const SEARCH_FORM_RULES = {
  pnr: [{ required: true, message: 'This field is required' }],
  firstName: [{ required: true, message: 'This field is required' }],
  lastName: [{ required: true, message: 'This field is required' }]
}
