
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { StoreType } from '@/types/store'
import LangMixinsVue from '../../SearchAndSelectPassengers/Mixin/LangMixins.vue'

export default defineComponent({
  name: 'ChangePassword',
  mixins: [LangMixinsVue],
  props: {
    taxiData: {
      type: Object,
      default: () => {}
    },
    taxiLanguage: {
      type: Object,
      default: () => {}
    },
    showSecondLanguage: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const airlineIconColor = computed(() => {
      const store = useStore<StoreType>()
      return store.state?.airline?.templateConfig?.icon_color || '#05164d'
    })

    const iconStyle = computed(() => {
      return {
        color: airlineIconColor.value,
        boxShadow: '0 0 2px ' + airlineIconColor.value
      }
    })

    const colorStyle = computed(() => {
      return {
        color: airlineIconColor.value
      }
    })

    return {
      iconStyle,
      colorStyle
    }
  },
  methods: {
    languageReplace(fullLang, textReplaced) {
      let re = new RegExp(Object.keys(textReplaced).join('|'), 'gi')
      return fullLang?.replace(re, function (matched) {
        return textReplaced[matched]
      })
    }
  }
})
