import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_StyledSFSDate = _resolveComponent("StyledSFSDate")!

  return (_openBlock(), _createBlock(_component_StyledSFSDate, null, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.prop ? 'el-form-item' : 'div'), {
        prop: _ctx.prop,
        class: "content-input"
      }, {
        default: _withCtx(() => [
          (_ctx.label)
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.label), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_el_date_picker, {
            modelValue: _ctx.inputValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
            format: "MM-DD-YYYY",
            "value-format": "YYYY-MM-DD",
            placeholder: _ctx.placeholder,
            "disabled-date": _ctx.disabledDate,
            onChange: _ctx.handleChange
          }, null, 8, ["modelValue", "placeholder", "disabled-date", "onChange"])
        ]),
        _: 1
      }, 8, ["prop"]))
    ]),
    _: 1
  }))
}