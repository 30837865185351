import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "item-of-vouchers__wrapper" }
const _hoisted_2 = { class: "item-of-vouchers__icon-and-name d-flex align-items-center" }
const _hoisted_3 = { class: "item-of-vouchers__icon d-flex justify-content-center align-items-center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "text-cancelled"
}
const _hoisted_6 = {
  key: 1,
  class: "font-weight-bold"
}
const _hoisted_7 = {
  key: 2,
  class: "item-of-vouchers__noti"
}
const _hoisted_8 = {
  key: 3,
  class: "item-of-vouchers__noti"
}
const _hoisted_9 = {
  key: 0,
  class: "item-of-vouchers__pnr hd-item-title-color"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StyledItemOfVoucher = _resolveComponent("StyledItemOfVoucher")!

  return (_openBlock(), _createBlock(_component_StyledItemOfVoucher, { "color-background": _ctx.color }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["ws-textbox ws-textbox__custom d-flex justify-content-between align-items-center hd-service-background-color", { disabled: _ctx.disable || _ctx.used, 'ws-textbox__border': _ctx.border }]),
          style: _normalizeStyle({ '--background-color': _ctx.color, cursor: _ctx.cursor })
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, [
              _createElementVNode("img", {
                src: `/${_ctx.icon}`,
                alt: "",
                srcset: "",
                class: "img-voucher"
              }, null, 8, _hoisted_4)
            ]),
            _createElementVNode("span", {
              class: "item-of-vouchers__name hd-item-title-color",
              style: _normalizeStyle({ cursor: _ctx.cursor }),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClickLabel($event, _ctx.disableClick)))
            }, [
              (_ctx.disable && !_ctx.used)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Cancelled haha "))
                : _createCommentVNode("", true),
              (_ctx.used)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, " Used "))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(!_ctx.disable || !_ctx.used ? _ctx.label : _ctx.label.toLowerCase()), 1),
              (_ctx.number)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, " (" + _toDisplayString(_ctx.number) + ") ", 1))
                : _createCommentVNode("", true),
              (_ctx.text)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.text), 1))
                : _createCommentVNode("", true)
            ], 4)
          ]),
          (_ctx.pnr)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, "PNR: " + _toDisplayString(_ctx.pnr), 1))
            : _createCommentVNode("", true)
        ], 6)
      ])
    ]),
    _: 1
  }, 8, ["color-background"]))
}