
import { computed, defineComponent, onMounted, reactive, Ref, ref, toRef } from 'vue'
import { useRoute } from 'vue-router'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
import StyledSFSHotelCard from '@/styled-components/components/SFSHotelCard'
import LangMixinsVue from '@/views/SearchAndSelectPassengers/Mixin/LangMixins.vue'
import { publicPath } from '../../vue.config'
import _ from 'lodash'
import moment from 'moment'
import store from '@/store'
import { replaceStr } from '@/utils/variables'
import { linkify } from '@/utils/string'

export default defineComponent({
  name: 'SFSHotelCard',
  components: {
    Carousel,
    Slide,
    StyledSFSHotelCard
  },
  mixins: [LangMixinsVue],
  props: {
    hotelInfo: {
      type: Object,
      default: () => ({})
    },
    isAfter: {
      type: Boolean,
      default: false
    },
    selectRoom: {
      type: Boolean,
      default: false
    },
    listDataBooking: {
      type: Array,
      default: () => []
    },
    isShowGGMap: {
      type: Boolean,
      default: false
    },
    timePickup: {
      type: Object,
      default: () => ({})
    },
    origin: {
      type: Object,
      default: () => ({})
    },
    destination: {
      type: Object,
      default: () => ({})
    },
    showCancelHotel: {
      type: Boolean,
      default: false
    },
    hasCancelHotelVoucher: {
      type: Boolean,
      default: false
    },
    cancelTime: {
      type: String,
      default: ''
    },
    checkinDate: {
      type: Date,
      default: null
    },
    checkoutDate: {
      type: Date,
      default: null
    }
  },
  emits: ['confirmPickUpTime', 'handleBookHotel'],
  setup(props, { emit }) {
    const route = useRoute()

    const hotelImageDefault = publicPath + 'images/No_Image_Available.jpg'
    const hotelImages = ref<string[]>([])

    const wrapAround = ref(true) // enable carousel infinite scrolling mode
    const dialogVisible = ref(false) // PopupPickTime dialog visibility

    const sortDescription = ref(false)

    const defaultExplainHRSPayment = ref(
      `HRS has made the reservation and payment for your hotel booking. Should the hotel require any more please mention the reservation number stated above or ask the hotel to contact the HRS customer service.`
    )
    const initHotelImages = () => {
      // push images url to array
      if (props.hotelInfo) {
        if (props.hotelInfo.image1_url || props.hotelInfo.img1) {
          hotelImages.value.push(props.hotelInfo.image1_url || props.hotelInfo.img1)
        }
        if (props.hotelInfo.image2_url || props.hotelInfo.img2) {
          hotelImages.value.push(props.hotelInfo.image2_url || props.hotelInfo.img2)
        }
        if (props.hotelInfo.image3_url || props.hotelInfo.img3) {
          hotelImages.value.push(props.hotelInfo.image3_url || props.hotelInfo.img3)
        }
      }

      // if hotel has no image
      if (hotelImages.value.length == 0) {
        hotelImages.value.push(hotelImageDefault)
      }

      if (hotelImages.value.length == 1) {
        wrapAround.value = false // disable carousel infinite scrolling mode when there is only 1 item
      }
    }

    const imgDefault = (event) => {
      event.target.src = hotelImageDefault
    }

    const listDataBooking: Ref<any[]> = toRef(props, 'listDataBooking')
    const getOptionTaxi = computed((): string => {
      if (!listDataBooking.value.length) {
        return ''
      }

      if (listDataBooking.value[0].origin.display_address === props.hotelInfo.address) {
        return 'from the airport'
      } else {
        return 'from the hotel'
      }
    })

    const isShowMap = ref(false)
    const setIsShowMap = (val: boolean) => {
      isShowMap.value = val
    }

    const timePickup = toRef(props, 'timePickup')
    const handlePickTime = (val) => {
      // @TODO: hide pick-up-time modal
      emit('confirmPickUpTime', val)
      timePickup.value['HH'] = val['HH'] || '00'
      timePickup.value['mm'] = val['mm'] || '00'
    }

    const handleBookHotel = () => {
      emit('handleBookHotel', props.hotelInfo)
    }

    const isSeeMoreShowMap = ref(false)

    const getAddressHotel = computed((): string => {
      return (
        (props.hotelInfo.address || '') +
        ', ' +
        (props.hotelInfo.zipcode || '') +
        ', ' +
        (props.hotelInfo.city || '') +
        ', ' +
        (props.hotelInfo.country || '')
      )
    })

    const timeOpenClose = reactive({
      open_time: '',
      close_time: ''
    })
    const checkDate = (): boolean => {
      if (props.hotelInfo && props.hotelInfo.blockdate && props.hotelInfo.reception_time) {
        let dateBook = new Date(props.hotelInfo.blockdate)
        let day = dateBook.getDay() //  day = 0 => is sunday, day = 6 => is saturday
        // day in js return sunday with value = 0 and monday with value 1
        // day in db retrun sunday with value = 6 and monday with value 0
        if (day === 0) {
          day = 6
        } else {
          day = day - 1
        }
        if (
          !_.isNull(props.hotelInfo.reception_time.regular) &&
          !_.isEmpty(props.hotelInfo.reception_time.regular) &&
          !_.isUndefined(props.hotelInfo.reception_time.regular) &&
          !_.isNull(props.hotelInfo.reception_time.regular[day]) &&
          !_.isEmpty(props.hotelInfo.reception_time.regular[day]) &&
          !_.isUndefined(props.hotelInfo.reception_time.regular[day]) &&
          props.hotelInfo.reception_time.regular[day].open_time &&
          props.hotelInfo.reception_time.regular[day].close_time &&
          props.hotelInfo.reception_time.all_time == 0
        ) {
          timeOpenClose.open_time = props.hotelInfo.reception_time.regular[day].open_time
          timeOpenClose.close_time = props.hotelInfo.reception_time.regular[day].close_time
          return true
        }
      }
      return false
    }

    const hotelRoomType = computed(() => {
      let roomType = ''
      switch (props.hotelInfo.room_type) {
        case 1:
          roomType = 'Single room'
          break
        case 2:
          roomType = 'Double room'
          break
        case 3:
          roomType = 'Triple room'
          break
        case 4:
          roomType = 'Quad room'
          break
      }

      return roomType
    })

    const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    const flightDate = ref('')
    const setFlightDate = (value) => {
      flightDate.value = value
    }
    const getBlockDate = computed((): any => {
      let shuttleBusInfor = {}
      if (props.isAfter) {
        let blockDate = moment(_.get(props.hotelInfo, 'blockdate', new Date())).format('ddd')
        setFlightDate(moment(_.get(props.hotelInfo, 'blockdate', new Date())).format('dddd'))
        let operatingTime = props.hotelInfo['operating_time'] || []
        for (let [index, data] of operatingTime.entries()) {
          if (weekdays[index] === blockDate) {
            shuttleBusInfor = data.data
          }
        }
      }
      return shuttleBusInfor
    })
    const isCheckBlockDate = computed(() => {
      if (Array.isArray(getBlockDate.value)) {
        return !getBlockDate.value.every((x) => Object.values(x).every((value) => value === null || value === ''))
      }
      return false
    })

    const secret = ref(route.query.secret)

    const formatDate = (date: Date | string, format = 'DD-MMM-YYYY') => {
      if (!date || !moment(date).isValid()) {
        return ''
      }
      return moment(date).format(format)
    }
    const getOvernightDateEnd = (format = 'DD-MMM-YYYY') => {
      const overnightDateEnd = !_.isEmpty(props.hotelInfo.overnight_date_end)
        ? moment(props.hotelInfo.overnight_date_end)
        : moment(props.hotelInfo.overnight_date).add(props.hotelInfo.num_day, 'days')
      return overnightDateEnd.format(format)
    }
    onMounted(async () => {
      if (props.selectRoom) {
        await store.dispatch('selectRoom/fetchVoucherHotel', {
          passenger_id: [useRoute().query.pax_voucher_id],
          reservation_id: useRoute().query.reservation_id,
          voucher_id: useRoute().query.voucher_id
        })
      }
      initHotelImages()
      setIsShowMap(props.isShowGGMap)
    })

    return {
      defaultExplainHRSPayment,
      wrapAround,
      dialogVisible,
      hotelImages,
      imgDefault,
      sortDescription,
      getOptionTaxi,
      isShowMap,
      handlePickTime,
      handleBookHotel,
      isSeeMoreShowMap,
      getAddressHotel,
      timeOpenClose,
      checkDate,
      hotelRoomType,
      getBlockDate,
      isCheckBlockDate,
      flightDate,
      secret,
      formatDate,
      replaceStr,
      getOvernightDateEnd,
      linkify
    }
  },
  computed: {
    checkinInfo() {
      return replaceStr(
        this.groundHandlerMessage?.hotel.SFS_LANG_ROW_48 || '<checkin_date> between <checkin_opentime> and <checkin_closetime>',
        ['<checkin_date>', '<checkin_opentime>', '<checkin_closetime>'],
        [this.formatDate(this.checkinDate), this.timeOpenClose.open_time, this.timeOpenClose.close_time]
      )
    },
    checkoutInfo() {
      return replaceStr(
        this.groundHandlerMessage?.hotel.SFS_LANG_ROW_49 || '<checkout_date> at <checkout_time>',
        ['<checkout_date>', '<checkout_time>'],
        [this.formatDate(this.checkoutDate), this.hotelInfo.checkout_time]
      )
    }
  }
})
