import { API_SERVICE } from '@/constants/apiService'
import { mutationTree, actionTree } from 'typed-vuex'
import _ from 'lodash'
import Hotel from '@/models/Hotel'
import { FetchHotelType } from '@/types/hotel'

type RootState = ReturnType<typeof state>

export interface HotelStateType {
  dataListHotel: any
  isInventoryList: number
}

export const initHotelStore = {
  dataListHotel: {},
  isInventoryList: 0
} as HotelStateType

export const state = () => initHotelStore

export const mutations = mutationTree(state, {
  setDataListHotel(state: RootState, dataListHotel: any[]) {
    state.dataListHotel = dataListHotel
  },
  setIsInventoryList(state: RootState, isInventoryList) {
    state.isInventoryList = isInventoryList
  }
})

export const actions = actionTree(
  { state, mutations },
  {
    // updateListHotel()
    async fetchDataListHotel({ commit }, dataSearchHotel: FetchHotelType) {
      const response = await new Hotel(API_SERVICE.SFS).fetchListHotel(dataSearchHotel)
      if (response.success) {
        const result = response.data
        if (result.reservation && result.reservation.length > 0) {
          _.each(result.reservation, function (res) {
            if (!res.total_estimate_cost) {
              res.total_estimate_cost = 0
            }
          })
          commit('setDataListHotel', result.reservation)
        } else if (result.list_hotel && result.list_hotel.length > 0) {
          _.each(result.list_hotel, function (res) {
            if (!res.total_estimate_cost) {
              res.total_estimate_cost = 0
            }
          })
          commit('setDataListHotel', result.list_hotel)
          commit('setIsInventoryList', 1)
        } else {
          commit('setDataListHotel', [])
        }
      }
    },
    async bookListHotel({ commit }, payload) {
      const response = await new Hotel(API_SERVICE.SFS).bookHotel(payload)
      return response
    },
    async fetchMatrixHotels({ commit }, payload: FetchHotelType) {
      const response = await new Hotel(API_SERVICE.SFS).fetchMatrixHotels(payload)
      return response
    }
  }
)
