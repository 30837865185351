import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["model"]
const _hoisted_2 = { class: "confirm-label" }
const _hoisted_3 = { class: "btn__container" }
const _hoisted_4 = { class: "btn btn-confirm-traveling" }
const _hoisted_5 = { class: "btn btn-back-traveling" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemOfVoucher = _resolveComponent("ItemOfVoucher")!
  const _component_SFSListFlightInfo = _resolveComponent("SFSListFlightInfo")!
  const _component_StyledAreYouTravelAlone = _resolveComponent("StyledAreYouTravelAlone")!

  return (_openBlock(), _createBlock(_component_StyledAreYouTravelAlone, null, {
    default: _withCtx(() => [
      _createVNode(_component_ItemOfVoucher, {
        color: "#a7116f",
        icon: "images/icon-voucher/contact-detail.png",
        class: "item-of-voucher",
        pnr: _ctx.myInfo.pnr,
        text: `${_ctx.myInfo.first_name} ${_ctx.myInfo.last_name}`,
        cursor: "auto"
      }, null, 8, ["pnr", "text"]),
      _createVNode(_component_SFSListFlightInfo, { class: "flight-info" }),
      _createElementVNode("div", {
        model: _ctx.travelingAloneForm,
        class: "ws-textbox"
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_13), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirmedTravelingAlone(true)))
            }, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_14), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmedTravelingAlone(false)))
            }, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_15), 1)
          ])
        ])
      ], 8, _hoisted_1)
    ]),
    _: 1
  }))
}