import _ from 'lodash'

export const isInfant = (pax: any) => {
  if (!pax) return false
  let invoicing_flag = pax.invoicing_flag
  if (pax && !_.isUndefined(pax.invoicing_flag) && !_.isNull(pax.invoicing_flag) && !_.isEmpty(pax.invoicing_flag)) {
    let position = invoicing_flag.search('INFT')
    if (position == -1) {
      position = invoicing_flag.search('INF')
    }
    return position != -1
  } else {
    let ssrs = pax.ssrs
    var v = _.findLastIndex(ssrs, (o) => o.type == 'INFT')
    if (v != -1) {
      return v != -1
    }
    return _.findLastIndex(ssrs, (o) => o.type == 'INF') != -1
  }
}
