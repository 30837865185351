import styled from 'vue3-styled-components'

const SFSDateRange = styled.section`
  position: relative;
  .el-date-editor {
    box-shadow: 0 0 10px 3px rgb(0 1 1 / 16%);
    height: 50px;
    width: 100% !important;

    input {
      box-shadow: none;
      font-size: 1rem;
      &:nth-child(2) {
        width: fit-content;
        &:not(:placeholder-shown) {
          width: 39%;
        }
      }
    }

    .el-range-separator {
      position: relative;
      top: 4px;
    }
    .el-input__icon {
      display: none;
    }

    &.is-active {
      border-color: #dcdfe6;
      &:hover {
        font-size: 40px;
      }
    }
  }

  .icon {
    width: 40px;
    position: absolute;
    right: 25px;
    top: 2px;
  }
`

export default SFSDateRange
