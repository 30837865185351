import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ElNotification } from 'element-plus'
import { DataResponse } from '@/types/axios'
import { removeUser, removeToken } from '@/utils/auth'
import store from '@/store'
import { getToken } from './auth'
import router from '@/router'

export abstract class HTTPBaseService {
  protected instance: AxiosInstance
  private specialRequestUrl = [
    'mobile/passengers/hotel-station-handler',
    'mobile/passengers/voucher-hotel',
    'mobile/mealPlanReceipt',
    'vouchers/update-print-voucher'
  ]
  public constructor(service = 'station-handler') {
    let baseURL = ''
    if (service == 'station-handler') {
      baseURL = process.env.VUE_APP_STATION_HANDLER_API + '/api/v1/'
    }
    if (service == 'sfs-api') {
      baseURL = process.env.VUE_APP_SFS_API + '/api/v1/'
    }

    axios.defaults.headers.common['Authorization'] = getToken()
    this.instance = axios.create({
      baseURL,
      withCredentials: service == 'station-handler' ? true : false, // send cookies when cross-domain requests
      timeout: 60000 // request timeout
    })

    this.initializeRequestInterceptor()

    this.initializeResponseInterceptor()
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        store.dispatch('incrementLoading')
        if (getToken()) {
          config.headers['Authorization'] = `Bearer ${getToken()}`
        }

        return config
      },
      (error) => {
        console.log(error.message) // for debug
      }
    )
  }

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use((response: AxiosResponse<DataResponse>) => {
      store.dispatch('decrementLoading')
      // Handle the returned result here
      if (!response.data.success) {
        const errorMessage = response.data.message || response.data.message
        if (response.config.url && !this.specialRequestUrl.includes(response.config.url)) {
          ElNotification.closeAll()
          ElNotification({
            title: 'Error',
            message: errorMessage,
            type: 'error',
            duration: 3000
          })
        }
      }
      return response
    }, this.handleError)
  }

  private handleError = (error) => {
    // Turn off loading screen
    store.dispatch('decrementLoading')
    // Handle not authentication
    if (error.response?.status && error.response.status === 401) {
      removeUser()
      removeToken()
      const errorMessage = error.response?.data?.message || error.message
      ElNotification.closeAll()

      router.push({ path: '/login', query: { notification: errorMessage } })
      return error.response
    }

    // Handle not perrmision
    if (error.response?.status && error.response.status === 405) {
      ElNotification({ message: 'Not Permission', type: 'error' })
      setTimeout(() => (window.location.href = '/'), 2000)
      return error.response
    }

    let message = ''
    if (error.response?.data?.details) {
      const errorMessage: any = Object.values(error.response?.data?.details)
      message = errorMessage.length && errorMessage[0]?.message
    }

    const errorMessage = message || error.response?.data?.message || error.message

    process.env.NODE_ENV !== 'production' &&
      ElNotification({
        title: 'Error',
        message: errorMessage,
        type: 'error',
        duration: 3000
      })

    throw error.response.data
  }
}

class HttpException extends Error {
  status: number
  message: string
  response: { [key: string]: string }
  constructor(status: number, message: string, response: { [key: string]: string }) {
    super(message)
    this.status = status
    this.message = message
    this.response = response
  }
}

export default HttpException
