import { SendSmsMailBody } from '@/types/sfsApiSendSmsMail'
import { HTTPBaseService } from '@/utils/request'
import { BaseResponse } from './../types/global'

export default class SfsApiSendSmsMail extends HTTPBaseService {
  constructor() {
    super('sfs-api')
  }

  public async sendSmsMail(body: SendSmsMailBody): Promise<BaseResponse> {
    const { data } = await this.instance.post(`vouchers/sendSmsMail`, body)
    return data
  }
}
