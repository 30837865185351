import styled from 'vue3-styled-components'

const StyledVoucher = styled.section`
  font-family: Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 16px;
  .taxi {
    .icon-border,
    .color-text-icon {
      color: #3c82af;
    }
  }
  .refreshment {
    .icon-border,
    .color-text-icon {
      color: #05164d;
      margin-left: unset;
    }
  }
  p {
    margin-top: 0;
    margin-bottom: 4px;
  }
  .hotel-service-info {
    margin-top: 25px !important;
    strong {
      display: inline-block;
      margin-bottom: 5px;
    }
  }
  .your-voucher-detail {
    font-size: 19px;
  }
  .general-comment {
    font-size: 19px;
    margin-bottom: 13px;
    margin-top: 18px;
  }
  .transport-to-hotel {
    font-size: 19px;
    margin-bottom: 17px;
    margin-top: 34px;
  }
  .check-in {
    margin-bottom: 16px;
  }
  .fa-shopping-basket {
    width: 14px;
    padding-left: 0.5em !important;
  }

  .btn-select {
    width: 63px;
    background: #09154b;
    color: #fff;
    padding: 11px 2px;
    &:hover {
      background-color: #0094d5;
      border: 0px;
      span {
        color: #f5d32a;
        font-weight: 500;
      }
    }
  }
  .btn-close {
    margin-top: 25px;
  }
  .btn {
    text-align: center;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
    button {
      border: 1px;
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
      background-color: #ffffff;
      letter-spacing: 0px;
      color: #f5d32a;
      font-family: 'Roboto';
      border-radius: 17px;
      width: 162px;
      height: 44px;
      font-weight: 500;
      font-size: 16px;
    }

    button + button {
      margin-left: 5%;
    }
  }

  @media print {
    width: 100%;
  }
  .voucher-title {
    font-size: 20px;
  }
  .voucher-title img {
    vertical-align: sub;
    margin-right: 4px;
  }
  .voucher-title-wrapper {
    display: flex;
    height: 100%;
    align-items: flex-end;
  }
  .voucher-title-hotel {
    font-size: 20px;
    padding-top: 15px;
    padding-bottom: 6px;
  }
  .voucher-title-hotel img {
    vertical-align: sub;
    margin-right: 4px;
    margin-left: 4px;
    margin-bottom: 4px;
  }
  .voucher-logo {
    display: flex;
    padding-bottom: 10px;
    align-items: end;
  }
  .vertical-unset {
    vertical-align: unset;
  }
  .hotel-voucher-billing-inf {
    background-color: #5ea354;
    border: 10px solid #5ea354;
    padding: 18px 12px;
    line-height: 21px;
    margin-top: 30px !important;
    strong {
      font-size: 14px;
    }
    span {
      font-weight: 500;
    }
  }
  .pax-name {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .qrcode {
    height: 150px;
    width: 150px;
    display: block;
    margin-bottom: 10px;
  }
  .mta4-3 {
    margin-top: 10px;
  }
  .m-2 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .pl-2 {
    padding-left: 5px;
  }
  i.icon-border {
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0 0 2px #888;
    padding: 0.5em 0.6em;
  }
  .color-text-icon {
    color: #05164d;
    margin-inline: 5px;
    font-size: 15px;
  }
  .align-center {
    display: flex;
    align-items: center;
  }
  .border-wrap {
    border: 1px solid #333;
    padding: 5px;
  }
  .travel-destination {
    padding-bottom: 20px;
  }
  .pagebreak {
    padding-top: 50px;
  }
`

export default StyledVoucher
