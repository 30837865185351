import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ws-textbox mt-50" }
const _hoisted_2 = { class: "total-price" }
const _hoisted_3 = { class: "mt-50" }
const _hoisted_4 = { class: "group-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemOfVoucher = _resolveComponent("ItemOfVoucher")!
  const _component_SFSTravelersAndServiceInGroup = _resolveComponent("SFSTravelersAndServiceInGroup")!
  const _component_StyledVoucherSharing = _resolveComponent("StyledVoucherSharing")!

  return (_openBlock(), _createBlock(_component_StyledVoucherSharing, null, {
    default: _withCtx(() => [
      _createVNode(_component_ItemOfVoucher, {
        label: _ctx.groundHandlerMessage?.monetary_compensation.SFS_LANG_ROW_2,
        color: "#bbe35d",
        icon: "images/icon-voucher/monetary-compensation.png"
      }, null, 8, ["label"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.groundHandlerMessage?.monetary_compensation.SFS_LANG_ROW_3), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.totalCredit), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.listMyGroupPaxs.length > 0)
          ? (_openBlock(), _createBlock(_component_SFSTravelersAndServiceInGroup, {
              key: 0,
              label: _ctx.groundHandlerMessage?.monetary_compensation.SFS_LANG_ROW_5 || 'Credit includes vouchers for',
              "list-pax": _ctx.listMyGroupPaxs
            }, null, 8, ["label", "list-pax"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "btn-action",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backToHomePage()))
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.groundHandlerMessage?.monetary_compensation.SFS_LANG_ROW_4), 1)
        ])
      ])
    ]),
    _: 1
  }))
}