import styled from 'vue3-styled-components'

const StyledPassengerDetail = styled.section`
  .mt-4 {
    margin-top: 1.5rem !important;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .btn-action {
    width: 162px;
    height: 44px;
    border-radius: 17px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
    background-color: #ffffff;
    border: 0px;
    display: inline-block;
    margin-top: 30px;
    span {
      font-size: 16px;
      letter-spacing: 0px;
      color: #f5d32a;
      font-weight: 500;
    }
  }
  .btn-action:hover {
    background-color: #0094d5;
    border: 0px;
    display: inline-block;
    span {
      color: #f5d32a;
      font-weight: 500;
    }
  }
  .ws-textbox {
    padding: 1rem;
    letter-spacing: 0.004375rem;
    background-color: #ffffff;
    border-radius: 0.75rem;
    border: none;
    -webkit-box-shadow: 0 0.1875rem 0.375rem #85858529;
    box-shadow: 0 0.1875rem 0.375rem #85858529;
    outline: none;
    line-height: 1.777777778;
  }
  .d-flex {
    display: flex;
  }
  .justify-content-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #bbb;
    border-radius: 0.25rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .form-control:focus-visible {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    border-color: #66afe9;
    outline: 1px solid #a1cdf0;
  }
  h5 {
    font-size: 1.25rem;
    color: #474747;
  }
  .m-0 {
    margin: 0 !important;
  }
  .ws-btn {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 0.75rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.00375rem;
    color: #ffffff;
    background-color: #0094d5;
    border: none;
    outline: none !important;
    -webkit-box-shadow: 0rem 0.125rem 0.625rem #0000001a;
    box-shadow: 0rem 0.125rem 0.625rem #0000001a;
    display: inline-block;
    line-height: 1.25;
    text-align: center;
    min-width: 7.5rem;
  }
  .text-center {
    text-align: center !important;
  }
  .vue-tel-input {
    border-radius: 3px;
    display: flex;
    text-align: left;
    align-items: center;
    div {
      border: none;
    }
    i {
      margin: 0 10px;
    }
  }
  .vue-tel-input {
    .dropdown:focus {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
      border-color: #66afe9;
    }
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px white inset;
      -webkit-text-fill-color: #333;
    }
    input:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 50px white inset;
      -webkit-text-fill-color: #333;
    }
    input {
      border: 0px;
      margin-right: 5px;
    }
    .dropdown {
      border: 1px solid #bbb;
      border-radius: 10px;
      margin-right: 5px;
    }
  }
  .vue-tel-input:focus {
    outline-color: white;
  }
  .el-alert {
    margin: 20px 0 0;
  }
  .el-alert:first-child {
    margin: 0;
  }
  .validation-failed {
    border-color: #ff0000;
  }
  .validation-failed .vti__dropdown {
    background-color: #ffdddd;
  }
  .alert-box {
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f8d7da;
    z-index: 2000;
    border-radius: 1rem;
    padding-left: 20px;
    font-size: 14px;
  }
`

export default StyledPassengerDetail
