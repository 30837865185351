import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "ws-textbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_SFSMatrixSelect = _resolveComponent("SFSMatrixSelect")!
  const _component_StyledSFSMatrixSelectAndSelectBox = _resolveComponent("StyledSFSMatrixSelectAndSelectBox")!

  return (_openBlock(), _createBlock(_component_StyledSFSMatrixSelectAndSelectBox, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.groundHandlerMessage?.taxi.SFS_LANG_ROW_22), 1),
          _createElementVNode("div", null, [
            _createVNode(_component_el_select, {
              modelValue: _ctx.numberOfTaxies,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.numberOfTaxies) = $event)),
              class: "m-2",
              placeholder: _ctx.groundHandlerMessage?.taxi.SFS_LANG_ROW_23,
              size: "large",
              disabled: _ctx.isSetByAirline
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectOptions, (item, index) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: index,
                    label: item,
                    disabled: _ctx.isSetByAirline,
                    value: item,
                    clearable: false,
                    filterable: false
                  }, null, 8, ["label", "disabled", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "placeholder", "disabled"])
          ])
        ])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_SFSMatrixSelect, {
          ref: "sfsMatrixSelectComponent",
          amount: _ctx.numberOfTaxies,
          "list-paxs": _ctx.listPaxs,
          matrix: _ctx.matrix,
          title: _ctx.groundHandlerMessage?.taxi.SFS_LANG_ROW_24,
          "is-airline-set": _ctx.isSetByAirline,
          "airline-matrix": _ctx.shareMatrix,
          disabled: _ctx.isSetByAirline
        }, null, 8, ["amount", "list-paxs", "matrix", "title", "is-airline-set", "airline-matrix", "disabled"])
      ])
    ]),
    _: 1
  }))
}