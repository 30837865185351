
import { defineComponent, ref, watch } from 'vue'

import StyledSFSInput from '@/styled-components/components/SFSInput'

export default defineComponent({
  components: {
    StyledSFSInput
  },
  props: {
    placeholder: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    modelValue: {
      type: [String, Number],
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    rows: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    maxlength: {
      type: Number,
      default: null
    },
    step: {
      type: Number,
      default: null
    },
    append: {
      type: String,
      default: null
    },
    prepend: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default: () => []
    },
    triggerClick: {
      type: Boolean,
      default: false
    },
    // use for validate
    prop: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    blur: {
      type: Function,
      default: () => {}
    }
  },
  emits: ['update:modelValue', 'handleChange'],
  setup(props, { emit }) {
    const inputValue = ref<String | Number>(props.modelValue)

    watch(
      () => props.modelValue,
      (value) => (inputValue.value = value)
    )
    watch(inputValue, (value) => emit('update:modelValue', value))

    const handleChange = (value) => emit('handleChange', value)

    const handleBlur = (event) => {
      props.blur()
      // force trigger click event for related target
      props.triggerClick && event?.relatedTarget?.click()
    }

    return { inputValue, handleChange, handleBlur }
  }
})
