
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { VueTelInput } from 'vue-tel-input'
import { ElNotification } from 'element-plus'
import store from '@/store'
// import styled component
import StyledHome from '@/styled-components/pages/home'
import ItemOfVoucher from '@/components/ItemOfVoucher/Index.vue'
import SFSTravelersAndServiceInGroup from '@/components/SFSTravelersAndServiceInGroup.vue'
import SfsApiSendSmsMail from '@/models/SFSAPI_SendSMSMail'
import { useRoute, useRouter } from 'vue-router'
import Passenger from '@/models/Passenger'
import FetchAllMixinsVue from '../Mixins/FetchAllMixins.vue'
import { StoreType } from '@/types/store'
import { isJson } from '@/utils/string'
import _ from 'lodash'
import { SERVICE } from '@/constants/service'
import LangMixinsVue from '../SearchAndSelectPassengers/Mixin/LangMixins.vue'

export default defineComponent({
  name: 'Home',
  components: {
    VueTelInput,
    ItemOfVoucher,
    StyledHome,
    SFSTravelersAndServiceInGroup
  },
  mixins: [FetchAllMixinsVue, LangMixinsVue],
  setup() {
    const store = useStore<StoreType>()
    return {
      flightInfo: computed(() => store.state.flightInfo),
      paxThisIsMe: computed(() => store.state.groupPax.me),
      listGroupPaxExcludeMe: computed(() => store.state.groupPax.listGroupPaxExcludeMe),
      vouchers: computed(() => {
        const vouchers = JSON.parse(JSON.stringify(store.state.vouchers))
        if (!vouchers.length) return vouchers
        const serviceIdMc = 21
        const resultVouchers: any[] = []
        vouchers.forEach((voucher) => {
          if (voucher.service_id == serviceIdMc) {
            if (!_.isEmpty(voucher.detail_monetary)) resultVouchers.push(voucher)
          } else {
            resultVouchers.push(voucher)
          }
        })
        return resultVouchers
      }),
      paxsGroup: computed(() => store.getters['groupPax/listGroupPax'])
    }
  },
  data() {
    return {
      sendButtonLoading: false,
      sendButtonDisable: false,
      phonePax: '',
      emailPax: '',
      validatePhone: true,
      checkEmptyValue: true,
      checkEmail: false,
      tempPhone: '',
      isNotShowMealPlanService: false,
      serviceMealPlanId: SERVICE.MEAL_PLAN_SERVICE_ID,
      route: useRoute(),
      router: useRouter()
    }
  },
  computed: {
    isShowCombineVoucher() {
      if (store.state.airline?.params) {
        const params = store.state.airline?.params
        return params.mobile_enable_travelcompanions_home == 1
      }
      return false
    }
  },
  watch: {
    paxThisIsMe: {
      handler: function (newValue) {
        this.phonePax = newValue.phone_number || ''
        this.emailPax = newValue.email_address || ''
        this.cleanPhoneNumber(this.phonePax)
      },
      deep: true
    }
  },
  async created() {
    this.fetchAll()
    await this.fetchVouchers()
    this.fetchFlightInfo()
    this.fetchMealPlanVoucher()
    this.checkNotShowMealPlanWhenCancelHotel()
    this.fetchOvernightStation()
  },
  methods: {
    getLabel: function (label) {
      if (!label) return ''
      if (label == 'Hotel voucher') {
        return this.groundHandlerMessage?.homepage_new.SFS_LANG_ROW_6
      }
      if (label == 'Taxi voucher') {
        return this.groundHandlerMessage?.homepage_new.SFS_LANG_ROW_7
      }
      if (label.includes('Monetary voucher')) {
        const voucherNumber = label.split(' ')[2] || ''
        return this.groundHandlerMessage?.homepage_new.SFS_LANG_ROW_9 + ' ' + voucherNumber
      }
      if (label == 'Meal Plan voucher') {
        return this.groundHandlerMessage?.homepage_new.SFS_LANG_ROW_8
      }
      if (label.includes('Refreshment voucher')) {
        const voucherNumber = label.split(' ')[2] || ''
        return this.groundHandlerMessage?.homepage_new.SFS_LANG_ROW_5 + ' ' + voucherNumber
      }
    },
    checkPhoneValid: function ({ formatted, valid }) {
      this.checkEmptyValue = true
      this.validatePhone = valid
      if (!formatted) {
        this.validatePhone = true
      }
      this.tempPhone = formatted
    },
    cleanPhoneNumber(number) {
      let addPLusSign = false
      if (this.hasLeadingPlus(number)) {
        addPLusSign = true
      }
      number = number.replace(/\+/g, '')
      if (this.hasLeadingZeros(number)) {
        addPLusSign = true
        number = number.replace(/^0+/, '')
      }
      if (addPLusSign) {
        number = '+' + number
      }
      this.phonePax = number
    },

    hasLeadingZeros(str) {
      return /^00+/.test(str)
    },
    hasLeadingPlus(str) {
      return /^\++/.test(str)
    },
    changeEmail() {
      this.checkEmptyValue = true
    },
    changePhone() {
      this.cleanPhoneNumber(this.phonePax)
      if (this.phonePax.length == 0) this.validatePhone = true
    },
    checkValidEmail() {
      // eslint-disable-next-line
      this.checkEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailPax)
    },
    checkValidInput() {
      let self = this
      if (self.phonePax.length == 1) self.validatePhone = false
      self.checkValidEmail()
      if (self.phonePax || self.emailPax) {
        if (self.phonePax) {
          if (!self.validatePhone) {
            ElNotification.closeAll()
            ElNotification({
              title: 'Error',
              message: 'Please enter valid phone or email address',
              type: 'error',
              duration: 3000
            })
            return false
          }
          if (self.tempPhone !== '') {
            let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
            if (!numbers.includes(this.tempPhone.charAt(this.tempPhone.length - 1))) {
              ElNotification.closeAll()
              ElNotification({
                title: 'Error',
                message: 'Please enter valid phone or email address',
                type: 'error',
                duration: 3000
              })
              return false
            }
          }
        }
        if (self.emailPax) {
          if (!self.checkEmail) {
            ElNotification.closeAll()
            ElNotification({
              title: 'Error',
              message: 'Please enter valid phone or email address',
              type: 'error',
              duration: 3000
            })
            return false
          }
          if (self.emailPax === null || self.emailPax === '') {
            ElNotification.closeAll()
            ElNotification({
              title: 'Error',
              message: 'Please enter your email address.',
              type: 'error',
              duration: 3000
            })
            return false
          }
        }
      }
      if (!self.phonePax && !self.emailPax) {
        this.checkEmptyValue = false
        ElNotification.closeAll()
        ElNotification({
          title: 'Error',
          message: 'Please enter your phone number or email address.',
          type: 'error',
          duration: 3000
        })
        return false
      }
      return true
    },
    handleTo() {},
    async handleSendSmsEmail() {
      this.sendButtonDisable = true
      this.sendButtonLoading = true
      const airlineToken = store.state.airline?.unique_token
      const airportCode = this.paxThisIsMe?.overnight_station
      const paxId = this.paxThisIsMe?.id
      const email = this.paxThisIsMe?.email_address
      const phone = this.paxThisIsMe?.phone_number
      const dataSend = {
        data: {
          Passengers: [{ Mail: email ?? '', PassengerId: paxId ?? 0, PhoneNumber: phone ?? '' }],
          fromIssueVoucher: 0,
          fromSelectPassenger: 0,
          AirportCode: airportCode ?? ''
        },
        AirlineToken: airlineToken ?? ''
      }
      const sfsApi = new SfsApiSendSmsMail()
      const { success, message } = await sfsApi.sendSmsMail(dataSend)
      if (success) {
        ElNotification.closeAll()
        ElNotification({
          title: 'Success',
          message: 'Send SMS/Email successfully',
          type: 'success',
          duration: 3000
        })
      } else {
        ElNotification.closeAll()
        ElNotification({
          title: 'Error',
          message: message ?? 'Action failed',
          type: 'error',
          duration: 3000
        })
      }
      this.sendButtonDisable = false
      this.sendButtonLoading = false
    },
    async sendSMSAndEmail() {
      // check phone number and email format
      let isValid = this.checkValidInput()
      if (!isValid) {
        return
      }
      if (this.phonePax || this.emailPax) {
        const email = this.paxThisIsMe?.email_address
        const phone = this.paxThisIsMe?.phone_number
        // check has change phone number or email -> call api update info
        if (this.phonePax != phone || this.emailPax != email) {
          const { success, message } = await new Passenger().updateEmailAndPhoneNumber({
            passenger_id: this.paxThisIsMe?.id ?? 0,
            email_address: this.emailPax,
            phone_number: this.phonePax
          })
          if (success) {
            // Update info of pax thisIsMe
            store.dispatch('groupPax/setMe', {
              ...this.paxThisIsMe,
              email_address: this.emailPax,
              phone_number: this.phonePax
            })
            // call api issue (send sms/email)
            await this.handleSendSmsEmail()
          } else {
            ElNotification.closeAll()
            ElNotification({
              title: 'Error',
              message: message,
              type: 'error',
              duration: 3000
            })
          }
        } else {
          // call api issue (send sms/email)
          await this.handleSendSmsEmail()
        }
      } else {
        return
      }
    },
    handleBack() {
      // redirect to search page
      this.router.push({ name: 'SearchAndSelectPassengers' })
      return
    },
    handlePrint() {
      // redirect to hotel book if has matrix
      const dataHotel = this.vouchers.find((v) => v.service_id == SERVICE.HOTEL_SERVICE_ID)
      if (this.paxsGroup.length > 1 && dataHotel) {
        if (dataHotel && isJson(dataHotel?.params)) {
          dataHotel.params = JSON.parse(dataHotel?.params)
        }
        // Group with more than 1 passenger
        if (!_.isNull(dataHotel?.params) && !_.isUndefined(dataHotel?.params.matrix_share_room)) {
          // Matrix existed
          this.router.push({ path: '/hotel-list' })
          return
        }
        this.router.push({ path: '/hotel-book' })
        return
      }
      if (dataHotel) {
        // hotel voucher
        this.router.push({ path: '/hotel-list' })
        return
      }
      if (this.vouchers.find((v) => v.service_id == SERVICE.TAXI_SERVICE_ID)) {
        // taxi
        this.router.push({ path: '/taxi-service' })
        return
      }

      if (this.vouchers.length) {
        this.router.push({ path: '/print-services' })
        return
      }
      ElNotification.closeAll()
      ElNotification({
        title: 'Error',
        message: 'You have no service',
        type: 'error',
        duration: 3000
      })
    },
    checkNotShowMealPlanWhenCancelHotel() {
      const dataMealPlan = this.vouchers.find((v) => v.service_id == this.serviceMealPlanId)
      if (dataMealPlan) {
        const isNotShowMealPlanService = JSON.parse(dataMealPlan.params).has_cancelled_meal_plan_voucher
        if (isNotShowMealPlanService) {
          this.isNotShowMealPlanService = true
        }
      }
    }
  }
})
