import styled from 'vue3-styled-components'

const SFSCheckbox = styled.section`
  .el-checkbox {
    .el-checkbox__input {
      width: 10px;
      .el-checkbox__inner {
        height: 1rem;
        width: 1rem;
        &:hover {
          border-color: inherit;
        }
      }
    }
    .el-checkbox__label {
      font-size: 1rem;
      color: inherit;
      font-weight: normal;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: inherit;
      font-weight: normal;
    }
  }
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: inherit;
  }
`

export default SFSCheckbox
