import styled from 'vue3-styled-components'

const StyledLogin = styled.section`
  margin: 5% 10% 10%;
  .login {
    margin: 0 auto
    max-width: 500px;
    padding: 5% 15% 5%;
    letter-spacing: 0.004375rem;
    background-color: #ffffff;
    border-radius: 0.75rem;
    border: none;
    -webkit-box-shadow: 0 0.1875rem 0.375rem #85858529;
    box-shadow: 0 0.1875rem 0.375rem #85858529;
    outline: none;
    line-height: 1.777777778;
    text-align: center;

    .content-input {
      text-align: left;
    }

    .login-label {
      font-size: 150%;
      font-weight: bold;
    }

    .el-input__inner {
      height: 3rem;
    }
    
   .el-input input, .el-textarea input {
      box-shadow: none;
    }
  }
  
  .btn-confirm {
    padding: 5% 0% 0%;
    button {
      height: 3rem;
      border-radius: 5px;
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      margin-bottom: 25px;
      border: 1px;
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
      background-color: #f5d32a;
      letter-spacing: 0px;
      font-family: 'Roboto';

    }
`

export default StyledLogin
