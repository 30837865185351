import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialogVisible) = $event)),
    "close-on-click-modal": false,
    onOpened: _ctx.handleDialogOpened,
    onClosed: _ctx.closeOrStopScanBoarding
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_el_button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogVisible = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax?.SFS_LANG_ROW_35 || 'Close scan'), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        id: `reader`,
        width: _ctx.width
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["modelValue", "onOpened", "onClosed"]))
}