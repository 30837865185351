
import { defineComponent, ref, watch } from 'vue'

import StyledSFSCheckbox from '@/styled-components/components/SFSCheckbox'

export default defineComponent({
  components: {
    StyledSFSCheckbox
  },
  props: {
    label: {
      type: String,
      default: null
    },
    modelValue: {
      type: [Boolean, Number],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // use for validate
    prop: {
      type: String,
      default: null
    },
    isNumber: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'handleChange'],
  setup(props, { emit }) {
    const checked = ref<Boolean | Number>(!!props.modelValue)

    watch(
      () => props.modelValue,
      (value) => (checked.value = !!value)
    )
    watch(checked, (value) => emit('update:modelValue', value ? (props.isNumber ? 1 : true) : props.isNumber ? 0 : false))

    const handleChange = (value) => emit('handleChange', value ? (props.isNumber ? 1 : true) : props.isNumber ? 0 : false)

    return { checked, handleChange }
  }
})
