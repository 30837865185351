import { mutationTree, actionTree } from 'typed-vuex'

type RootState = ReturnType<typeof state>

interface StateType {
  pax: any
  lang: []
}

export const state = () =>
  ({
    pax: {},
    lang: []
  } as StateType)

export const mutations = mutationTree(state, {
  setPassengerDetail(state: RootState, pax: any) {
    state.pax = pax
    state.lang = pax.lang
  }
})

export const actions = actionTree(
  { state, mutations },
  {
    async setPassengerDetail({ commit }, pax) {
      commit('setPassengerDetail', pax)
    }
  }
)
