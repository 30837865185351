import { BaseResponse } from './../types/global'
import { HTTPBaseService } from '@/utils/request'
import {
  ModifyGroupBody,
  ModifyGroupPaxBody,
  SearchFlightBody,
  SearchPaxBody,
  UpdateEmailAndPhoneNumberBody
} from '@/types/passenger'
import { RequiredParamsType } from '@/types/common'

export default class Passenger extends HTTPBaseService {
  constructor(service = 'station-handler') {
    super(service)
  }

  public async searchFlights(body: SearchFlightBody): Promise<BaseResponse> {
    const { data } = await this.instance.post('passengers/searchFlights', body)
    return data
  }

  public async searchPax(body: SearchPaxBody): Promise<BaseResponse> {
    const { data } = await this.instance.post('passengers/search', body)
    return data
  }

  public async modifyGroup(body: ModifyGroupBody): Promise<BaseResponse> {
    const { data } = await this.instance.post('passengers/modifyGroup', body)
    return data
  }

  public async updateEmailAndPhoneNumber(body: UpdateEmailAndPhoneNumberBody): Promise<BaseResponse> {
    const { data } = await this.instance.put('passengers/updateEmailAndPhoneOfPassenger', body)
    return data
  }

  public async parseStringToPax(body: { boardingPass: string }): Promise<BaseResponse> {
    const { data } = await this.instance.post('passengers/search-pax', body)
    return data
  }

  public async modifyGroupPax(body: ModifyGroupPaxBody): Promise<BaseResponse> {
    const { data } = await this.instance.post('passengers/modify-group-pax', body)
    return data
  }

  public async addNewPaxsToGroup(body: ModifyGroupPaxBody): Promise<BaseResponse> {
    const { data } = await this.instance.post('passengers/add-new-paxs-to-group', body)
    return data
  }

  public async getPaxsGroup(body: { paxId: number }): Promise<BaseResponse> {
    const { data } = await this.instance.post('passengers/group', body)
    return data
  }

  public async getFlightInfo(fltref: string): Promise<BaseResponse> {
    const { data } = await this.instance.get('passengers/get-flight-info', { params: { fltref } })
    return data
  }

  public async getListFlightInfo(paxId: number): Promise<BaseResponse> {
    const { data } = await this.instance.get('passengers/get-list-flight-info', { params: { paxId } })
    return data
  }

  public async fetchPax(param: string): Promise<BaseResponse> {
    const { data } = await this.instance.get(`mobile/passengers/${param}`)
    return data
  }

  /**
   * service = 'sfs-api'
   */
  public async fetchVoucherHotel(body: {
    passenger_id: number
    reservation_id: number
    voucher_id: number
  }): Promise<BaseResponse> {
    const { data } = await this.instance.post('mobile/passengers/voucher-hotel', { data: body })
    return data
  }

  /**
   * service = 'sfs-api'
   */
  public async fetchHotel(body: RequiredParamsType): Promise<BaseResponse> {
    const { data } = await this.instance.post(`mobile/passengers/hotel-station-handler`, { data: { ...body } })

    return data
  }

  /**
   * service = 'station-handler'
   */
  public async fetchServices(paxId: number, airlineId: number): Promise<BaseResponse> {
    const { data } = await this.instance.post(`passengers/services`, { paxId: paxId, airline_id: airlineId })

    return data
  }

  /**
   * service = 'sfs-api'
   */
  public async sendSMSShareroom(body: any): Promise<BaseResponse> {
    const { data } = await this.instance.post(`mobile/sms/share-room`, { data: { ...body } })

    return data
  }

  public async getOvernightStation(paxId: number, airlineId: number): Promise<BaseResponse> {
    const { data } = await this.instance.get('passengers/over-night-station', { params: { paxId, airlineId } })
    return data
  }
}
