
import { defineComponent, computed } from 'vue'
import { OPTION_TAXI, TAXI_CONFIG, TAXI_WAY_OPTION } from '@/constants/taxiService'
import ItemOfVoucher from '@/components/ItemOfVoucher/Index.vue'
import StyledTaxiService from '@/styled-components/pages/taxi-service'
import { useStore } from 'vuex'
import _ from 'lodash'
import { useRoute, useRouter } from 'vue-router'
import AddressInfo from './AddressInfo.vue'
import SFSMatrixTaxi from '@/views/TaxiService/SFSMatrixTaxi.vue'
import _Empty from 'element-plus/lib/el-empty'
import { ElNotification } from 'element-plus'
import SfsApiMobile from '@/models/SFSAPI_Mobile'
import FetchAllMixinsVue from '../Mixins/FetchAllMixins.vue'
import LangMixinsVue from '@/views/SearchAndSelectPassengers/Mixin/LangMixins.vue'
import { StoreType } from '@/types/store'
import Title from '@/components/SFSTitle.vue'

export default defineComponent({
  name: 'TaxiService',
  components: {
    ItemOfVoucher,
    StyledTaxiService,
    AddressInfo,
    SFSMatrixTaxi,
    Title
  },
  mixins: [FetchAllMixinsVue, LangMixinsVue],
  props: {
    indexBooking: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const store = useStore<StoreType>()
    return {
      airline: computed(() => store.state.airline),
      flightInfo: computed(() => store.state.flightInfo),
      paxThisIsMe: computed(() => store.state.groupPax?.me),
      listGroupPax: computed(() => store.getters['groupPax/listGroupPax']),
      listGroupPaxExcludeMe: computed(() => store.state.groupPax?.listGroupPaxExcludeMe),
      vouchers: computed(() => store.state.vouchers),
      overnightStation: computed(() => store.state.overnightStation),
      detailVouchersTaxi: computed(() => store.state.taxi.detailVouchers),
      paxsHotel: computed(() => store.state.selectRoom.paxsHotel)
    }
  },
  data() {
    const shareMatrixPaxId: number[][] = []
    return {
      route: useRoute(),
      router: useRouter(),
      taxiService: undefined as any,
      shareMatrixPaxId,
      isSetByAirline: false,
      taxiBookingDetail: undefined as any,
      optionTaxi: OPTION_TAXI.AIRPORT_ADDRESS,
      wayOption: TAXI_WAY_OPTION.ONE_WAY,
      totalPrice: 0,
      taxiPrice: 0,
      priceHotelToAirport: 0,
      priceAirportToHotel: 0,
      locationInput: undefined as any,
      distanceTaxi: 0,
      distance_hotel_to_airport: 0,
      taxiConfig: TAXI_CONFIG.PHOTO2CLAIM,
      paxPerTaxi: undefined as number | undefined,
      isSetOptionTaxi: false,
      isNeedToShareTaxi: false,
      used_transfer_wise: false,
      taxiErrorMessage: ''
    }
  },
  computed: {
    origin() {
      if (this.taxiBookingDetail && this.taxiBookingDetail.origin) {
        return this.taxiBookingDetail.origin
      }
      let location = {}
      switch (this.optionTaxi) {
        case OPTION_TAXI.ONE_AIRPORT_HOTEL:
        case OPTION_TAXI.TWO_AIRPORT_HOTEL:
        case OPTION_TAXI.AIRPORT_ADDRESS:
          if (this.airline) {
            location = {
              lat: parseFloat(this.overnightStation ? this.overnightStation.geo_lat : this.airline?.geo_lat),
              lng: parseFloat(this.overnightStation ? this.overnightStation.geo_lon : this.airline?.geo_lon),
              display_address: this.overnightStation ? this.overnightStation.name : this.airline?.airport_name,
              disabled: true
            }
          }
          break
        case OPTION_TAXI.ONE_HOTEL_AIRPORT:
          if (!_.isEmpty(this.paxsHotel))
            location = {
              lat: parseFloat(this.paxsHotel?.geo_lat),
              lng: parseFloat(this.paxsHotel?.geo_long),
              display_address: this.paxsHotel?.address,
              disabled: true
            }
          else {
            location = {
              lat: '',
              lng: '',
              display_address: '',
              disabled: true
            }
          }
          break
        default:
          break
      }
      return location
    },
    destination() {
      if (this.taxiBookingDetail && this.taxiBookingDetail.destination) {
        return this.taxiBookingDetail.destination
      }

      let location = {}
      switch (this.optionTaxi) {
        case OPTION_TAXI.ONE_AIRPORT_HOTEL:
        case OPTION_TAXI.TWO_AIRPORT_HOTEL:
          if (this.paxsHotel)
            location = {
              lat: parseFloat(this.paxsHotel?.geo_lat ?? ''),
              lng: parseFloat(this.paxsHotel?.geo_long ?? ''),
              display_address: this.paxsHotel?.address ?? '',
              disabled: true
            }
          break
        case OPTION_TAXI.ONE_HOTEL_AIRPORT:
          if (this.airline) {
            location = {
              lat: parseFloat(this.overnightStation ? this.overnightStation.geo_lat : this.airline.geo_lat),
              lng: parseFloat(this.overnightStation ? this.overnightStation.geo_lon : this.airline.geo_lon),
              display_address: this.overnightStation ? this.overnightStation.name : this.airline.airport_name,
              disabled: true
            }
          }
          break
        case OPTION_TAXI.AIRPORT_ADDRESS:
          if (this.locationInput) location = this.locationInput
          break
        default:
          break
      }
      return location
    },
    isRenderGroupPhoneNumber() {
      return this.isNeedToShareTaxi || this.taxiConfig == TAXI_CONFIG.TAXI2RIDE || this.taxiConfig == TAXI_CONFIG.PHOTO2CLAIM
    },
    isDisableChooseWayOption() {
      return this.optionTaxi != OPTION_TAXI.AIRPORT_ADDRESS || this.isSetOptionTaxi
    },
    isRenderPleaseIssueHotelFirst() {
      return this.optionTaxi != 4 && _.isEmpty(this.paxsHotel)
    }
  },
  watch: {
    detailVouchersTaxi() {
      this.initData()
    },
    optionTaxi(option) {
      switch (parseFloat(option)) {
        case OPTION_TAXI.ONE_AIRPORT_HOTEL:
        case OPTION_TAXI.ONE_HOTEL_AIRPORT:
        case OPTION_TAXI.AIRPORT_ADDRESS:
          this.wayOption = TAXI_WAY_OPTION.ONE_WAY
          break
        case OPTION_TAXI.TWO_AIRPORT_HOTEL:
          this.wayOption = TAXI_WAY_OPTION.TWO_WAY
          break
        default:
          break
      }
    },
    wayOption(way) {
      this.totalPrice = way == 1 ? this.taxiPrice : this.priceHotelToAirport + this.priceAirportToHotel
    }
  },
  created() {
    this.fetchVouchers()
    this.fetchDetailTaxi()
    this.fetchVoucherHotel()
  },
  async mounted() {
    this.initData()
  },
  methods: {
    initData() {
      this.taxiService = this.vouchers.find((voucher) => voucher.service_id == 4)
      // Todo: if undefined redirect to homepage
      let paxId: number = parseInt((this.route.query as any).passenger_id ?? 0)
      paxId = this.paxThisIsMe?.id ?? paxId
      if (this.taxiService?.store_taxi_option) {
        this.optionTaxi = this.taxiService.store_taxi_option
      }

      if (this.detailVouchersTaxi && !_.isEmpty(this.detailVouchersTaxi)) {
        this.used_transfer_wise = Object.values(this.detailVouchersTaxi).some((item: any) => {
          return item.used_transfer_wise == 1
        })
        const voucher = this.detailVouchersTaxi[paxId] ?? Object.values(this.detailVouchersTaxi)[0]
        this.optionTaxi = voucher?.option_taxi ?? OPTION_TAXI.AIRPORT_ADDRESS
        this.wayOption = voucher?.way_option ?? TAXI_WAY_OPTION.ONE_WAY
        this.isSetOptionTaxi = true
      }

      // set taxi config
      this.taxiConfig = this.airline?.taxi_config?.mobile_self_service ?? TAXI_CONFIG.PHOTO2CLAIM

      // check is need to share taxi
      let airlineParams: any = {}
      if (this.airline?.params) airlineParams = this.airline.params
      this.paxPerTaxi = airlineParams.pax_per_taxi ? parseInt(airlineParams.pax_per_taxi as string) : undefined

      // check the share taxi matrix
      this.checkMatrixShareTaxi()

      if (this.taxiService?.taxi_booking_detail?.length && this.detailVouchersTaxi && !_.isEmpty(this.detailVouchersTaxi)) {
        this.getTaxiBookingDetail()
      }

      if (!this.paxPerTaxi) {
        this.isNeedToShareTaxi = false
      } else if (this.listGroupPax.length > this.paxPerTaxi) {
        this.isNeedToShareTaxi = true
      }

      this.taxiErrorMessage =
        this.groundHandlerMessage?.taxi?.SFS_LANG_ROW_27 ||
        'You are entitled for a taxi but please make sure you select an hotel first before you can issue your taxi voucher.'
    },
    checkMatrixShareTaxi() {
      if (!this.taxiService) return

      const taxiParams = this.taxiService ? this.taxiService?.params : ''
      const matrix_share_taxi = JSON.parse(taxiParams || '{}')
      if (this.shareMatrixPaxId.length == 0)
        this.shareMatrixPaxId = matrix_share_taxi ? matrix_share_taxi.matrix_share_taxi ?? [] : []

      if (!matrix_share_taxi.matrix_share_taxi) this.isSetByAirline = false
      else this.isSetByAirline = true
      const paxPerTaxi = this.paxPerTaxi ?? 0
      const listPassengerIds = _.map(this.listGroupPax, 'passenger_id')
      if (_.isEmpty(this.shareMatrixPaxId) && this.listGroupPax.length <= paxPerTaxi) {
        this.shareMatrixPaxId = [listPassengerIds]
      }
    },
    // Update the selected location
    updateLocation(location) {
      if (location.destination) {
        this.locationInput = {
          lat: parseFloat(location.destination.lat),
          lng: parseFloat(location.destination.lng),
          display_address: location.destination.address
        }
      }
    },
    getTaxiBookingDetail() {
      const voucher: any = Object.values(this.detailVouchersTaxi)[0]
      if (_.isEmpty(voucher) || !voucher) return

      const startPoint = JSON.parse(voucher.start_point)
      const endPoint = JSON.parse(voucher.end_point)
      const origin = {
        lat: startPoint.Lat,
        lng: startPoint.Lon,
        display_address: voucher.from_andress,
        disabled: true
      }
      const destination = {
        lat: endPoint.Lat,
        lng: endPoint.Lon,
        display_address: voucher.to_address,
        disabled: true
      }
      this.taxiBookingDetail = {
        way: this.indexBooking,
        type: this.indexBooking,
        est_amount: voucher.est_amount,
        currency: voucher.currency,
        // indexBooking = 0 (airport -> hotel)
        // if indexBooking = 1 (hotel -> airport), we need to reverse the origin and destination address taken from the Voucher
        origin: this.indexBooking ? destination : origin,
        destination: this.indexBooking ? origin : destination
      }
    },
    checkPhoneNumberForEachGroup() {
      return this.shareMatrixPaxId.some((group) => {
        return group.some((paxId) => {
          const pax = this.listGroupPax.find((pax) => paxId == pax.passenger_id)
          if (!pax) return false
          if (pax.phone_number) return true
          return false
        })
      })
    },
    getPhoneList() {
      return this.listGroupPax
        .filter((pax) => pax.phone_number)
        .map((pax) => {
          return {
            id: pax.passenger_id,
            phone_number: pax.phone_number
          }
        })
    },
    // estimate the distance and price
    getPriceByDistance(distanceOriginToDestination, distanceDestinationToOrigin) {
      const airlineParams = this.airline?.params
      const taxiConfig = airlineParams.airline_config_taxi
      const taxiAirportConfig = this.overnightStation?.airline_airport_detail
      const km_rate_airline = taxiConfig.km_rate && Number(taxiConfig.km_rate) !== 0 ? taxiConfig.km_rate : 3
      const starting_tariff_airline = Number(taxiConfig.starting_tariff) !== 0 ? taxiConfig.starting_tariff : 0
      const km_rate =
        taxiAirportConfig && taxiAirportConfig.km_rate && Number(taxiAirportConfig.km_rate) !== 0
          ? taxiAirportConfig.km_rate
          : km_rate_airline
      const starting_tariff =
        taxiAirportConfig && taxiAirportConfig.starting_tariff && Number(taxiAirportConfig.starting_tariff) !== 0
          ? taxiAirportConfig.starting_tariff
          : starting_tariff_airline

      this.distanceTaxi = distanceOriginToDestination ? parseFloat(distanceOriginToDestination.toFixed(2)) : 0
      this.distance_hotel_to_airport = distanceDestinationToOrigin ? parseFloat(distanceDestinationToOrigin.toFixed(2)) : 0

      this.priceAirportToHotel = parseFloat(km_rate) * distanceOriginToDestination.toFixed(2) + parseFloat(starting_tariff) ?? 0
      this.priceHotelToAirport = parseFloat(km_rate) * distanceDestinationToOrigin.toFixed(2) + parseFloat(starting_tariff) ?? 0
      this.taxiPrice =
        this.wayOption == TAXI_WAY_OPTION.TWO_WAY ? this.priceAirportToHotel + this.priceHotelToAirport : this.priceAirportToHotel
      this.totalPrice = this.taxiPrice ?? 0
    },
    async sendSMSForGroupPax() {
      const listGroupPaxIds = this.listGroupPax.map((pax) => pax.passenger_id)
      const phoneList = this.getPhoneList()
      const passengers = this.passengersFormatForSendSMSAndIssueTaxi()
      const data = {
        passenger_id: listGroupPaxIds,
        matrix_share_taxi: this.shareMatrixPaxId,
        phone_list: phoneList,
        passengers,
        paxId: this.paxThisIsMe?.passenger_id,
        airline_id: this.airline?.id,
        airport_code: this.paxThisIsMe?.overnight_station ?? this.paxThisIsMe?.dep
      }
      const mobile = new SfsApiMobile()
      const { success } = await mobile.sendSMSToOthers(data)
      if (success) this.router.push({ path: '/print-services' })
    },
    async saveOptionTaxi() {
      if (this.vouchers.length <= 1) {
        //if pax only have taxi service, but select option relate to hotel
        ElNotification.closeAll()
        ElNotification({
          title: 'Error',
          message: this.taxiErrorMessage,
          type: 'error',
          duration: 5000
        })
        return
      }
      const listGroupPaxIds = this.listGroupPax.map((pax) => pax.passenger_id)
      const phoneList = this.getPhoneList()
      const data = {
        passenger_id: listGroupPaxIds,
        taxi_option: this.optionTaxi,
        matrix_share_taxi: this.shareMatrixPaxId,
        phone_list: phoneList.length ? phoneList : undefined,
        paxId: this.paxThisIsMe?.passenger_id,
        airline_id: this.airline?.id,
        airport_code: this.paxThisIsMe?.overnight_station ?? this.paxThisIsMe?.dep
      }
      if (phoneList.length) {
        data.phone_list = phoneList
      }

      const mobile = new SfsApiMobile()
      const { success } = await mobile.updateTaxiTransferOption(data)
      if (success) this.router.push({ path: '/print-services' })
    },
    clearDestinationAddress() {
      Object.keys(this.locationInput).forEach((key) => {
        this.locationInput[key] = null
      })
    },
    async issueTaxi() {
      // check locationInput
      if (this.optionTaxi == OPTION_TAXI.AIRPORT_ADDRESS)
        if (!this.locationInput?.display_address || !this.locationInput?.lat || !this.locationInput?.lng) {
          ElNotification.closeAll()
          ElNotification({
            title: 'Error',
            message: 'Please enter the address',
            type: 'error',
            duration: 3000
          })
          return
        }

      const passengers = this.passengersFormatForSendSMSAndIssueTaxi()
      const data = {
        passengers,
        matrix_share_taxi: this.shareMatrixPaxId,
        paxId: this.paxThisIsMe?.passenger_id,
        airline_id: this.airline?.id,
        airport_code: this.paxThisIsMe?.overnight_station ?? this.paxThisIsMe?.dep
      }

      const mobile = new SfsApiMobile()
      const { success } = await mobile.issueTaxi(data)
      if (success) this.router.push({ path: '/print-services' })
    },
    async handleReserveTaxi() {
      if (this.taxiBookingDetail) {
        // if issued taxi, re-print taxi voucher again
        if (!this.used_transfer_wise) await this.sendSMSForGroupPax()
        this.router.push({ path: '/print-services' })
        return
      } else if (this.optionTaxi != OPTION_TAXI.AIRPORT_ADDRESS && _.isEmpty(this.paxsHotel)) {
        // if issue only taxi with option different airport to address
        this.saveOptionTaxi()
      } else this.issueTaxi() //issue taxi after issue hotel for the first time
    },

    passengersFormatForSendSMSAndIssueTaxi() {
      return this.listGroupPax.map((item) => {
        const pax: any = {
          option_taxi: this.optionTaxi,
          way_option: this.wayOption,
          total_price: this.totalPrice,
          distance: this.distanceTaxi,
          distance_hotel_to_airport: this.distance_hotel_to_airport,
          hotel_id: !_.isEmpty(this.paxsHotel) ? this.paxsHotel.hotel_id : 0,
          currency: this.airline?.currency_code,
          show_voucher: true,
          airport_code: this.overnightStation?.code ?? item.dep,
          passenger_id: item.passenger_id,
          phone_number: typeof item.phone_number == 'string' ? item.phone_number.replace(/\s+/g, '') : item.phone_number,
          from_andress: this.overnightStation?.name ?? this.airline?.airport_name,
          from_geo_lat_lon: this.overnightStation
            ? `${this.overnightStation.geo_lat}, ${this.overnightStation.geo_lon}`
            : `${this.airline?.geo_lat}, ${this.airline?.geo_lon}`,
          is_auto_taxi: true,
          price_hotel_to_airport: this.priceHotelToAirport,
          price_airport_to_hotel: this.priceAirportToHotel
        }
        switch (this.optionTaxi) {
          case OPTION_TAXI.ONE_AIRPORT_HOTEL:
          case OPTION_TAXI.TWO_AIRPORT_HOTEL:
            pax.to_address = this.paxsHotel.address
            pax.to_geo_lat_lon = `${this.paxsHotel.geo_lat}, ${this.paxsHotel.geo_long}`
            break
          case OPTION_TAXI.ONE_HOTEL_AIRPORT:
            pax.from_andress = this.paxsHotel ? this.paxsHotel.address : this.origin.display_address
            pax.from_geo_lat_lon = !this.paxsHotel
              ? `${this.paxsHotel.geo_lat}, ${this.paxsHotel.geo_long}`
              : `${this.origin.lat}, ${this.origin.lng}`
            pax.to_address = this.overnightStation?.name ?? this.airline?.airport_name
            pax.to_geo_lat_lon = this.overnightStation
              ? `${this.overnightStation.geo_lat},${this.overnightStation.geo_lon}`
              : `${this.airline?.geo_lat},${this.airline?.geo_lon}`
            break
          case OPTION_TAXI.AIRPORT_ADDRESS:
            if (this.locationInput) {
              pax.to_address = this.locationInput.display_address
              pax.to_geo_lat_lon = `${this.locationInput.lat}, ${this.locationInput.lng}`
            } else {
              if (this.taxiBookingDetail) {
                pax.to_address = this.taxiBookingDetail.destination.display_address
                pax.to_geo_lat_lon = `${this.taxiBookingDetail.destination.lat}, ${this.taxiBookingDetail.destination.lng}`
              }
            }
            break
          default:
            break
        }
        return pax
      })
    }
  }
})
