import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ws-textbox" }
const _hoisted_2 = { class: "select-pax" }
const _hoisted_3 = { class: "pax-detail" }
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { class: "pnr" }
const _hoisted_6 = { class: "first-name" }
const _hoisted_7 = { class: "first-name" }
const _hoisted_8 = { class: "travel-with" }
const _hoisted_9 = { class: "text" }
const _hoisted_10 = { class: "btn__container" }
const _hoisted_11 = { class: "btn btn-confirm" }
const _hoisted_12 = { class: "btn btn-back" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemOfVoucher = _resolveComponent("ItemOfVoucher")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_StyledSelectPaxTraveling = _resolveComponent("StyledSelectPaxTraveling")!

  return (_openBlock(), _createBlock(_component_StyledSelectPaxTraveling, null, {
    default: _withCtx(() => [
      _createVNode(_component_ItemOfVoucher, {
        class: "traveling-with",
        color: "#a7116f",
        icon: "images/icon-voucher/contact-detail.png",
        text: _ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_16,
        border: true,
        cursor: "default"
      }, null, 8, ["text"]),
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backStep()))
      }, [
        _createVNode(_component_ItemOfVoucher, {
          class: "add-passenger",
          color: "#0094d5",
          icon: "images/icon-voucher/combine-voucher.png",
          text: _ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_17
        }, null, 8, ["text"])
      ]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_checkbox_group, {
          modelValue: _ctx.listNewGroupPaxsExcludeMeIds,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.listNewGroupPaxsExcludeMeIds) = $event))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listGroupPaxsExcludeMe, (pax, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "select-paxs"
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", _hoisted_4, " Passenger " + _toDisplayString(index + 1), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_29) + " " + _toDisplayString(pax.pnr), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(pax.first_name), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(pax.last_name), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_18), 1)
                    ]),
                    (_openBlock(), _createBlock(_component_el_checkbox, {
                      key: index,
                      label: pax.id
                    }, null, 8, ["label"]))
                  ])
                ])
              ]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleConfirm && _ctx.handleConfirm(...args)))
            }, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_19), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("button", {
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.backStep && _ctx.backStep(...args)))
            }, _toDisplayString(_ctx.groundHandlerMessage?.search_and_select_pax.SFS_LANG_ROW_20), 1)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}