import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "travelers__wrapper" }
const _hoisted_2 = { class: "ws-textbox" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = {
  key: 0,
  class: "traveler-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StyledSFSTravelersAndServiceInGroup = _resolveComponent("StyledSFSTravelersAndServiceInGroup")!

  return (_openBlock(), _createBlock(_component_StyledSFSTravelersAndServiceInGroup, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.label), 1),
          (_ctx.listPax)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listPax, (pax, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: ""
                  }, _toDisplayString(pax?.first_name + ' ' + pax?.last_name), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}