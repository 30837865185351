
import { computed, defineComponent } from 'vue'
import { StoreType } from '@/types/store'
import { useStore } from 'vuex'
import NotLayout from '@/styled-components/layouts/NotLayout'

export default defineComponent({
  components: {
    NotLayout
  },
  setup() {
    const store = useStore<StoreType>()
    return {
      loading: computed(() => store.state.loading > 0)
    }
  }
})
