import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content-input" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_StyledDateRange = _resolveComponent("StyledDateRange")!

  return (_openBlock(), _createBlock(_component_StyledDateRange, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_date_picker, {
          modelValue: _ctx.dateRange,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateRange) = $event)),
          type: "daterange",
          "range-separator": "",
          format: "MM/DD/YYYY",
          "value-format": "YYYY-MM-DD",
          "start-placeholder": _ctx.placeholder,
          onChange: _ctx.handleChange
        }, null, 8, ["modelValue", "start-placeholder", "onChange"]),
        _createElementVNode("img", {
          class: "icon",
          src: `${_ctx.cloudFrontUrl}/superadmin/img/calender.svg`
        }, null, 8, _hoisted_2)
      ])
    ]),
    _: 1
  }))
}