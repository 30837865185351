import styled from 'vue3-styled-components'

const StyledSFSGoogleMap = styled.section`
  mb-3 {
    margin-bottom: 3px;
  }
  .d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .w-100 {
    width: 100%;
  }
  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .vue-map {
    width: 100% !important;
  }
  hr {
    margin-bottom: 0;
    margin-top: 0;
  }
  .show-on-map {
    border: none;
    cursor: pointer;
  }
  .gmap {
    padding-top: 1.5rem;
  }
  .departure-title {
    font-size: 18px;
    margin-bottom: 0.5rem;
    color: #646464;
  }
  .pac-target-input {
    font-size: 18px;
    color: #646464;
  }
  .distance {
    color: #646464;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }

  .hide-show-map-btn {
    text-align: center;
    cursor: pointer;
    span {
      color: #646464;
    }
  }
  .from {
    color: #646464;
  }
`
export default StyledSFSGoogleMap
