import ResetPassword from '@/views/ResetPassword/Index.vue'
import ChangePassword from '@/views/ChangePassword/Index.vue'
import NotLayout from '@/layouts/NotLayout.vue'
import guest from '@/middleware/guest'
import Login from '@/views/Login/Index.vue'

export const user = {
  path: '/',
  name: 'user',
  hidden: true,
  component: NotLayout,
  meta: {
    middleware: [guest]
  },
  children: [
    {
      path: 'reset-password',
      name: 'ResetPassword',
      component: ResetPassword
    },
    {
      path: 'change-password/:hash',
      name: 'ChangePassword',
      component: ChangePassword
    },
    {
      path: 'login',
      name: 'Login',
      component: Login
    }
  ]
}
