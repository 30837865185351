import { API_SERVICE } from '@/constants/apiService'
import { mutationTree, actionTree, getterTree } from 'typed-vuex'
import _ from 'lodash'
import Lang from '@/models/Lang'
import { FetchLangType } from '@/types/user'
import Cookies from 'js-cookie'
import { ActionContext } from 'vuex'

type RootState = ReturnType<typeof state>

export interface LangStateType {
  lang: any
  selectedLang: string
  direction: string
}

export const initLangStore = {
  lang: {},
  selectedLang: Cookies.get('currentLanguage') ?? 'en',
  direction: Cookies.get('direction') ?? 'ltr'
} as LangStateType

export const state = () => initLangStore

export const getters = getterTree(state, {
  lang: (state: RootState) => state.lang,
  selectedLang: (state: RootState) => state.selectedLang,
  direction: (state: RootState) => state.direction
})

export const mutations = mutationTree(state, {
  setLang(state: RootState, { lang, type }) {
    state.lang[type] = lang
  },
  setCurrentLanguage(state: RootState, [lang_code, direction]) {
    Cookies.set('direction', direction)
    Cookies.set('currentLanguage', lang_code)
  },
  setTemplateDirection(state: RootState, payload: any) {
    const direction = payload?.paxDirection || state.direction
    document.body.dir = direction
    // Set style with variables base on direction
    var r = document.querySelector<HTMLElement>(':root')
    var cssVariables = {
      '--text-align': state.direction == 'rtl' ? 'right' : '',
      '--voucher-icon-border-radius': state.direction == 'rtl' ? '0 0.75rem 0.75rem 0' : '0.75rem 0 0 0.75rem',
      '--meal-plan-box-border-radius': state.direction == 'rtl' ? '0.75rem 0 0.75rem 0' : '0 0.75rem 0 0.75rem',
      '--voucher-ear-marker-border-radius': state.direction == 'rtl' ? '0.75rem 0 0 0.75rem' : '0 0.75rem 0.75rem 0',
      '--transform-flight-icon': state.direction == 'rtl' ? 'scaleX(-1)' : 'none',
      '--travel-destination-title': direction == 'rtl' ? '0 0 0 1.5rem' : '0 1rem 0 0' // Use only on the printA4 page, utilize direction from the passenger's language
    }
    const entries = Object.entries(cssVariables)
    for (const [key, value] of entries) {
      r?.style.setProperty(key, value)
    }
  }
})

export const actions = actionTree(
  { state, mutations },
  {
    async fetchLang({ commit }, payload: FetchLangType) {
      const response = await new Lang(API_SERVICE.SFS).fetchLangByType(payload)
      if (response.success) {
        commit('setLang', { lang: response.data, type: payload.type })
      }
    },
    async setCurrentLanguage({ commit }, lang_code: string) {
      commit('setCurrentLanguage', lang_code)
    },
    async setTemplateDirection({ commit }) {
      commit('setTemplateDirection')
    }
  }
)
