import styled from 'vue3-styled-components'

const StyledAddMorePax = styled.section`
  .ws-textbox {
    margin: 30px 0px 30px;
    padding: 0 !important;
  }

  .me-voucher {
    cursor: default;
  }

  .add-pax {
    padding-bottom: 23px !important;
  }
  .button-scan {
    span {
      font-size: 19px !important;
    }
    img {
      width: 50px;
    }
  }

  .add-pax-label {
    font-size: 20px;
    padding: 10px;
    span {
      color: #6f6f6b;
    }
  }

  .group-pax {
    .group-pax__item {
      white-space: pre-wrap;
      display: flex;
    }
    .el-checkbox__input {
      margin-bottom: 0.25rem !important;
    }
  }

  .btn-back {
    margin-top: 50px;
  }

  .container {
    filter: none;
  }

  .search-by-scan {
    margin: -6px 0 81px 0;
  }

  .group-pax {
    display: flex;
    flex-direction: column;

    .group-pax__item {
      margin: 5px;
      .el-checkbox__input {
        margin-bottom: 0.5rem;
      }
    }
  }
  .search-by-pnr,
  .search-by-detail,
  .search-by-scan {
    button {
      cursor: pointer;
      padding-left: 0;
      padding-right: 0;
    }
  }
  ,
  .search-by-scan {
    .button-scan-parent {
      border-top: 1px solid #f7f7f7;
    }
  }
`

export default StyledAddMorePax
