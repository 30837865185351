import styled from 'vue3-styled-components'

const CombineVoucher = styled.section`
  .ws-textbox {
    padding: 1rem;
    letter-spacing: 0.004375rem;
    background-color: #ffffff;
    border-radius: 0.75rem;
    border: none;
    -webkit-box-shadow: 0 0.1875rem 0.375rem #85858529;
    box-shadow: 0 0.1875rem 0.375rem #85858529;
    outline: none;
    line-height: 1.777777778;
  }

  .el-dialog__footer {
    text-align: center;
  }

  .btn {
    text-align: center;
    margin-bottom: 25px;
    button {
      cursor: pointer;
      border: 1px;
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
      background-color: #ffffff;
      letter-spacing: 0px;
      color: #f5d32a;
      font-family: 'Roboto';
    }
  }

  .btn-back,
  .btn-confirm {
    button {
      border-radius: 17px;
      padding: 10px 50px;
      font-weight: 500;
      font-size: 16px;
      width: 162px;
      height: 44px;
    }
    button:hover {
      background-color: #0094d5;
      border: 0px;
      span {
        color: #f5d32a;
        font-weight: 500;
      }
    }
  }
`

export default CombineVoucher
