
import { defineComponent } from 'vue'
import moment from 'moment'
import Search from './Search.vue'
import SearchOption from './SearchOption.vue'
import ConfirmThisIsMe from './ConfirmThisIsMe.vue'
import AreYouTravelingAlone from './AreYouTravelingAlone.vue'
import SelectPaxTraveling from './SelectPaxTraveling.vue'
import AddMorePax from './AddMorePax.vue'
import Passenger from '@/models/Passenger'
import SearchPaxMixinsVue from './Mixin/SearchPaxMixins.vue'
import LangMixinsVue from './Mixin/LangMixins.vue'
import { SELECT_FLIGHT_RULES } from '@/constants/searchPax'
import StyledSearchPax from '@/styled-components/pages/search-and-select-passengers'
import { getUser } from '@/utils/auth'
import store from '@/store'
import { useRouter } from 'vue-router'
import _ from 'lodash'
import { PassengerType } from '@/types/passenger'

export default defineComponent({
  name: 'SearchAndSelectPax',
  components: {
    Search,
    SearchOption,
    ConfirmThisIsMe,
    AreYouTravelingAlone,
    SelectPaxTraveling,
    AddMorePax,
    StyledSearchPax
  },
  mixins: [SearchPaxMixinsVue, LangMixinsVue],
  data() {
    const listGroupPaxs: any[] = []
    const flights: any[] = []
    const listSearchedOtherPaxsOnFlight: PassengerType[] = []
    const listSelectedPaxIdsOnFlightIncludeMyGroupAndOtherGroups: number[] = []

    return {
      step: 'searchOption',
      selectFlightRules: SELECT_FLIGHT_RULES,
      selectedFlight: {
        flightId: undefined
      },
      listGroupPaxs,
      myId: undefined,
      flights,
      passengerSearchInfo: {
        pnr: '',
        firstName: '',
        lastName: ''
      },
      isShowModalSelectFlight: false,
      searchType: undefined,
      listSearchedOtherPaxsOnFlight,
      listSelectedPaxIdsOnFlightIncludeMyGroupAndOtherGroups
    }
  },
  computed: {
    flightInfo() {
      return store.state.flightInfo
    },
    listGroupPaxsAndListSearchedOtherPaxsOnFlight() {
      return [...this.listGroupPaxs, ...this.listSearchedOtherPaxsOnFlight]
    }
  },
  created() {
    const router = useRouter()
    router.replace({ query: { passenger_id: null } })
    this.resetData()
  },
  methods: {
    resetData() {
      store.dispatch('groupPax/setListGroupPaxExcludeMe', [])
      store.dispatch('setVouchers', [])
    },
    updateStep(newStep, isResetAllStep = false) {
      if (isResetAllStep) {
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href
        this.step = 'searchOption'
        return
      }
      this.step = newStep
    },
    async handleSearchFlight() {
      const self = this
      if (self.step == 'search') {
        const passenger = new Passenger()
        self.flights = (
          await passenger.searchFlights({
            airline_id: getUser().airline?.id,
            pnr: _.trim(self.passengerSearchInfo.pnr),
            first_name: self.passengerSearchInfo.firstName,
            last_name: self.passengerSearchInfo.lastName,
            search_type: this.searchType ?? ''
          })
        ).data
      }

      if (self.flights.length == 0) {
        this.throwNoPaxFoundError()
      }
      // if only 1 flight -> Go to Page show list GROUP has same PRN search (confirm this is me)
      else if (self.flights.length == 1) {
        this.selectedFlight.flightId = self.flights[0].id
        store.dispatch(
          'setFlightInfo',
          this.flights.find((f: any) => f.id == this.selectedFlight.flightId)
        )
        this.handleSearchPax()
      } else {
        this.isShowModalSelectFlight = true
      }
    },
    async handleSearchPax() {
      if (!this.selectedFlight.flightId) {
        this.handleSearchFlight()
      } else {
        const self = this
        const passenger = new Passenger()
        self.listGroupPaxs = (
          await passenger.searchPax({
            airline_id: getUser().airline?.id,
            pnr: _.trim(self.passengerSearchInfo.pnr),
            flight_id: self.selectedFlight.flightId,
            first_name: self.passengerSearchInfo.firstName,
            last_name: self.passengerSearchInfo.lastName,
            search_type: this.searchType ?? ''
          })
        ).data
        this.step = 'confirmThisIsMe'
      }
    },
    handleCloseModalSelectFlight() {
      // Reset flightId when not click confirm button select flight
      this.selectedFlight = { ...this.selectedFlight, flightId: undefined }
      this.isShowModalSelectFlight = false
    },
    handleSelectFlight() {
      const form = this.$refs.selectFlightForm as any
      form.validate((valid) => {
        if (!valid) return
        store.dispatch(
          'setFlightInfo',
          this.flights.find((f: any) => f.id == this.selectedFlight.flightId)
        )
        this.isShowModalSelectFlight = false
        this.handleSearchPax()
      })
    },
    async handleQRScan(decodedText: string) {
      const self = this
      const passenger = new Passenger()
      const { data: parsedPax } = await passenger.parseStringToPax({ boardingPass: decodedText })
      const { data: flights } = await passenger.searchFlights({
        airline_id: getUser().airline?.id,
        pnr: parsedPax.pnr,
        first_name: parsedPax.firstName,
        last_name: parsedPax.lastName,
        search_type: 'NAME'
      })
      const flight = flights.find(
        (f) =>
          f.carrier === parsedPax.carrier &&
          f.flight_no === parsedPax.flightNo &&
          f.arr === parsedPax.arr &&
          f.dep === parsedPax.dep
      )
      if (!flight) {
        this.throwFlightNotFound()
        return
      }

      const listSearchedPax = (
        await passenger.searchPax({
          airline_id: getUser().airline?.id,
          pnr: parsedPax.pnr,
          flight_id: flight.id,
          first_name: parsedPax.firstName,
          last_name: parsedPax.lastName,
          search_type: this.searchType ?? ''
        })
      ).data

      // filter base on parsed pax string
      const me = listSearchedPax.find(
        (p) =>
          p.pnr.toLowerCase() === parsedPax.pnr.toLowerCase() &&
          p.first_name.toLowerCase() === parsedPax.firstName.toLowerCase() &&
          p.last_name.toLowerCase() === parsedPax.lastName.toLowerCase()
      )
      const groupPax = (await passenger.getPaxsGroup({ paxId: me.id })).data

      store.dispatch('setFlightInfo', flight)
      store.dispatch('groupPax/setMe', me)

      self.listGroupPaxs = this.filterPassengersHasMyGroupId(me, groupPax)
      self.listGroupPaxs.forEach((pax) => (pax.id = pax.passenger_id))
      this.myId = me.id
      this.selectedFlight.flightId = flight.id
      self.step = 'confirmThisIsMe'
    }
  }
})
