const publicPath = {
  staging: '/',
  test: '/',
  production: '/',
  development: '/'
}

module.exports = {
  devServer: {
    host: '0.0.0.0',
    port: 4300, // CHANGE YOUR PORT HERE!
    https: false,
    hotOnly: false
  },
  publicPath: publicPath[process.env.NODE_ENV]
}
