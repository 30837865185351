import styled from 'vue3-styled-components'

const StyledSelectPaxTraveling = styled.section`
  .first-name,
  .last-name {
    font-size: 15px;
    padding: 0px 5px 0px;
    color: #8d8c8b;
  }

  .traveling-with,
  .add-passenger {
    margin: 30px 0px 30px;
  }

  .ws-textbox {
    margin: 30px 0px 30px;
  }

  .select-pax {
    display: flex;
    .text {
      font-size: 16px;
    }

    div {
      flex: 1 0 auto;
    }
    .travel-with {
      text-align: center;
      padding: 10px 0px 10px;
    }

    .el-checkbox__label {
      font-size: 0;
      padding: 0;
    }

    .el-checkbox-group {
      font-size: 16px !important;
    }
  }

  .pax-detail {
    padding: 10px 0px 10px;
  }
`

export default StyledSelectPaxTraveling
