import { UserType } from '@/types/user'
import Cookies from 'js-cookie'

export function getToken() {
  return Cookies.get('sh_access_token')
}

export function setToken(token: string) {
  return Cookies.set('sh_access_token', token)
}

export function setUser(user: UserType) {
  return Cookies.set('user_info', JSON.stringify(user))
}

export function getUser() {
  const user = Cookies.get('user_info')
  return !user ? undefined : JSON.parse(user)
}

export function removeToken() {
  return Cookies.remove('sh_access_token')
}

export function removeUser() {
  return Cookies.remove('user_info')
}
