import styled from 'vue3-styled-components'

const StyledHome = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Roboto';
  a {
    text-decoration: none;
  }
  .space {
    margin-bottom: 20px;
  }
  .list-vouchers {
    margin-top: 13px;
    margin-bottom: 28px;
    a {
      text-decoration: none;
    }
  }
  .list-services {
    margin-top: 18px;
  }
  .item-label {
    padding-left: 45px;
    margin-top: 25px;
    font-size: 18px;
    letter-spacing: 0px;
    color: #808080;
    font-weight: 400;
  }
  .group-input-label {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 32px;
    color: #474747;
    font-weight: 400;
  }
  .label-center {
    font-size: 16px;
    line-height: 20px;
    color: #5a5858;
    font-weight: 400;
    margin: 35px auto 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info-pax {
    display: flex;
    flex-direction: column;
    align-items: center;
    .select-phone {
      margin-top: 7px;
      font-size: 14px;
      margin-bottom: 3px;
      font-weight: 300;
    }
  }
  .full-width {
    width: 100%;
  }

  .group-button {
    .btn-action {
      width: 162px;
      height: 44px;
      border-radius: 17px;
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
      background-color: #ffffff;
      border: 0px;
      display: block;
      margin-top: 30px;
      span {
        font-size: 16px;
        letter-spacing: 0px;
        color: #f5d32a;
        font-weight: 500;
      }
    }
    .btn-action:hover {
      background-color: #0094d5;
      border: 0px;
      display: block;
      span {
        color: #f5d32a;
        font-weight: 500;
      }
    }
  }
  .ws-textbox {
    padding: 1rem;
    letter-spacing: 0.004375rem;
    background-color: #ffffff;
    border-radius: 0.75rem;
    border: none;
    -webkit-box-shadow: 0 0.1875rem 0.375rem #85858529;
    box-shadow: 0 0.1875rem 0.375rem #85858529;
    outline: none;
    line-height: 1.777777778;
  }

  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #cacaca;
    border-radius: 0.25rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .form-control:focus-visible {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    border-color: #66afe9;
    outline: 1px solid #a1cdf0;
  }

  .vue-tel-input {
    border-radius: 3px;
    display: flex;
    text-align: left;
    align-items: center;
    div {
      border: none;
    }
    i {
      margin: 0 10px;
    }
  }
  .vue-tel-input {
    .dropdown:focus {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
      border-color: #66afe9;
    }
    input {
      border: 0px;
      margin-right: 5px;
    }
    .dropdown {
      border: 1px solid #bbb;
      border-radius: 10px;
      margin-right: 5px;
    }
  }
  .vue-tel-input:focus {
    outline-color: white;
  }
  .validation-failed {
    border-color: #ff0000;
  }
  .validation-failed .vti__dropdown {
    background-color: #ffdddd;
  }
`

export default StyledHome
