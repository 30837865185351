import { mutationTree, actionTree } from 'typed-vuex'
import { UserType } from 'src/types/user'

type RootState = ReturnType<typeof state>

interface StateType {
  token: string
  user: UserType | ''
}

export const state = () =>
  ({
    token: '',
    user: ''
  } as StateType)

export const mutations = mutationTree(state, {
  setToken(state: RootState, token: string) {
    state.token = token
  },
  setUser(state: RootState, user: UserType) {
    state.user = user
  }
})

export const actions = actionTree(
  { state, mutations },
  {
    setToken({ commit }, token: string) {
      commit('setToken', token)
    },
    setUser({ commit }, user: UserType) {
      commit('setUser', user)
    }
  }
)
