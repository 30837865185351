import Home from '@/views/Home/Index.vue'
import PassengerDetail from '@/views/PassengerDetail/Index.vue'
import authentication from '@/middleware/authentication'
import DefaultLayout from '@/layouts/Default.vue'
import PrintServices from '@/views/PrintServices/Index.vue'

export const home = {
  path: '/',
  component: DefaultLayout,
  meta: {
    middleware: [authentication]
  },
  children: [
    {
      path: '/home',
      name: 'Home',
      component: Home
    },
    {
      path: '/pax-detail',
      name: 'PassengerDetail',
      component: PassengerDetail
    },
    {
      path: '/print-services',
      name: 'PrintServices',
      component: PrintServices
    }
  ]
}
